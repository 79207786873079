import React, {
  useEffect, useRef, useState,
} from "react";

import { LoadingOutlined } from "@ant-design/icons";
import { isEmpty, pick } from "lodash";
import moment from "moment";
import printJS from "print-js";
import Scrollbar from "react-scrollbars-custom";
import * as XLSX from "xlsx";

import NoResults from "../../../Common/NoResultsGIF";
import EVENT_SPEC_STYLE_MAP from "../../constants";
import ReportModal from "./ReportModal";

import { getFormattedDaysAgoString } from "../../../Common/DatePicker/utils";

import { getEnvironmentFields } from "../../../../actions/envAnalytics";
import { getEnvSchedule } from "../../../../actions/envCalendar";

import genistaDocumentHeaderImage from "../../../../assets/images/environment/Genista_Header.png";
import pumpkinGIF from "../../../../assets/images/environment/pumpkin.gif";
import "./ExportModal.css";

export default function ExportModal({
  title = "",
  onClose,
  defaultDateRange,
  allowClear = true,
}) {
  const [loading, setLoading] = useState(false);
  const [groupedSubmissiondData, setGroupedSubmissiondData] = useState([]);
  // const [linkPatternFields, setLinkPatternFields] = useState([]);
  const [sampleIDFields, setSampleIDFields] = useState([]);
  const [markedDates, setMarkedDates] = useState([]);
  const [linkPatternDelimiter, setLinkPatternDelimiter] = useState(null);
  const controller = useRef();

  /**
   * Group data sample wise
   * @param {*} data
   */
  const groupData = (data) => {
    if (!isEmpty(data)) {
      const groupedData = [];
      (data.scheduled_submission ?? []).forEach((submission) => {
        const samples = submission.samples_list.map((s) => {
          const { samples_list, ...rest } = submission;
          const fields = sampleIDFields.map(((field) => field.json_field));
          const sampleidfields = pick(s, fields);
          const testListStr = s.test_list.map((t) => t.Test).join(", ");
          return {
            ...rest,
            sample: {
              ...s,
              ...sampleidfields,
              testListStr,
            },
          };
        });
        groupedData.push(...samples);
      });
      setGroupedSubmissiondData(groupedData);
    }
  };

  /**
   * Calls environmentfields/ api
   */
  const apiGetEnvFields = async () => {
    const result = await getEnvironmentFields();

    if (result && result.success) {
      const {
        fields, index0, delimiter, link_pattern_fields, sampleid_fields, link_pattern,
      } = result;
      const regex = new RegExp(delimiter, "g");
      const linkfields = link_pattern_fields.map((f) => f.split(regex)).flat();
      const sampleidfields = sampleid_fields.map((f) => f.split(regex)).flat();
      return {
        allFields: fields, index0, delimiter, linkPatternFields: linkfields, sampleIDFields: sampleidfields, link_pattern,
      };
    }

    return {
      allFields: [], index0: "", delimiter: "", linkPatternFields: [], sampleIDFields: [], link_pattern: "",
    };
  };

  /**
   * Calls envschedule/ api
   * @param {Date} start start date
   * @param {Date} end end date
   */
  const apiGetEnvSchedule = async (start = "", end = "") => {
    const abortController = new AbortController();
    if (controller.current) {
      controller.current.abort();
    }
    controller.current = abortController;
    const result = await getEnvSchedule(start, end, "", controller.current.signal);

    let schedulingData = [];
    if (controller.current?.signal.aborted) {
      schedulingData = [];
    }

    if (result && result.success) {
      const data = result.result;
      if (Array.isArray(data) && data.length === 0) {
        schedulingData = [];
      }
      schedulingData = data;
    }
    controller.current = null;
    return schedulingData;
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const envFields = await apiGetEnvFields();

      // const fieldMap = new Map(envFields.allFields.map((field) => [field.json_field, field]));
      // const linkFields = envFields.linkPatternFields.map((field_name) => fieldMap.get(field_name));

      const sampleIDCols = envFields.allFields.filter((field) => envFields.sampleIDFields.includes(field.json_field));

      setSampleIDFields(sampleIDCols);
      // setLinkPatternFields(linkFields);
      setLoading(false);
      setLinkPatternDelimiter(new RegExp(envFields.delimiter, "g"));
    })();
  }, []); // eslint-disable-line

  useEffect(() => {
    const fetchInitial = async () => {
      const { dateFromString, dateToString } = defaultDateRange;
      const schedulingData = await apiGetEnvSchedule(dateFromString, dateToString);
      groupData(schedulingData);
      setMarkedDates(schedulingData.scheduled_submission_dates ?? []);
    };
    if (sampleIDFields.length && linkPatternDelimiter) {
      setLoading(true);
      fetchInitial().finally(() => setLoading(false));
    }
  }, [sampleIDFields, linkPatternDelimiter, defaultDateRange]); // eslint-disable-line

  /**
   * Handle date picker change event
   * @param {Date} startDate start date
   * @param {Date} endDate end date
   */
  const handleDatePickerChange = async (startDate, endDate) => {
    const start = startDate ? moment(startDate).format("YYYY-MM-DD") : "";
    const end = endDate ? moment(endDate).format("YYYY-MM-DD") : "";
    setLoading(true);
    const schedulingData = await apiGetEnvSchedule(start, end);
    groupData(schedulingData);
    setMarkedDates(schedulingData.scheduled_submission_dates ?? []);
    setLoading(false);
  };

  /**
 * Generates filename
 * @returns Filename
 */
  const getFileName = () => {
    const timeStamp = moment().unix();
    return `Scheduling_Report_${timeStamp}.xlsx`;
  };

  /**
   * Export data to excel file
   * @param {*} data
   */
  const exportToExcel = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Scheduling Report");

    const filename = getFileName();
    XLSX.writeFile(workbook, filename);
  };

  // const s2ab = (s) => {
  //   const buf = new ArrayBuffer(s.length);
  //   const view = new Uint8Array(buf);
  //   for (let i = 0; i < s.length; i++) {
  //     // eslint-disable-next-line no-bitwise
  //     view[i] = s.charCodeAt(i) & 0xFF;
  //   }
  //   return buf;
  // };

  /**
   * Handle print data
   */
  const handlePrint = () => {
    // const worksheet = XLSX.utils.json_to_sheet(data);
    // const workbook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(workbook, worksheet, "Scheduling Report");
    // const excelBinary = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });

    // // Convert the binary string to a Blob
    // const blob = new Blob([s2ab(excelBinary)], { type: "application/octet-stream" });
    // const url = URL.createObjectURL(blob);
    // printJS(url);
    printJS({
      printable: "ExportModal__Report",
      type: "html",
      targetStyles: ["*"],
      css: ["./ExportModal.css"],
    });
  };

  /**
   * Handle download excel file
   */
  const handleDownload = () => {
    if (isEmpty(groupedSubmissiondData)) return;
    const data = groupedSubmissiondData.map((d) => {
      const sampleFields = sampleIDFields.reduce((acc, field) => {
        acc[field.title_field] = d.sample[field.json_field];
        return acc;
      }, {});
      const status = EVENT_SPEC_STYLE_MAP[d.sample.specs_flag].display_name;
      return {
        "Schedule Date": moment(d.event_date).format("MM/DD/YYYY"),
        "Submission Name": d.submission_name,
        ...sampleFields,
        Tests: d.sample.testListStr,
        Status: status,
      };
    });
    exportToExcel(data);
  };

  return (
    <ReportModal
      title={title}
      onClose={onClose}
      onPrint={handlePrint}
      onDownload={handleDownload}
      onDatePickerChange={handleDatePickerChange}
      disablePrintButton={loading || isEmpty(groupedSubmissiondData)}
      disableDownloadButton={loading || isEmpty(groupedSubmissiondData)}
      markedDates={markedDates}
      defaultDateRange={getFormattedDaysAgoString(defaultDateRange.dateFromObj, defaultDateRange.dateToObj)}
      showPrint={false}
      allowClear={allowClear}
    >
      <Scrollbar className="ExportModal__Container__Scrollbar">
        <div className="ExportModal__Container" id="ExportModal__Report">
          <img src={genistaDocumentHeaderImage} alt="Genista Document Header" className="ExportModal__HeaderImage" />
          <div className="ExportModal__TableContainer">
            <table className="ExportModal__Table">
              <thead>
                <tr>
                  <th>Schedule Date</th>
                  <th>Submission Name</th>
                  {sampleIDFields.map((field) => (
                    <th key={field.json_field}>{field.title_field}</th>
                  ))}
                  <th>Tests</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {!isEmpty(groupedSubmissiondData) && groupedSubmissiondData.map((submission, i) => {
                  const status = EVENT_SPEC_STYLE_MAP[submission.sample.specs_flag].display_name;
                  return (
                    <tr className="ExportModal__TableRow" key={`${submission.event_id}${submission.submission_id}${i}`}>
                      <td>{moment(submission.event_date).format("MM/DD/YYYY")}</td>
                      <td>{submission.submission_name}</td>
                      {sampleIDFields.map((field) => (
                        <td key={field.json_field}>{submission.sample[field.json_field]}</td>
                      ))}
                      <td>{submission.sample.testListStr}</td>
                      <td>{status}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {!loading && isEmpty(groupedSubmissiondData) && (
              <NoResults
                image={pumpkinGIF}
                message="No Result Found"
              />
            )}
            {loading && (
              <div className="ExportModal__LoadingContainer">
                <LoadingOutlined spin className="ExportModal__Loading" />
              </div>
            )}
          </div>
        </div>
      </Scrollbar>
    </ReportModal>
  );
}
