import React, {
  useState, useEffect, useRef, useCallback,
} from "react";

import { Dropdown } from "antd";
import { isEmpty } from "lodash";
import moment from "moment";
import { toast } from "react-toastify";

import NoResultsGIF from "../../../../../Common/NoResultsGIF";
import CreateSamplingEventModal from "../../../../components/CreateSamplingEventModal";
import EnvConfirmModal from "../../EnvConfirmModal";
import DatesSlider from "./DatesSlider/DatesSlider";
import ReportActionButton from "./ReportActionButton";
import ReportHistory from "./ReportHistory/ReportHistory";
import ResultActions from "./ResultActions";
import ResultHeader from "./ResultHeader";
import ResultTableCarousel from "./ResultTableCarousel";

import { hasFeature, hasPermission } from "../../../../../../utils/common";
import { getRecurringEventReadableText } from "../../../../utils";

import { getSwabReports, getEnvSpec } from "../../../../../../actions/envAnalytics";
import { deleteEnvSchedule, getReportEnvSchedule, patchEnvSchedule } from "../../../../../../actions/envCalendar";

import { ReactComponent as LoadingDots } from "../../../../../../assets/images/environment/3-dots-scale.svg";
import { ReactComponent as AddSamplingIcon } from "../../../../../../assets/images/environment/AddSamplingIcon.svg";
import { ReactComponent as CalendarIcon } from "../../../../../../assets/images/environment/CalendarEnv.svg";
import { ReactComponent as EditSpecIcon } from "../../../../../../assets/images/environment/EditSpecIcon.svg";
import pumpkinGIF from "../../../../../../assets/images/environment/pumpkin.gif";
import { ReactComponent as TrashIcon } from "../../../../../../assets/images/environment/TrashIcon.svg";

import "../ReportView.css";

const tabList = [
  "Select Locations",
  "Add/Edit Tests",
  "Select Frequency",
  "Summary",
];

export default function ReportResult({
  pinMap,
  pin,
  handlePreviewFile,
  handleAddSpecsButton,
  setImagePath,
  setCurReportInfo,
  editSpecs,
  loadingSpecs,
}) {
  const [filePath, setFilePath] = useState("");
  const [identifier, setIdentifier] = useState("");
  const [reportList, setReportList] = useState([]);
  const [defaultIndex, setDefaultIndex] = useState(null);
  // const [specsStatus, setSpecsStatus] = useState("");
  const [activeReportIdx, setActiveReportIdx] = useState(null);
  const [specsData, setSpecsData] = useState();
  const [resultTableCarouselKey, setResultTableCarouselKey] = useState(false);
  const [loadingReportList, setLoadingReportList] = useState(true);
  const [loadingSpecData, setLoadingSpecData] = useState(false); // eslint-disable-line
  const [showReportHistory, setShowReportHistory] = useState(false);
  const [openScheduleSampleModal, setOpenScheduleSampleModal] = useState(false);
  const [isSampleScheduled, setIsSampleScheduled] = useState(false);
  const [isEditingSamplingFrequency, setIsEditingSamplingFrequency] = useState(false);
  const [isDeletingSamplingFrequency, setIsDeletingSamplingFrequency] = useState(false);
  // const [paginationInfo, setPaginationInfo] = useState({ currentPage: 1, totalPages: 1 });
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  // Create sample event states
  const [tabSequence, setTabSequence] = useState(tabList); // eslint-disable-line
  const [selectedTab, setSelectedTab] = useState(tabSequence.length ? tabList[0] : null);
  const [newSamplingEventData, setNewSamplingEventData] = useState({
    samples: [],
    schedule: {
      dates: [],
      frequency: "",
    },
    event_name: "",
    action: "report-add",
    scheduled_from: "report-add",
    po: "",
  });

  // Delete event
  const [deleteEventData, setDeleteEventData] = useState({
    event_id: "",
    delete_recurring: true,
    submission_id: "",
  });

  // Edit event
  const [isEditing, setIsEditing] = useState(false);
  const [editingData, setEditingData] = useState(null);
  const [editRecurring, setEditRecurring] = useState(true); // eslint-disable-line
  const [editSchedulingPayload, setEditSchedulingPayload] = useState(null);
  const [hasEditEventFrequencyChanged, setHasEditEventFrequencyChanged] = useState(false); // eslint-disable-line

  // Env schedule data
  const [scheduledDate, setScheduledDate] = useState([]);
  const [scheduleFrequency, setScheduleFrequency] = useState("");
  const [loadingEnvSchedule, setLoadingEnvSchedule] = useState(false);

  // Report env signal
  const envControllerRef = useRef();

  /**
   * Calls envschedule/ api for reports
   */
  const apiReportEnvSchedule = useCallback(async () => {
    setLoadingEnvSchedule(true);
    const signal = new AbortController();
    if (envControllerRef.current) {
      envControllerRef.current.abort();
    }
    envControllerRef.current = signal;
    const result = await getReportEnvSchedule(pin.location_id, envControllerRef.current.signal);

    if (result && result.success && !isEmpty(result.result)) {
      const data = result.result;
      setScheduledDate(data.event_dates);
      setScheduleFrequency(data.event_frequency);
      setDeleteEventData((state) => ({ ...state, event_id: data.event_id }));
      setEditingData({
        event_dates: data.event_dates,
        event_frequency: data.event_frequency,
        submission_name: data.submission_name,
        submission_id: "",
        samples_list: [],
        event_id: data.event_id,
      });
      setIsSampleScheduled(true);
    } else {
      setScheduledDate([]);
      setScheduleFrequency("");
      setDeleteEventData((state) => ({ ...state, event_id: "" }));
      setEditingData(null);
      setIsSampleScheduled(false);
    }
    envControllerRef.current = null;
    setLoadingEnvSchedule(false);
  }, [pin.location_id]);

  // GET envschedule/ for reports
  useEffect(() => {
    apiReportEnvSchedule();
  }, [apiReportEnvSchedule]);

  /**
   * Get report list everytime swab changes
   */
  useEffect(() => {
    async function apiGetSwabReports() {
      setShowReportHistory(false);
      setLoadingReportList(true);
      setActiveReportIdx(null);
      const { location_id, swab_number } = pin;
      // const { currentPage } = paginationInfo;
      const payload = {
        location_id,
        // page: currentPage,
      };
      const {
        success,
        reports,
        default_index,
        // specs_status,
        // total_page,
      } = await getSwabReports(payload);
      if (success) {
        setReportList(reports || []);
        setDefaultIndex(default_index);
        setActiveReportIdx(default_index);
        // setSpecsStatus(specs_status);
        // setPaginationInfo({ currentPage, totalPages: total_page });
      } else {
        toast.error(`Failed to fetch reports for Swab ${swab_number}`);
      }
      if (!reports || reports.length === 0) {
        setCurReportInfo({ params: null, showUploadImage: false });
      }
      setLoadingReportList(false);
    }
    apiGetSwabReports();
  }, [pin, pinMap]); // eslint-disable-line

  /**
   * Get specs whenever report list or report index changes
   */
  useEffect(() => {
    async function apiGetSpecs() {
      if (reportList.length > 0 && activeReportIdx !== null && reportList[activeReportIdx] !== undefined) {
        setLoadingSpecData(true);

        /* Clear image when switching between reports */
        setImagePath("");
        setCurReportInfo(null);

        const {
          sample_id, location_id, received_date, sample_type,
        } = reportList[activeReportIdx];
        const params = {
          sample_id,
          location_id,
          sample_type,
          received_date: moment(new Date(received_date)).format("YYYY/MM/DD"),
        };
        const result = await getEnvSpec(params);
        if (result.success) {
          setImagePath(result.spec.image ?? "");
          setSpecsData(result.spec.report_result);
          setFilePath(result.spec.path);
          setIdentifier(result.spec.identifier ?? "");
          setCurReportInfo({ params, showUploadImage: result.spec.report_result && Object.keys(result.spec.report_result).length > 0 });
        } else {
          setImagePath("");
          setSpecsData(undefined);
          setFilePath("");
          setIdentifier("");
          setCurReportInfo(null);
          toast.error(`Failed to fetch results for sample on ${moment(new Date(received_date)).format("YYYY/MM/DD")}`);
        }
        setLoadingSpecData(false);
      }
      setResultTableCarouselKey(!resultTableCarouselKey);
    }
    apiGetSpecs();
  }, [reportList, activeReportIdx]); // eslint-disable-line

  /**
   * Calls envschedule/ delete api
   */
  const apiDeleteEnvSchedule = async () => {
    const { event_id } = deleteEventData;
    const isRecurring = true; // editingData?.event_frequency !== "one-time" && delete_recurring;
    const response = await deleteEnvSchedule(event_id, isRecurring, null, "report-delete");
    if (response.success) {
      toast.success("Event deleted");
    } else {
      toast.error(response.message);
    }
  };

  /**
   * Handle deleting event
   */
  const handleDeleteEvent = async () => {
    setIsConfirmLoading(true);
    await apiDeleteEnvSchedule();
    setIsDeletingSamplingFrequency(false);
    setDeleteEventData({
      event_id: "",
      delete_recurring: true,
      submission_id: "",
    });
    setIsConfirmLoading(false);
    await apiReportEnvSchedule();
  };

  /**
   * Refetch envschedule/ api for refreshing data
   */
  const refetch = async () => {
    await apiReportEnvSchedule();
  };

  /**
   * Resetting states when closing edit sampling modal
   */
  const onConfirmEditOpen = () => {
    // setOpenScheduleSampleModal(false);
    setIsEditingSamplingFrequency(true);
  };

  /**
   * Handle edit task
   */
  const handleEditSchedule = async () => {
    if (isEditing && editSchedulingPayload) {
      setIsConfirmLoading(true);
      const payload = {
        ...editSchedulingPayload,
        edit_recurring: true, // editingData?.event_frequency !== "one-time" && editRecurring,
        action: "report-edit",
        scheduled_from: "report-add",
      };
      const response = await patchEnvSchedule(payload);
      if (response.success) {
        setIsEditing(false);
        setEditRecurring(true);
        setEditSchedulingPayload(null);
        setIsEditingSamplingFrequency(false);
        setNewSamplingEventData({
          samples: [],
          schedule: {
            dates: [],
            frequency: "",
          },
          event_name: "",
          action: "report-add",
          po: "",
        });
        setEditingData(null);
        setTabSequence(tabList);
        setSelectedTab(tabList[0]);
        setOpenScheduleSampleModal(false);
        toast.success("Sampling Plan has been updated");

        await refetch();
      } else {
        setIsEditingSamplingFrequency(false);
        toast.error(response.message);
      }
      setIsConfirmLoading(false);
    }
  };

  /**
   * Render Schedule Sampling Frequency button and dropdown when sampling is scheduled
   */
  const renderScheduleSampleFrequencyButton = () => {
    const { text: statusText } = getRecurringEventReadableText(scheduledDate, scheduleFrequency);
    const title = isSampleScheduled ? statusText : "Schedule Sampling Frequency";
    const description = isSampleScheduled ? undefined : "Automate your testing";
    const icon = isSampleScheduled ? <CalendarIcon width={20} height={20} /> : <AddSamplingIcon />;
    const onClick = (isSampleScheduled || loadingEnvSchedule) ? () => { } : () => setOpenScheduleSampleModal(true);

    const button = (
      <ReportActionButton
        title={title}
        description={description}
        icon={icon}
        onClick={onClick}
        isActionComplete={isSampleScheduled}
        showHoverStyles={!isSampleScheduled}
        loading={loadingEnvSchedule}
      />
    );

    if (isSampleScheduled) {
      return (
        <Dropdown
          rootClassName="ReportResult__ReportActionButton__Dropdown"
          disabled={loadingEnvSchedule}
          menu={{
            items: [
              {
                key: "edit-schedule",
                label: (
                  <div className="ReportResult__ReportActionButton__DropdownMenuItem">
                    <EditSpecIcon />
                    <span>Edit Schedule</span>
                  </div>
                ),
                onClick: () => {
                  if (!isEmpty(editingData)) {
                    setIsEditing(true);
                    setOpenScheduleSampleModal(true);
                  }
                },
              },
              {
                key: "delete-schedule",
                label: (
                  <div className="ReportResult__ReportActionButton__DropdownMenuItem">
                    <TrashIcon />
                    <span>Delete Schedule</span>
                  </div>
                ),
                onClick: () => setIsDeletingSamplingFrequency(true),
              },
            ],
          }}
          placement="bottom"
          trigger={["click"]}
        >
          {button}
        </Dropdown>
      );
    }

    return button;
  };

  /**
   * Renders Action buttons - Schedule Sampling Frequency and Edit Specs button
   */
  const renderActionButtons = () => {
    const canAddSpec = hasPermission("add_spec");
    return (
      <div className={`env-analytics-report-action-buttons ${canAddSpec ? "" : "EnvAnalyticsBtnCenterText"}`}>
        {hasFeature("environment_calendar") ? renderScheduleSampleFrequencyButton() : <div className="env-analytics-report-action-button-emptyspace" />}
        <ReportActionButton
          title={canAddSpec ? "Edit Specs" : "View Specs"}
          description={canAddSpec ? "Editing spec will affect reports" : ""}
          icon={canAddSpec ? <EditSpecIcon /> : null}
          onClick={!loadingSpecs ? () => editSpecs(true) : null}
        />
      </div>
    );
  };

  const renderSamplingEventModal = (
    <>
      {openScheduleSampleModal && (
        <CreateSamplingEventModal
          open={openScheduleSampleModal}
          onCancel={() => {
            refetch(); // Refetch data
            setOpenScheduleSampleModal(false);
            setNewSamplingEventData({
              samples: [],
              schedule: {
                dates: [],
                frequency: "",
              },
              event_name: "",
              action: "report-add",
              scheduled_from: "report-add",
              po: "",
            });
            setEditingData(null);
            setIsEditing(false);
            setTabSequence(tabList);
            setSelectedTab(tabList[0]);
          }}
          modalTitle={isEditing ? "Edit Sampling Plan" : "Add to Calendar"}
          locationId={pin.location_id}
          tabs={tabSequence}
          selectedTab={selectedTab}
          onTabChange={(tab) => setSelectedTab(tab)}
          disableSwitchFromTab
          newSamplingEventData={newSamplingEventData}
          setNewSamplingEventData={setNewSamplingEventData}
          onConfirmEdit={onConfirmEditOpen}
          editingData={editingData}
          isEditing={isEditing}
          setEditSchedulingPayload={setEditSchedulingPayload}
          showSearchbar={false}
          allowEmptySubmissionName
          hideSubmissionInput
          setHasEditEventFrequencyChanged={setHasEditEventFrequencyChanged}
        />
      )}
      {isEditingSamplingFrequency && (
        <EnvConfirmModal
          width="520px"
          headerText="Edit Schedule"
          bodyText="This will affect all recurring occurrences. Are you sure you want to proceed?" // (KEPT FOR FUTURE USE): You're about to change the date of a recurring event. This will affect all future occurrences.
          buttonText={["Cancel", "Confirm"]}
          buttonFunctions={[
            () => setIsEditingSamplingFrequency(false),
            () => handleEditSchedule(),
          ]}
          loading={isConfirmLoading}
        />
      )}
      {isDeletingSamplingFrequency && (
        <EnvConfirmModal
          width="520px"
          headerText="Delete Recurring Event"
          bodyText="This will remove all recurring events. Are you sure you want to proceed?" // (KEPT FOR FUTURE USE): This action will remove all future sampling events for this location.
          buttonText={["Cancel", "Delete"]}
          buttonFunctions={[
            () => setIsDeletingSamplingFrequency(false),
            () => handleDeleteEvent(),
          ]}
          loading={isConfirmLoading}
        />
      )}
    </>
  );

  const report = reportList.length > 0 && activeReportIdx < reportList.length ? reportList[activeReportIdx] : null;
  if (loadingReportList) {
    return (
      <div className="env-analytics-report-view-section env-report-view-report-section">
        <div className="loading-container">
          <LoadingDots fill="#B3BFDB" width={100} />
        </div>
      </div>
    );
  }

  if (reportList.length === 0) {
    return (
      <>
        <div className="env-analytics-report-view-section env-report-view-report-section">
          {renderActionButtons()}
          <ResultHeader report={report} />
          <div className="env-report-view-report-section-results-container">
            <NoResultsGIF image={pumpkinGIF} message="No reports yet" />
          </div>
        </div>
        {renderSamplingEventModal}
      </>
    );
  }

  return (
    <>
      <div className="env-analytics-report-view-section env-report-view-report-section">
        {renderActionButtons()}
        <ResultHeader report={report} pin={pin} />
        <div className="env-report-view-report-section-results-container">
          {showReportHistory ? (
            <ReportHistory
              locationID={pin?.location_id ?? ""}
              setShowReportHistory={setShowReportHistory}
            />
          ) : (
            <>
              <ResultTableCarousel
                specsData={specsData}
                activeDate={report?.received_date ?? ""}
                key={resultTableCarouselKey}
              />
              <ResultActions
                report={report}
                identifier={identifier}
                handleAddSpecsButton={handleAddSpecsButton}
                handlePreviewFile={handlePreviewFile}
                path={filePath ?? ""}
                setShowReportHistory={setShowReportHistory}
              />
              <div className="env-report-view-report-section-dates">
                <DatesSlider
                  reportList={reportList}
                  defaultIndex={defaultIndex}
                  activeReportIdx={activeReportIdx}
                  setActiveReportIdx={setActiveReportIdx}
                />
              </div>
            </>
          )}
        </div>
      </div>
      {renderSamplingEventModal}
    </>
  );
}
