import React, { useEffect, useState } from "react";

import NoResults from "../Common/NoResults";
import ReportThumbnail from "./ReportThumbnail";

import { getFileFromS3WithPresigned } from "../../utils/helpers";

import getAvailableFiles from "../../actions/share";

import watermelon from "../../assets/images/EmptyState_V2.png";
import DownloadIcon from "../../assets/images/share/Download.svg";
import ESVBanner from "../../assets/images/share/esvLogo.svg";

import "./share.css";

export default function Share() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  // onMount get the ID and make API call
  useEffect(() => {
    async function getFiles(id) {
      const response = await getAvailableFiles(id);
      let pdfPaths = [];

      if (response.success) {
        pdfPaths = response?.reports.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
      }
      const paths = pdfPaths.map((pdf) => pdf.path);
      getFileFromS3WithPresigned(paths, "private")
        .then((fileBlobObj) => {
          const blobUrls = [];

          pdfPaths.forEach((pdf) => {
            if (fileBlobObj[pdf.path]) {
              const blobObj = {
                url: window.URL.createObjectURL(fileBlobObj[pdf.path]),
                date: pdf.date,
                sample_id: response.sample_id,
                name: pdf.path.split("COAs/")[1],
              };

              blobUrls.push(blobObj);
            }
          });

          setData(blobUrls);
          setLoading(false);
        });
    }

    const id = window.location.href.split("/report/")[1];
    getFiles(id);
  }, []);

  const handleDownload = (item) => {
    const a = document.createElement("a");
    a.href = item.url;
    a.download = item.name;
    a.click();
  };

  return (
    <div className="shareContainer">
      <div className="shareHeader">
        <img src={ESVBanner} alt="logo" className="logoImage" />
      </div>

      <div className="shareContent">
        {data.length === 0 && !loading ? (
          <div className="shareContentErrorContainer">
            <NoResults image={watermelon} message="No files found" />
          </div>
        ) : (
          <>
            {data.length > 0 && data.map((dataItem, index) => (
              <div className="reportThumbnailMainContainer" key={index}>
                <ReportThumbnail key={index} blobData={dataItem} />
                <div className="sampleIdText">{dataItem.sample_id}</div>
                <img className="reportDownloadIcon" src={DownloadIcon} alt="Download Icon" onClick={() => handleDownload(dataItem)} />
              </div>
            ))}
          </>
        )}

      </div>

      <footer className="shareFooter">
        <span className="shareFooterCopyRight">© 2021 ESV, All rights reserved</span>
        <span>Genista Biosciences </span>
        <span>Inc.5500 Hellyer Avenue </span>
        <span>San Jose, CA 95138</span>
        <span>P. 1.408.934.6575</span>
      </footer>
    </div>
  );
}
