import React from "react";

import { AppContext } from "../../../../../AppContext";
import StyledButton from "../../../../Common/UIComponents/StyledButton";
import StyledTooltip from "../../../../Common/UIComponents/StyledTooltip";
import { QUADRANT_STYLE_MAP } from "../Constant";
import { checkPinIsPinned } from "../pinHelper";
import ConfirmModal from "./ConfirmModal";
import EditSpecTable from "./EditSpecTable";
import { checkIfSpecsInvalid } from "./Helper";
import SpecTable from "./SpecTable";

import { hasPermission } from "../../../../../utils/common";

import watermelon from "../../../../../assets/images/EmptyState_V2.png";

import "./EditSpec.css";

class EditSpec extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saveConfirmation: false,
      disableButton: false,
      saveLoading: false,
      canAddSpec: hasPermission("add_spec"),
    };
  }

  componentDidUpdate(prevProps) {
    const { dataOfSpecEditing } = this.props;
    if (prevProps.dataOfSpecEditing !== dataOfSpecEditing) {
      const response = checkIfSpecsInvalid(dataOfSpecEditing);
      this.setState({
        disableButton: response,
      });
    }
    return true;
  }

  handleSaveSpec = async (e) => {
    e.preventDefault();
    const { handleSaveSpec } = this.props;
    const { saveConfirmation } = this.state;
    this.saveConfirmation(true);
    if (saveConfirmation) {
      this.setState({ saveLoading: true });
      await handleSaveSpec();
      this.setState({ saveLoading: false });
      this.saveConfirmation(false);
    }
  }

  handleConfirmationPopup() {
    const { saveConfirmation, saveLoading } = this.state;
    if (saveConfirmation) {
      return (
        <ConfirmModal
          headerText="Add Specs to Swab?"
          bodyText="Adding specs will update the status of all reports linked to this swab."
          buttonText={["Cancel", "Update"]}
          buttonFunctions={[() => { this.setState({ saveConfirmation: false }); }, this.handleSaveSpec]}
          saveLoading={saveLoading}
        />
      );
    }
    return <></>;
  }

  callProperUpdateDataFunction = (specList) => {
    const { handleDataChange } = this.props;
    handleDataChange(specList);
    const response = checkIfSpecsInvalid(specList);
    this.setState({
      disableButton: response,
    });
  }

  saveConfirmation(value) {
    this.setState({
      saveConfirmation: value,
    });
    return value;
  }

  renderButtons() {
    const { disableButton, canAddSpec } = this.state;
    const { dataOfSpecEditing, editSpecs } = this.props;

    let title = "";
    if (disableButton) {
      title = "Missing or invalid inputs";
    }

    if (canAddSpec && dataOfSpecEditing.length) {
      return (
        <>
          <StyledButton
            onClick={() => editSpecs(false)}
            type="primary"
            ghost
          >
            Close
          </StyledButton>
          <StyledTooltip
            title={title}
            placement="top"
          >
            <StyledButton
              onClick={this.handleSaveSpec}
              disabled={disableButton}
              type="primary"
              className="editSaveBtn"
            >
              Update
            </StyledButton>
          </StyledTooltip>
        </>
      );
    } if (!canAddSpec || dataOfSpecEditing.length === 0) {
      return (
        <StyledButton
          onClick={() => editSpecs(false)}
          type="primary"
          ghost
        >
          Close
        </StyledButton>
      );
    }

    return null;
  }

  renderHeader() {
    const { pin, linkPatternFields, index0 } = this.props;
    const { canAddSpec } = this.state;
    const { color: blockColor } = checkPinIsPinned(pin) ? QUADRANT_STYLE_MAP[pin.quadrant] : { color: undefined }; // red
    const message = canAddSpec ? "Edit specs to automatically update the status of your reports on this swab" : "Test Specifications for this Swab";
    return (
      <div className="environmentEditSpecHeader">
        {canAddSpec
        && (
        <div className="environmentEditSpecHeader--message">
          {message}
        </div>
        )}
        <div className="environmentEditSpecHeader--title">
          <div className="environmentEditSpecHeader--left-border" style={{ backgroundColor: blockColor }} />
          <div className="environmentEditSpecHeader--fields">
            <span className="environmentEditSpecHeader--fields-link-report">
              {`${pin[index0]}`}
            </span>
            <div className="environmentEditSpecHeader--fields-link-field">
              {linkPatternFields.map(({ json_field, title_field }) => (
                json_field !== index0 && (
                <span className="environmentEditSpecHeader--field--other" key={json_field}>
                  {`${title_field}: ${pin[json_field]}`}
                </span>
                )
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderTable() {
    const { dataOfSpecEditing, loading, addingSpec } = this.props;
    const { canAddSpec } = this.state;
    if (!loading) {
      if (!dataOfSpecEditing.length) {
        return (
          <div className="environmentEditSpecNoResult">
            <img
              className="environmentEditSpecNoResultWatermelonImg"
              alt="no result"
              src={watermelon}
            />
            <div className="environmentEditSpecNoResultErrorMessage">
              No Tests Found
            </div>
          </div>
        );
      }
      if (canAddSpec) {
        return (
          <EditSpecTable
            addingSpec={addingSpec}
            dataOfSpecEditing={dataOfSpecEditing}
            handleDataChange={this.callProperUpdateDataFunction}
          />
        );
      }
      return (
        <SpecTable
          addingSpec={addingSpec}
          dataOfSpecEditing={dataOfSpecEditing}
          handleDataChange={this.callProperUpdateDataFunction}
        />
      );
    }
    return null;
  }

  render() {
    return (
      <div className="environmentEditSpecContainer">
        {this.renderHeader()}
        <div className="environmentReportEditSpecTableContainer">
          {this.renderTable()}
          {this.handleConfirmationPopup()}
        </div>
        <div
          id="environmentReportEditSpecButtons"
          className="environmentReportEditSpecButtonsSection"
        >
          { this.renderButtons()}
        </div>

      </div>
    );
  }
}

EditSpec.contextType = AppContext; // allows class component to consume context, access via this.context
export default EditSpec;
