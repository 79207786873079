import React from "react";

import { toast } from "react-toastify";
// import RetestButton from "./RetestButton";

import { downloadFileFromS3WithPresigned } from "../../../../../../utils/helpers";

import { ReactComponent as DownloadIcon } from "../../../../../../assets/images/environment/downloadIcon.svg";
import { ReactComponent as HistoryIcon } from "../../../../../../assets/images/environment/historyIcon.svg";
import { ReactComponent as PreviewIcon } from "../../../../../../assets/images/environment/previewIcon.svg";
import "./ResultActions.css";

export default function ResultActions({
  // report,
  // identifier,
  handlePreviewFile,
  // handleAddSpecsButton,
  path,
  setShowReportHistory,
}) {
  const handleDownloadFile = (filePath) => {
    if (!filePath || Object.keys(filePath).length < 1) {
      toast.error("Unable to download, no valid file path for this report!");
      return;
    }

    // Loop through the entire filesPath object
    const files = Object.entries(filePath);

    let totalFiles = 0;
    let filesDownloaded = 0;
    Promise.all(files.map(async (fileData) => {
      const fileTempSet = new Set(fileData[1]);
      const fileTempArr = Array.from(fileTempSet);
      return Promise.all(fileTempArr.map(async (fileTemp) => {
        totalFiles++;
        const fileName = fileTemp.split("/").pop();
        const isPrivateS3Bucket = fileTemp.includes("COAs/");
        if (isPrivateS3Bucket) {
          const source = fileTemp;
          const success = await downloadFileFromS3WithPresigned([source], "private", [fileName]);
          if (success) {
            filesDownloaded++;
          }
        } else {
          const source = fileTemp;
          const success = await downloadFileFromS3WithPresigned([source], "public", [fileName]);
          if (success) {
            filesDownloaded++;
          }
        }
      }));
    })).then(() => {
      if (filesDownloaded === totalFiles) {
        toast.success(`Successfully downloaded ${filesDownloaded}/${totalFiles} files.`);
      } else {
        toast.warning(`Downloaded ${filesDownloaded}/${totalFiles} files.`);
      }
    });
  };

  return (
    <div className="env-report-view-report-section-actions">
      <div className="env-analytics-view-report-section-actions-buttons">
        <PreviewIcon
          className={Object.keys(path).length < 1 ? "disabledAction" : ""}
          onClick={Object.keys(path).length > 0 ? () => handlePreviewFile(path || "") : () => { }}
        />
        <DownloadIcon
          className={Object.keys(path).length < 1 ? "disabledAction" : ""}
          onClick={Object.keys(path).length > 0 ? () => handleDownloadFile(path || "") : () => { }}
        />
        <HistoryIcon
          onClick={() => setShowReportHistory(true)}
        />
      </div>
      {/* {(report && report.specs_flag === "3")
        && (
        <Button
          onClick={() => handleAddSpecsButton(identifier)}
          className="ResultActions__AddSpecsBtn"
        >
          Add Specs
        </Button>
        )} */}
      {/* <RetestButton /> */}
    </div>
  );
}
