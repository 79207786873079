import React, { useContext, useEffect, useState } from "react";

import { Layout } from "antd";
import { Route, Switch, Redirect } from "react-router-dom";

import { AppContext } from "../AppContext";
import AddCompanyLandingPage from "./AddCompany/AddCompanyLandingPage";
import AuthFailedPage from "./AuthFailedPage";
import Documents from "./Documents/Documents";
import Env from "./Env/Env";
import Index from "./Index/Index";
import NavBar from "./Layout/NavBar";
import MyAccountHelp from "./MyAccount/MyAccountHelp";
import MyProfile from "./MyAccount/MyProfile/MyProfile";
import TeamMember from "./MyAccount/TeamMember/TeamMember";
import Product from "./Product/Product";
import EnvironmentLandingPage from "./SampleSubmission/Onboarding/EnvironmentOnboarding/EnvironmentLandingPage";
import ProductLandingPage from "./SampleSubmission/Onboarding/ProductOnboarding/ProductLandingPage";
import SampleSubmissionNav from "./SampleSubmission/SampleSubmissionNav";
import UnderMaintenance from "./UnderMaintenance";

import {
  isUser, getCodeNamesForRole, hasPermission, getUserCredentials,
} from "../utils/common";
import { ESV_DETAILS } from "../utils/Constant";

import { getTabsForCompany } from "../actions/switchCompany";

import LoadingImg from "../assets/images/under_maintenance.gif";

// import ChangeLog from "./Common/ChangeLog/ChangeLog";

const { Content } = Layout;

const LoggedIn = (routeProps) => {
  const { location, history } = routeProps;
  const [reports_link, setReports] = useState("");
  const [product_link, setProductLink] = useState("");
  const [sample_sub_link, setSampleSubLink] = useState("");
  const [loading, setLoading] = useState(false);
  const { userTitle } = useContext(AppContext);

  const optionalTabRoutes = [
    {
      tab: "index", path: "/index", exact: true, component: () => (<Index onChangeTab={setProductLink} />),
    },
    {
      tab: "product", path: "/product/report", exact: true, component: (props) => (<Product history={props.history} onChangeReport={setReports} />),
    },
    {
      tab: "product", path: "/product/template", exact: true, component: (props) => (<Product history={props.history} onChangeReport={setReports} />),
    },
    {
      tab: "product", path: "/product/analytics", exact: true, component: (props) => (<Product history={props.history} onChangeReport={setReports} />),
    },
    {
      tab: "environment", path: "/environment", exact: true, component: (props) => (<Env history={props.history} onChangeReport={setReports} />),
    },
    {
      tab: "environment", path: "/environment/calendar", exact: true, component: (props) => (<Env history={props.history} onChangeReport={setReports} />),
    },
    {
      tab: "environment", path: "/environment/analytics", exact: true, component: (props) => (<Env history={props.history} onChangeReport={setReports} />),
    },
    {
      tab: "documents", path: "/documents", exact: true, component: Documents,
    },
    {
      tab: "sample-submission", path: "/sample-submission", exact: false, component: (props) => (<SampleSubmissionNav history={props.history} setSampleSubLink={setSampleSubLink} />),
    },
    {
      tab: "sample-submission",
      path: "/onboarding/product",
      exact: true,
      component: () => (<ProductLandingPage />),
    },
    {
      tab: "sample-submission",
      path: "/onboarding/environment",
      exact: true,
      component: () => (<EnvironmentLandingPage />),
    },
  ];
  const [routes, setRoutes] = useState(optionalTabRoutes);
  const FALLBACK_ROUTES = ["/maintenance", "/auth-failed"];

  /** Fetch tabs for the company on component mount */
  useEffect(() => {
    async function apiGetTabs() {
      setLoading(true);
      const { tabsList, featuresList } = await getTabsForCompany();
      const filteredRoutes = optionalTabRoutes.filter(({ tab }) => tabsList.includes(tab));
      let finalRoutes = [...filteredRoutes];
      const permissions = getCodeNamesForRole(userTitle, getUserCredentials()?.uid);

      // Adjust finalRoutes if "product" tab is included
      if (tabsList.includes("product")) {
        finalRoutes = filteredRoutes.filter((item) => item.path !== "/onboarding/product");
      }

      // Adjust finalRoutes if "environment" tab is included
      if (tabsList.includes("environment")) {
        finalRoutes = finalRoutes.filter((item) => item.path !== "/onboarding/environment");
      }
      sessionStorage.setItem(ESV_DETAILS, JSON.stringify({ tabsList, featuresList, permissions }));
      setRoutes(finalRoutes);
      setLoading(false);
    }
    // Check if the user is authenticated before fetching tabs
    if (isUser()) {
      if (!FALLBACK_ROUTES.includes(location.pathname)) {
        apiGetTabs();
      }
    }
  }, []); //eslint-disable-line

  if (!isUser()) {
    return <Redirect to="/login" />;
  }

  if (loading) {
    return (
      <div className="LoggedIn__Loading">
        <img src={LoadingImg} alt="under_maintenance" width={450} />
        <span className="LoggedIn__Loading__Text">Preparing your dashboard...</span>
      </div>
    );
  }

  const layoutStyle = { background: "#FDFDFD" };

  return (
    <Layout>
      {(!FALLBACK_ROUTES.includes(location.pathname)
        && (
        <NavBar
          history={history}
          // analytics={analytics}
          reports={reports_link}
          product_link={product_link}
          sample_sub_link={sample_sub_link}
        />
        ))}
      <Layout
        className={`${FALLBACK_ROUTES.includes(location.pathname) ? "" : "site-layout"} sm-margin-0`}
        style={layoutStyle}
      >
        <Content
          style={FALLBACK_ROUTES.includes(location.pathname) ? {} : { marginTop: "60px", background: "#FDFDFD" }}
        >
          <Switch>
            {routes.map(({ path, component, exact }) => (<Route key={path} path={path} exact={exact} component={component} />))}
            <Route path="/myaccount/profile" exact component={MyProfile} />
            <Route path="/myaccount/members" exact component={TeamMember} />
            <Route path="/myaccount/help" exact component={MyAccountHelp} />
            {hasPermission("add_company") && <Route path="/add-company" exact component={(props) => (<AddCompanyLandingPage history={props.history} />)} />}
            <Route path="/maintenance" component={(props) => (<UnderMaintenance history={props.history} />)} />
            <Route path="/auth-failed" component={(props) => (<AuthFailedPage history={props.history} />)} />
            <Route path="/" render={() => <Redirect to="/sample-submission" />} />
          </Switch>
        </Content>
      </Layout>
    </Layout>
  );
};

export default LoggedIn;
