import axios from "axios";

const API_URL = "https://devbackendlink.esvsupplier.com/api";

/**
 * Calls api to get all printers
 */
export const getPrinters = async () => {
  try {
    const response = await axios.get(`${API_URL}/printers/`);
    if (response.data.message.toLowerCase() === "success") {
      return { success: true, ...response.data };
    }
    return { success: false, message: response?.data?.message || "Something went wrong." };
  } catch (e) {
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

/**
 * Calls api to add a new printer
 * @param {{ name: string, label_type: string, serial_number: string}} printer new printer data
 * @param {*} signal AbortSignal
 */
export const postPrinters = async (printer, signal) => {
  try {
    const response = await axios.post(`${API_URL}/printers/`, { printer }, { signal });
    if (response.data.message.toLowerCase() === "success") {
      return { success: true, result: response.data.result };
    }
    return { success: false, message: response?.data?.message || "Something went wrong." };
  } catch (e) {
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

/**
 * Calls api to edit a printer data
 * @param {{ id: string, name: string, label_type: string, serial_number: string}} printer printer data
 * @param {*} signal AbortSignal
 */
export const patchPrinters = async (printer, signal) => {
  try {
    const response = await axios.patch(`${API_URL}/printers/`, { printer }, { signal });
    if (response.data.message.toLowerCase() === "success") {
      return { success: true, ...response.data };
    }
    return { success: false, message: response?.data?.message || "Something went wrong." };
  } catch (e) {
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};

/**
 * Calls api to delete a printer
 * @param {string} id printer id
 * @param {*} signal AbortSignal
 */
export const deletePrinters = async (id, signal) => {
  try {
    const data = { printer: { id } };
    const response = await axios.delete(`${API_URL}/printers/`, { data, signal });
    if (response.data.message.toLowerCase() === "success") {
      return { success: true, ...response.data };
    }
    return { success: false, message: response?.data?.message || "Something went wrong." };
  } catch (e) {
    return { success: false, message: e.response?.data?.message || "Something went wrong." };
  }
};
