import React, { useState } from "react";

import { InputNumber, Select } from "antd";
import { keys } from "lodash";
import { toast } from "react-toastify";

import StyledButton from "../../../Common/UIComponents/StyledButton";
import EnvConfirmModal from "../../Analytics/Components/EnvConfirmModal";

import { AWS_BASE_PATH } from "../../../../utils/Constant";
import { getFileFromS3WithPresigned } from "../../../../utils/helpers";

import { sendFileToPrinter } from "../../../../actions/envCalendar";

import { ReactComponent as EditIcon } from "../../../../assets/images/sample-submission/edit.svg";

export default function ZebraPrinterSettings({
  onToggleEditPrinters, onSelect, printerDropdownOptions, onCopiesChange, isLoading,
  onClose, totalItems, config, scheduleData,
}) {
  const [disableCancelAllPrints] = useState(false);
  const [cancelPrintClicked, setCancelPrintClicked] = useState(false);
  const [cancellingPrint, setCancellingPrint] = useState(false);
  const [printing, setPrinting] = useState(false);
  const LABEL_FILE_SIZE_LIMIT_5MB = 5000000;

  // Remove the defer backward command at the end of the template to execute the cut action
  const removeDeferBackCmd = (template) => {
    const parts = template.split("^XB");
    const result = parts.slice(0, -1).join("^XB") + parts[parts.length - 1];
    return result;
  };

  // Create x number of copies of the template
  const duplicateTemplateByCopies = (template) => {
    let currTemplate = "";
    for (let i = 0; i < config.copies; i++) {
      // Remove the defer backward command at the end of the template to execute the cut action
      const isLastCopy = i === config.copies - 1;
      currTemplate += isLastCopy ? removeDeferBackCmd(template) : template;
    }
    return currTemplate;
  };

  /** Send the final label data to the printer */
  const sendLabelToPrinterApi = async (template, type = "Print") => {
    const exceededLimitMsg = "File size limit of 5MB exceeded. Please reduce the number of copies.";
    const createCopies = type === "Cancel" ? template : duplicateTemplateByCopies(template);
    /** Create a binary file */
    const blob = new Blob([createCopies], { type: "text/plain" });
    // Make sure the file size is not greater than 5MB for safety
    const fileSize = blob.size;
    if (fileSize > LABEL_FILE_SIZE_LIMIT_5MB) {
      toast.error(exceededLimitMsg);
    }
    const payload = new FormData();
    payload.append("sn", config.serial_number);
    payload.append("zpl_file", blob);

    const { success, message } = await sendFileToPrinter(payload);
    if (type === "Cancel") {
      setCancellingPrint(false);
      setCancelPrintClicked(false);
    } else {
      setPrinting(false);
    }
    if (success) {
      const successMessage = type === "Print" ? "The label(s) have been sent to the printer successfully" : "All labels have been cancelled successfully";
      toast.success(successMessage);
    } else {
      toast.error(message);
    }
  };

  // For each sample, replace the placeholders to target values (event_id, sample id, event date)
  const appendLabelValuesToTemplate = (zplTemplateString) => {
    let outputTemplate = "";
    scheduleData.forEach((item) => {
      const { event_id: barcodeData, submission_name: sampleID, event_date: eventDate } = item;
      const labelData = {
        barcodeData,
        sampleID,
        eventDate,
      };
      let updateTemplate = zplTemplateString;
      // replace the placeholders to target values (event_id, sample id, event date)
      keys(labelData).forEach((key) => {
        updateTemplate = updateTemplate.replace(key, labelData[key]);
      });
      outputTemplate += updateTemplate;
      console.log("\nQR Code Content: ", barcodeData);
      console.log("\nSample ID:", sampleID, "\nEvent Date:", eventDate);
    });
    return outputTemplate;
  };

  const onPrint = async () => {
    if (!config.serial_number) {
      toast.error("Please select a printer");
      return;
    }
    setPrinting(true);
    // Fetch the template string
    const filePath = `${AWS_BASE_PATH}media/app/sampleLabelTemplate.zpl`;
    const fileBlobObj = await getFileFromS3WithPresigned([filePath], "private");
    if (!(fileBlobObj && fileBlobObj[filePath])) {
      setPrinting(false);
      toast.error("Something went wrong");
      return;
    }
    const fileBlob = fileBlobObj[filePath];
    const zplTemplateString = await fileBlob.text();
    // Append all label values to the template
    const outputTemplate = appendLabelValuesToTemplate(zplTemplateString);
    // Send the final label template to the printer
    sendLabelToPrinterApi(outputTemplate);
  };

  const onCancelClick = () => {
    if (!config.serial_number) {
      toast.error("Please select a printer");
      return;
    }
    setCancelPrintClicked(true);
  };

  const onCancelAllPrints = () => {
    setCancellingPrint(true);
    sendLabelToPrinterApi("~JA", "Cancel"); // "~JA" means clear all jobs
  };

  return (
    <div className="ZebraPrintModal__Content">
      <div className="ZebraPrintModal__ButtonPanel">
        <span className="ZebraPrintModal__Header__Title">Select Printer</span>
        <div className="ZebraPrintModal__Buttons">
          <StyledButton
            htmlType="button"
            type="primary"
            className="ZebraPrintModal__Button"
            onClick={onToggleEditPrinters}
          >
            <EditIcon />
            Edit Printers
          </StyledButton>
        </div>
      </div>
      <div className="ZebraPrintModal__ContentContainer">
        <div className="ZebraPrintModal__InputContainer">
          <label htmlFor="support-login-select" className="ZebraPrintModal__Input__Label">Printer</label>
          <Select
            id="printer-select"
            placeholder="Please select a printer"
            showSearch
            value={config.serial_number}
            onSelect={onSelect}
            options={printerDropdownOptions}
            disabled={isLoading}
            // status={printerSelectError ? "error" : undefined}
            rootClassName="ZebraPrintModal__Select"
            popupClassName="ZebraPrintModal__Select__Popup"
            loading={isLoading}
          />
        </div>
        <div className="ZebraPrintModal__InputContainer">
          <div className="ZebraPrintModal__InputContainer Inline">
            <label htmlFor="copies-input" className="ZebraPrintModal__Input__Label">Copies</label>
            <InputNumber
              type="number"
              id="copies-input"
              className="ZebraPrintModal__CopiesInput"
              value={config.copies}
              onChange={onCopiesChange}
              min={1}
              max={100}
            />
          </div>
        </div>
        <div className="ZebraPrintModal__InputContainer Inline">
          <label className="ZebraPrintModal__Input__Label--Bold">
            Total Labels:
            {" "}
            {config.copies * totalItems}
          </label>
        </div>
      </div>
      <div className="ZebraPrintModal__ButtonPanel">
        <StyledButton
          htmlType="button"
          type="primary"
          ghost
          className="ZebraPrintModal__CancelAllPrintsButton"
          onClick={onCancelClick}
          disabled={disableCancelAllPrints}
        >
          Cancel all prints
        </StyledButton>
        <div className="ZebraPrintModal__Buttons">
          <StyledButton
            htmlType="button"
            type="primary"
            ghost
            onClick={onClose}
          >
            Close
          </StyledButton>
          <StyledButton
            htmlType="button"
            type="primary"
            onClick={onPrint}
            loading={printing}
          >
            Print
          </StyledButton>
        </div>
      </div>
      {cancelPrintClicked && (
        <EnvConfirmModal
          width="520px"
          headerText="Cancel All Prints"
          bodyText="Are you sure you want to cancel all prints?"
          buttonText={["Cancel", "Confirm"]}
          buttonFunctions={[
            () => {
              setCancelPrintClicked(false);
            },
            () => onCancelAllPrints(),
          ]}
          loading={cancellingPrint}
        />
      )}
    </div>
  );
}
