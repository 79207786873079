import axios from "axios";
import { keys, merge, toPairs } from "lodash";
import moment from "moment";

import { getUserCredentials } from "./common";
import {
  API_URL, AWS_BASE_PATH, AWS_REGION, ESV_PRIV_BUCKET, ESV_PUB_BUCKET,
} from "./Constant";

export const getViewAsPerStatusCode = (error) => {
  try {
    if (
      error.request.status === 404
      || (error.request.status >= 500 && error.request.status <= 599)
    ) {
      window.location.pathname = "/maintenance";
    }
  } catch (e) {
    window.location.pathname = "/maintenance";
  }
};

export const formatDateForDisplay = (date) => moment(new Date(date)).format("YYYY/MM/DD");

export const getValueOfObj = (objName, ...fields) => {
  let objValue = { ...objName };
  if (objValue !== undefined) {
    for (let i = 0; i < fields.length; i++) {
      if (objValue[fields[i]] !== undefined) {
        objValue = objValue[fields[i]];
      } else {
        return undefined;
      }
    }
    return objValue;
  }
  return undefined;
};

export const isNonEmptyField = (arr, field) => (arr !== undefined) && (arr[field] !== undefined);

/**
 * Gets filename from path and returns the name and extension.
 * @param {String} path file path/filename
 * @returns {object} { name, ext }
 */
export const getFileNameAndExtensionFromPath = (path) => {
  const basename = path.split("/").pop(); // extract file name from full path
  const pos = basename.lastIndexOf("."); // get last position of "."

  if (basename === "" || pos < 1) { // if file name is empty or "." not found (-1) or comes first (0)
    return { name: basename, ext: "" };
  }

  return { name: basename.slice(0, pos), ext: basename.slice(pos) }; // extract filename and extension (with ".")
};

/**
 * Remove timestamp from filename (preserves extension). Filename must in the form of "something_TIMESTAMP.ext" (.ext is optional).
 * @param {String} path file path/filename
 * @returns {String} filename with timestamp removed
 */
export const getFileNameWithTimestampRemoved = (path) => {
  const { name, ext } = getFileNameAndExtensionFromPath(path);
  const nameArr = name.split("_");
  nameArr.pop();
  return `${nameArr.join("_")}${ext}`;
};

/**
 * Fetch a presigned S3 URL for GET and POST
 * @param {"get" | "post" | "delete"} action get/post/delete actions
 * @param {object} aws_s3_urls Array of objects with keys bucket, filepath, region, content_type
 * */
export const getPresignedURL = async (action = "get", aws_s3_urls) => {
  try {
    const response = await axios.post(
      `${API_URL}/s3_presigned_url/`,
      {
        action,
        aws_s3_urls,
      },
    );
    const data = await response.data;
    return { success: true, ...data };
  } catch (error) {
    return { success: false, error };
  }
};

const MAX_S3_RETRIES = 3;
const S3_RETRY_DELAY = 1000;

/**
 * Pause execution for given time
 * @param {number} timeout timeout
 */
const pause = (timeout) => new Promise((resolve) => {
  setTimeout(resolve, timeout);
});

/**
 * Get file from AWS S3 using file url
 * @param {string} filePath file path
 * @param {string} fileUrl file url
 * @returns key-value pair of file path as key and presigned file url as value
 */
export const getFiles = async (filePath, fileUrl) => {
  let attempts = 0;
  while (attempts < MAX_S3_RETRIES) {
    try {
      // eslint-disable-next-line no-await-in-loop
      const response = await axios.get(fileUrl, {
        responseType: "blob",
        headers: {
          "Cache-Control": "no-cache",
        },
      });
      // eslint-disable-next-line no-await-in-loop
      const blob = await response.data;
      const fileBlob = new Blob([blob], { type: blob.type });
      return { [filePath]: fileBlob };
    } catch (e) {
      attempts++;
      if (attempts >= MAX_S3_RETRIES || e?.response?.status !== 503) {
        // if no more attempts or the current error is not 503 (SlowDown), then return the error
        // const errorCode = e?.response?.status === 404 ? "NoSuchKey" : "";
        return {};
      }
      // eslint-disable-next-line no-await-in-loop
      await pause(S3_RETRY_DELAY);
    }
  }
  return {};
};

/**
 * Fetch file from s3 using presigned url
 * @param {Array<string>} filePaths file paths as array
 * @param {"private" | "public"} aws_bucket private bucket/public bucket
 * @returns Key-value pair of path and respective presigned url
 */
export const getFileFromS3WithPresigned = async (filePaths, aws_bucket = "private") => {
  const bucket = aws_bucket === "private" ? ESV_PRIV_BUCKET : ESV_PUB_BUCKET;
  const urls = filePaths.map((path) => ({
    bucket,
    path,
    region: AWS_REGION,
  }));
  const presignedUrls = await getPresignedURL("get", urls);
  if (presignedUrls.success) {
    const getFilePromise = keys(presignedUrls.result).map((key) => getFiles(key, presignedUrls.result[key]));
    const fileResults = await Promise.all(getFilePromise);
    return merge(...fileResults);
  }
  return null;
};

/**
 * Download fetched file from s3 using presigned url
 * @param {Array<string>} filePaths file paths as array
 * @param {"private" | "public"} aws_bucket private bucket/public bucket
 * @param {string} fileNames file names array for each file to download
 */
export const downloadFileFromS3WithPresigned = async (filePaths, bucket = "private", fileNames = []) => {
  const fileBlobObjs = await getFileFromS3WithPresigned(filePaths, bucket);
  if (fileBlobObjs) {
    keys(fileBlobObjs).forEach((filePath, index) => {
      const blob = fileBlobObjs[filePath];
      const url = window.URL.createObjectURL(blob);
      const downloadFilename = fileNames.length > 0 && fileNames[index] ? fileNames[index] : filePath;
      if (url) {
        const a = document.createElement("a");
        a.href = url;
        a.download = downloadFilename;
        a.click();
        a.remove();
      }
    });
    return true;
  }
  return false;
};

/**
 * Takes file information and generates aws s3 file path
 * @param {*} fileInfo file information
 * @returns file path
 */
export const getFilePath = (fileInfo) => {
  const {
    folderPath, fileName, // folderPath ex: "ProductTemplate/Template/"
  } = fileInfo;
  const user = getUserCredentials();
  const sanitizedFileName = fileName.replace(/\s|\//g, "_");
  const path = `${AWS_BASE_PATH}media/${user.company_domain}/${user.company_name}/${folderPath}${sanitizedFileName}`;
  return path;
};

/**
 * Calls api to upload file using presigned url
 * @param {*} presignedData presigned url with file data
 * @param {*} file file
 * @returns Object having success status, file path, file type
 */
export const postFile = async (presignedData, file) => {
  let attempts = 0;
  while (attempts < MAX_S3_RETRIES) {
    try {
      // eslint-disable-next-line no-await-in-loop
      const { url, fields } = presignedData;
      // Create FormData
      const formData = new FormData();
      toPairs(fields).forEach(([fieldKey, fieldValue]) => {
        if (fieldKey !== "Content-Type") {
          formData.append(fieldKey, fieldValue);
        }
      });
      // Add the file
      formData.append("file", file);
      // POST to S3
      // eslint-disable-next-line no-await-in-loop
      await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return {
        [fields.key]: { success: true, path: fields.key, type: file.type ? file.type : "" },
      };
    } catch (e) {
      attempts++;
      if (attempts >= MAX_S3_RETRIES || e?.response?.status !== 503) {
        // if no more attempts or the current error is not 503 (SlowDown), then return the error
        // const errorCode = e?.response?.status === 404 ? "NoSuchKey" : "";
        return {
          [presignedData.fields.key]: { path: "", type: file.type },
        };
      }
      // eslint-disable-next-line no-await-in-loop
      await pause(S3_RETRY_DELAY);
    }
  }
  return {
    [presignedData.fields.key]: { path: "", type: file.type },
  };
};

/**
 * Calls presigned api to generate presigned url for uploading files
 * @param {*} fileInfoList Array having each file information
 * @param {"private" | "public"} aws_bucket public bucket/private bucket
 * @returns Object with key value pairs of file path as key and uploaded file information as value
 */
export const uploadFileToS3WithPresigned = async (fileInfoList, aws_bucket = "private") => {
  const bucket = aws_bucket === "private" ? ESV_PRIV_BUCKET : ESV_PUB_BUCKET;

  const pathObj = {};
  const fileObj = {};
  const urls = fileInfoList.reduce((acc, fileInfo) => {
    const {
      file, type,
    } = fileInfo;
    const key = getFilePath(fileInfo);
    if (!file) {
      pathObj[key] = { path: "", type };
    } else {
      fileObj[key] = file;
      acc.push({
        bucket,
        path: key,
        region: AWS_REGION,
        content_type: file.type,
      });
    }
    return acc;
  }, []);
  const presignedUrls = await getPresignedURL("post", urls);
  if (presignedUrls.success) {
    const postFilesResults = await Promise.all(urls.map((url) => postFile(presignedUrls.result[url.path], fileObj[url.path])));
    return merge(...postFilesResults, pathObj);
  }
  return null;
};

/**
 * Delete file from s3 using presigned url
 * @param {Array<string>} filePaths file paths as array
 * @param {"private" | "public"} aws_bucket private bucket/public bucket
 */
export const deleteFileFromS3WithPresigned = async (filePaths, aws_bucket = "private") => {
  const bucket = aws_bucket === "private" ? ESV_PRIV_BUCKET : ESV_PUB_BUCKET;
  const urls = filePaths.map((path) => ({
    bucket,
    path,
    region: AWS_REGION,
  }));
  const presignedUrls = await getPresignedURL("delete", urls);
  return presignedUrls.success;
};

/**
 * Fetch file from given S3 bucket
 * @param {String} filePath path of file in aws
 * @param {S3} s3 one of the s3 objects initialized in aws.js
 * @returns {Promise} resolves to a Blob if success, else "" if there's an error
 */
export const getFileFromS3 = (filePath, s3) => new Promise((resolve) => {
  s3.getObject(
    { Bucket: s3.config.bucket, Key: filePath },
    (error, data) => {
      if (error != null) {
        return resolve("");
      }
      const fileBlob = new Blob([data.Body], { type: data.ContentType ? data.ContentType : "" });
      if (fileBlob) {
        return resolve(fileBlob);
      }
      return resolve("");
    },
  );
});

/**
 * Fetch file from given S3 bucket, then downloads file.
 * @param {String} filePath path of file in aws
 * @param {S3} s3 one of the s3 objects initialized in aws.js
 * @param {String} fileName name of the downloaded file (what the user sees). If not provided, filePath is used.
 * @returns {Boolean} true if successful, else false
 */
export const downloadFileFromS3 = async (filePath, s3, fileName = "") => {
  const fileBlob = await getFileFromS3(filePath, s3);
  if (fileBlob) {
    const url = window.URL.createObjectURL(fileBlob);
    if (url) {
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName || filePath;
      a.click();
      return true;
    }
    return false;
  }
  return false;
};

/**
 * Upload file to given S3 bucket. Sanitizes file name, constructs file key, and determines part size before uploading.
 * @param {Object} fileInfo { file: File, folderPath: String, fileName: String, type: String }
 * @param {S3} s3
 * @returns {Promise} resolves { path, type } if successful else ""
 */
export const uploadFileToS3 = (fileInfo, s3) => new Promise((resolve) => {
  const {
    file, folderPath, fileName, type, // folderPath ex: "ProductTemplate/Template/"
  } = fileInfo;
  if (!file) {
    return resolve({ path: "", type });
  }

  const sizeInMB = file.size / (1024 * 1024);
  const partSizeInMB = Math.max(5, Math.ceil(sizeInMB));

  const user = getUserCredentials();
  const sanitizedFileName = fileName.replace(/\s|\//g, "_");
  const key = `${AWS_BASE_PATH}media/${user.company_domain}/${user.company_name}/${folderPath}${sanitizedFileName}`;

  s3.upload(
    {
      Bucket: s3.config.bucket,
      Key: key,
      Body: file,
      ContentType: file.type,
      // ACL: s3.config.ACL, // ACL (access control list) defaults to that of the bucket
    },
    { partSize: partSizeInMB * 1024 * 1024 },
    (err, success) => {
      if (err) return resolve("");
      const location = success.Key;
      return resolve({ path: location, type: file.type ? file.type : "" });
    },
  );
  return "";
});

export const deleteFileFromS3 = async (file, s3) => {
  s3.deleteObject(
    { Bucket: s3.config.bucket, Key: file },
    (err) => {
      if (err) {
        return false;
      } return true;
    },
  );
};
