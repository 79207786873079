import React, { useState, useContext } from "react";

import { Drawer } from "antd";

import { AppContext } from "../../AppContext";
import StyledButton from "../Common/UIComponents/StyledButton";
import SignOutConfirmation from "../Modals/SignoutConfirmation";
import CompanyList from "./CompanyList";

import { signOut, hasPermission, getAppVersion } from "../../utils/common";

import { ReactComponent as Close } from "../../assets/images/close.svg";
import { ReactComponent as LogoutIcon } from "../../assets/images/logout.svg";
import { ReactComponent as MyAccountIcon } from "../../assets/images/myAccountIcon.svg";
import { ReactComponent as Plus } from "../../assets/images/onboarding/Plus.svg";

import "./DrawerNav.css";

export default function DrawerNav({
  handleNavDrawer,
  history,
  navDrawer,
  userInfo,
}) {
  const canAddCompany = hasPermission("add_company");
  const [signOutConfirm, setSignOutConfirm] = useState(false);
  const [logoutHovered, steLogoutHovered] = useState(false);

  const appContext = useContext(AppContext);

  const onSignOutConfirm = () => {
    setSignOutConfirm(false);
    signOut(history, appContext);
  };

  const onSignOutCancel = () => {
    setSignOutConfirm(false);
  };

  const handleMenuMouseEnter = () => {
    steLogoutHovered(true);
  };

  const handleMenuMouseLeave = () => {
    steLogoutHovered(false);
  };

  const handleAddCompany = () => {
    history.push("/add-company");
    handleNavDrawer();
  };

  const { user_name } = userInfo;
  const accountTitle = user_name ? `Hi, ${user_name}` : "Hi";
  return (
    <Drawer
      placement="right"
      open={navDrawer}
      closable={false}
      onClose={handleNavDrawer}
      width={212}
      className="DrawerNav__Container"
    >
      {signOutConfirm && (
        <SignOutConfirmation
          onConfirm={onSignOutConfirm}
          onCancel={onSignOutCancel}
        />
      )}
      <div className="DrawerNav__Header">
        <div className="DrawerNav__HeaderAccountInfo">
          <MyAccountIcon className="userIcon" />
          <span className="DrawerNav__UserName" title={accountTitle}>{accountTitle}</span>
        </div>
        <Close
          className="DrawerNav__HeaderCloseButton"
          onClick={handleNavDrawer}
        />
      </div>
      <div className="DrawerNav__RoutesContainer">
        <span
          onClick={() => {
            history.push("/myaccount/profile");
            handleNavDrawer();
          }}
        >
          My Profile
        </span>
        <span
          onClick={() => {
            history.push("/myaccount/members");
            handleNavDrawer();
          }}
        >
          Team Members
        </span>
        <span
          className="DrawerNav__LogOutButton"
          onClick={() => setSignOutConfirm(true)}
          onMouseEnter={() => handleMenuMouseEnter()}
          onMouseLeave={() => handleMenuMouseLeave()}
        >
          <LogoutIcon className={`DrawerNav__LogOutButton--image ${logoutHovered ? "DrawerNav__LogOutButton--hover" : ""}`} />
          Log Out
        </span>
      </div>
      <div className="DrawerNav__CompaniesContainer">
        <div className="DrawerNav__CompaniesContainer--header">COMPANY</div>
        <CompanyList closeNavbar={handleNavDrawer} navDrawer={navDrawer} />
        {canAddCompany
          && (
            <div className="DrawerNav__AddCompany">
              <StyledButton type="primary" onClick={handleAddCompany} className="DrawerNav__AddCompany__Btn">
                <Plus className="plusIcon" />
                Add Company
              </StyledButton>
            </div>
          )}
      </div>
      <div className="DrawerNav__Footer"><p className="DrawerNav__Version">{getAppVersion()}</p></div>
    </Drawer>
  );
}
