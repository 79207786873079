import React from "react";
import "../../../Env.css";
import "./SpecManager.css";

import { AppContext } from "../../../../../AppContext";
import StyledTooltip from "../../../../Common/UIComponents/StyledTooltip";
import ConfirmModal from "./ConfirmModal";
import EditSpecTable from "./EditSpecTable";
import { checkIfSpecsComplete } from "./Helper";
import SpecTable from "./SpecTable";

import { hasPermission } from "../../../../../utils/common";

import pumpkin from "../../../../../assets/images/environment/pumpkin.png";
import backIcon from "../../../../../assets/images/product/backIconDarkBlue.png";

import "./EditSpec.css";

class EditSpec extends React.Component {
  constructor(props) {
    super(props);
    const { addingSpec, editingSpec } = this.props;

    this.state = {
      saveConfirmation: false,
      editing: editingSpec || false,
      specsComplete: false,
      addingSpecState: addingSpec,
    };

    this.handleExitModal = this.handleExitModal.bind(this);
    this.handleEditButtonClicked = this.handleEditButtonClicked.bind(this);
  }

  componentDidMount() {
    const {
      addingSpec,
    } = this.props;

    if (addingSpec) {
      this.setState({
        editing: true,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { dataOfSpecEditing } = this.props;

    if (prevProps.dataOfSpecEditing !== dataOfSpecEditing) {
      const response = checkIfSpecsComplete(dataOfSpecEditing);
      this.setState({
        specsComplete: response,
      });
    }

    return true;
  }

  handleClearSpec = (e) => {
    const { dataOfSpecEditing, handleDataChange } = this.props;
    e.preventDefault();
    const specList = dataOfSpecEditing;
    const specListCleared = specList.map((spec) => ({
      test: spec.test,
      test_type: "",
      specs: [],
    }));
    handleDataChange(specListCleared);
    // this.props.handleSaveSpec(true);
  }

  handleSaveSpec = async (e) => {
    const {
      handleSaveSpec, handleSpecChanged, fromModal, handleClosingEditSpec,
    } = this.props;

    this.saveConfirmation(true);

    e.preventDefault();
    if (this.state.saveConfirmation) { // eslint-disable-line
      this.saveConfirmation(false);
      const response = await handleSaveSpec();

      if (response === true) {
        if (fromModal) {
          handleClosingEditSpec(true);
        } else {
          this.setState({
            editing: false,
            addingSpecState: false,
          });

          handleSpecChanged(false);
        }
      }
    }
  }

  handleConfirmationPopup() {
    const { saveConfirmation, addingSpecState } = this.state;
    const { identifier } = this.props;

    if (saveConfirmation && addingSpecState) {
      return (
        <ConfirmModal
          headerText="Add Specs to Environment?"
          bodyText={`Adding specs will update the status of all samples linked to ${identifier}. This cannot be undone.`}
          buttonText={["Cancel", "Add"]}
          buttonFunctions={[() => { this.setState({ saveConfirmation: false }); }, this.handleSaveSpec]}
        />
      );
    }

    if (saveConfirmation && !addingSpecState) {
      return (
        <ConfirmModal
          headerText="Update Specs to Environment?"
          bodyText={`Adding specs will update the status of all samples linked to ${identifier}. This cannot be undone.`}
          buttonText={["Cancel", "Update"]}
          buttonFunctions={[() => { this.setState({ saveConfirmation: false }); }, this.handleSaveSpec]}
        />
      );
    }

    return <></>;
  }

  handleExitModal() {
    const {
      confirmExitModalDisplayToggle, handleExitEdit, specChanged, valueHasBeenEdited,
    } = this.props;
    const { editing } = this.state;

    if (editing && specChanged) {
      const response = confirmExitModalDisplayToggle();
      this.setState({
        editing: response,
      });
    } else {
      handleExitEdit(false, valueHasBeenEdited);
    }
  }

  handleEditButtonClicked() {
    const { fromModal, dataOfSpecEditing } = this.props;
    this.setState({
      editing: true,
    });

    if (!fromModal) {
      if (dataOfSpecEditing.length !== 0) {
        const response = checkIfSpecsComplete(dataOfSpecEditing);
        this.setState({
          specsComplete: response,
        });
      } else {
        this.setState({
          specsComplete: false,
        });
      }
    }
  }

  callProperUpdateDataFunction = (specList) => {
    const { handleDataChange, handleSpecChanged } = this.props;

    handleDataChange(specList);
    handleSpecChanged(true);

    const response = checkIfSpecsComplete(specList);
    this.setState({
      specsComplete: response,
    });
  }

  saveConfirmation(value) {
    this.setState({
      saveConfirmation: value,
    });

    return value;
  }

  renderButtons() {
    const { editing, specsComplete, addingSpecState } = this.state;
    const { dataOfSpecEditing } = this.props;

    let title = "";
    if (!specsComplete) {
      title = "Must fill out all of the specs";
    }

    if (addingSpecState && dataOfSpecEditing.length) {
      return (
        <>
          <div />
          <StyledTooltip
            title={title}
            placement="top"
          >
            <button
              type="button"
              className={specsComplete ? "confirmButton EnvEditSpecGeneralButton" : "envSpecManagerConfirmButtonDisabled EnvEditSpecGeneralButton"}
              onClick={specsComplete ? this.handleSaveSpec : null}
            >
              Add
            </button>
          </StyledTooltip>
        </>
      );
    }

    if (!editing && dataOfSpecEditing.length) {
      return (
        <>
          <div />
          <button
            type="button"
            className="envSpecManagerSecondaryButton"
            onClick={this.handleEditButtonClicked}
          >
            Edit
          </button>
        </>
      );
    }
    if (dataOfSpecEditing.length) {
      return (
        <>
          <div />
          <StyledTooltip
            title={title}
            placement="top"
          >
            <button
              type="button"
              className={specsComplete ? "confirmButton EnvEditSpecGeneralButton" : "envSpecManagerConfirmButtonDisabled EnvEditSpecGeneralButton"}
              onClick={specsComplete ? this.handleSaveSpec : null}
            >
              Update
            </button>
          </StyledTooltip>
        </>
      );
    }

    return null;
  }

  renderTable() {
    const { editing, addingSpecState } = this.state;
    const {
      addingSpec, dataOfSpecEditing, loading,
    } = this.props;
    if (!loading) {
      if (!dataOfSpecEditing.length) {
        return (
          <div className="EnvEditSpec__NoTestsFound">
            <img
              alt="no result"
              src={pumpkin}
              className="EnvEditSpec__NoTestsFound__Img"
            />
            <div className="EnvEditSpec__NoTestsFound__Img__Content">
              No Tests Found
            </div>
          </div>
        );
      }

      if (editing || addingSpecState) {
        return (
          <EditSpecTable
            addingSpec={addingSpec}
            dataOfSpecEditing={dataOfSpecEditing}
            handleDataChange={this.callProperUpdateDataFunction}
          />
        );
      }

      return (
        <SpecTable
          addingSpec={addingSpec}
          dataOfSpecEditing={dataOfSpecEditing}
          handleDataChange={this.callProperUpdateDataFunction}
        />
      );
    }
    return null;
  }

  render() {
    const {
      fromModal, identifier,
    } = this.props;

    const canAddSpec = hasPermission("add_spec");

    return (
      <div className="EnvEditSpecContainer">
        <div className="EnvEditSpecContainer__Header" title={identifier}>
          {!fromModal && (
          <img
            src={backIcon}
            alt="back icon"
            className="EnvEditSpecContainer__Header__Img"
            onClick={this.handleExitModal}
          />
          )}
          <span className="EnvEditSpecContainer__Header__Span">{identifier}</span>
        </div>
        <div className="EnvEditSpecContainer__MainSection">
          {this.renderTable()}
          {this.handleConfirmationPopup()}
        </div>
        <div className="EnvEditSpecContainer__ButtonsSection">
          { canAddSpec ? this.renderButtons() : null}
        </div>

      </div>
    );
  }
}

EditSpec.contextType = AppContext; // allows class component to consume context, access via this.context
export default EditSpec;
