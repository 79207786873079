import React from "react";

import {
  Document, Page, View, StyleSheet, Font, Text, Image,
} from "@react-pdf/renderer";

import RobotoMedium from "../../../../assets/fonts/roboto/Roboto-Medium.ttf";
import Roboto from "../../../../assets/fonts/roboto/Roboto-Regular.ttf";

export default function LabelsPDF({
  getFileName,
  scheduleData,
}) {
  Font.register({ family: "Roboto-400", src: Roboto });
  Font.register({ family: "Roboto-500", src: RobotoMedium });

  const styles = StyleSheet.create({
    page: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: "#FFFFFF",
      padding: "36 36 18 36",
      color: "#000000",
      fontFamily: "Roboto-500",
    },
    section: {
      display: "flex",
      flexDirection: "column",
      width: "3in",
    },
    labelContainer: {
      padding: 8,
      marginBottom: 10,
      border: "1 dashed #C4D2DF",
      justifyContent: "center",
      alignItems: "center",
      aspectRatio: 3 / 2,
    },
    content: {
      width: "100%",
      height: "96px",
      flexDirection: "row",
    },
    qrContainer: {
      flexShrink: 0,
      height: "100%", // 1 inch = 96px
      aspectRatio: 1,
      marginRight: 8,
    },
    textContainer: {
      flex: 1,
      alignSelf: "flex-start",
    },
    dataText: {
      fontFamily: "Roboto-400",
      fontSize: "10px",
      fontWeight: "400",
      color: "#182534",
      lineHeight: 1.3,
    },
  });

  const softHyphenate = (text) => (text.split("").join("\u{00AD}"));

  const renderQR = (event_id) => {
    const qrCodeSrc = document.getElementById(`GeneratedQR__${event_id}`).querySelector("canvas").toDataURL();
    return <Image src={qrCodeSrc} />;
  };

  return (
    <Document title={getFileName()}>
      <Page size="LETTER" style={styles.page}>
        <View style={styles.section}>
          {scheduleData.map((sub, i) => {
            const key = `${sub.event_id}-${sub.event_date}-${i}`;
            return (
              <View style={styles.labelContainer} key={key} wrap={false}>
                <View style={styles.content}>
                  <View style={styles.qrContainer}>
                    {renderQR(sub.event_id)}
                  </View>
                  <View style={styles.textContainer}>
                    <Text style={styles.dataText}>
                      {softHyphenate(sub.submission_name)}
                    </Text>
                    <Text style={styles.dataText}>
                      {softHyphenate(sub.event_date)}
                    </Text>
                  </View>
                </View>
              </View>
            );
          })}
        </View>
      </Page>
    </Document>
  );
}
