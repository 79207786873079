import React, { useContext, useEffect, useState } from "react";

import { Drawer } from "antd";
import Scrollbar from "react-scrollbars-custom";
import { toast } from "react-toastify";

import useAbortController from "../../../Common/hooks/useAbortController";
import SampleSubmissionContext from "../../SampleSubmissionContext";
import SampleSubmissionConfirmModal from "../../SampleSubmissionModal/SampleSubmissionConfirmModal";
import DraftSubmissionCard from "./DraftSubmissionCard";

import { deleteFileFromS3WithPresigned } from "../../../../utils/helpers";

import {
  getDraftSubmissionsInfo,
  deleteDraft,
} from "../../../../actions/sampleSubmission";

import { ReactComponent as ChevronDown } from "../../../../assets/images/common/ChevronDown.svg";

import "../../SampleSubmission.css";
import "../MainPage.css";
import "./Drafts.css";

export default function Drafts({
  setShowSubmissionForm,
  setDraftEditing,
  loadingDrafts,
  setLoadingDrafts,
  draftSubmissions,
  setDraftSubmissions,
  isDraftDrawerOpen,
  closeDraftDrawer,
}) {
  const { sampleCategory } = useContext(SampleSubmissionContext);

  const [draftToDelete, setDraftToDelete] = useState(-1);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [loadingFields, setLoadingFields] = useState(true);
  const { setNewAbortController, clearAbortController } = useAbortController();

  /** Get list of draft submissions
  * @param {String} submissionId - the unique id of a submission
  */
  const apiGetDraftSubmissionsInfo = async (submissionId = "") => {
    setLoadingDrafts(true);
    const {
      success: draftSuccess, message: draftMessage,
      result: draft_submissions,
    } = await getDraftSubmissionsInfo(submissionId);

    if (draftSuccess) {
      setDraftSubmissions(draft_submissions);
    } else {
      toast.error(draftMessage);
    }
    setLoadingDrafts(false);
  };

  /** on component mount - get submitted submissions and submission drafts lists */
  useEffect(() => {
    apiGetDraftSubmissionsInfo();
    setLoadingFields(false);
  }, []); // eslint-disable-line

  /**
   * Delete draft
   * @param  {Number} idx index of draft being deleted in draftSubmissions list
   */
  const handleConfirmDeleteDraft = async (idx) => {
    /** If env, get draft's samples so we can loop over them and delete images */
    let samples;
    setDeleteLoading(true);
    if (sampleCategory === "environment") {
      const { success, result } = await getDraftSubmissionsInfo(draftSubmissions[idx].submission_id);
      if (success) {
        samples = result.samples;
      }
    }
    const { success, message } = await deleteDraft(draftSubmissions[idx].submission_id, draftSubmissions[idx].sample_category);
    if (success) {
      toast.success("Successfully deleted draft");
      draftSubmissions.splice(idx, 1);
      setDraftSubmissions([...draftSubmissions]);
      setDraftToDelete(-1);
      /** If a sample has an image_path, delete image from aws */
      if (samples && samples.length > 0) {
        samples.forEach(({ image_path }) => {
          if (image_path) {
            deleteFileFromS3WithPresigned([image_path], "private");
          }
        });
      }
    } else {
      toast.error(message);
    }
    setDeleteLoading(false);
  };

  /**
   * Handle delete draft click
   * @param  {Number} idx index of draft being deleted in draftSubmissions list
   */
  const handleDeleteDraftClick = async (idx) => {
    setDraftToDelete(idx);
  };

  const drawerStyles = {
    header: {
      display: "none",
    },
    body: {
      padding: 0,
    },
    content: {
      marginTop: 60,
      height: "calc(100vh - 86px)",
      borderBottomLeftRadius: "8px",
    },
    wrapper: {
      boxShadow: "none",
    },
  };

  return (
    <Drawer
      open={isDraftDrawerOpen}
      onClose={closeDraftDrawer}
      styles={drawerStyles}
    >
      {(draftToDelete >= 0) // delete draft confirmation modal
        && (
          <SampleSubmissionConfirmModal
            headerText="Delete Draft Confirmation"
            bodyText="Are you sure you want to delete this draft? This cannot be undone."
            buttonText={["Cancel", "Delete", "Deleting"]}
            buttonFunctions={[() => { setDraftToDelete(-1); }, () => handleConfirmDeleteDraft(draftToDelete)]}
            loading={deleteLoading}
          />
        )}
      <div className="draft-submission-container">
        <div className="draft-submission-header">
          <button
            className="Draft_DrawerToggle"
            type="button"
            onClick={closeDraftDrawer}
          >
            <ChevronDown className="Draft_DrawerToggleIcon" />
          </button>
          <h2>Drafts</h2>
        </div>
        <div className="draft-submission-background">
          {!(!loadingFields && !loadingDrafts && draftSubmissions.length === 0) && (
            <Scrollbar>
              <ul className="draft-submissions-ul">
                {!(loadingFields || loadingDrafts) && draftSubmissions.map((submission, idx) => (
                  <DraftSubmissionCard
                    key={idx}
                    index={idx}
                    submission={submission}
                    setDraftEditing={setDraftEditing}
                    setShowSubmissionForm={setShowSubmissionForm}
                    handleDeleteDraft={handleDeleteDraftClick}
                    setNewAbortController={setNewAbortController}
                    clearAbortController={clearAbortController}
                  />
                ))}
                {(loadingFields || loadingDrafts) && (<div className="submitted-submissions-table-skeleton--draft ActiveloadingAnimation" />)}
              </ul>
            </Scrollbar>
          )}
          {!loadingFields && !loadingDrafts && draftSubmissions.length === 0 && (<div className="submitted-submissions-no-results--draft">No drafts data</div>)}
        </div>
      </div>
    </Drawer>
  );
}
