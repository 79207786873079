import React, { useState, useEffect } from "react";

import Scrollbar from "react-scrollbars-custom";

import {
  showScrollbarStyle,
  hideScrollbarStyle,
} from "../../../Common/AutoHideScrollbarStyles";
import "./SampleCard.css";
import { SPEC_FLAGS_MAP } from "../../../Common/constants/SpecFlagConstants";
import checkStatusEditable from "../helper";
import ActionsMenu from "./ActionsMenu";

import { getFileFromS3WithPresigned } from "../../../../utils/helpers";

export default function SampleCard(props) {
  const {
    sample,
    sampleFields,
    sampleFieldsInfo,
    handleEditSample,
    handleDeleteSample,
    submissionStatus,
    sampleCategory,
    handleViewReportData,
    submissionEventId,
  } = props;
  const [showTestView, setShowTestView] = useState(false);
  const [scrollbarInUse, setScrollbarInUse] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [imageSrc, setImageSrc] = useState("");

  const specs_status = [0, "0", "-1"];

  /** Toggle test view.  */
  const handleClickTestViewSwitch = () => {
    setShowTestView(!showTestView);
  };

  const disableActionsForSubmission = !checkStatusEditable(submissionStatus, null, submissionEventId);
  const disableActionsForSample = !checkStatusEditable(submissionStatus, sample.specs_flag);

  /**
   * Load image from AWS
   * @param {String} image_path path to image file in aws
   */
  const loadImageFromAWS = async (image_path) => {
    setLoadingImage(true);
    if (image_path) {
      const fileBlobObj = await getFileFromS3WithPresigned([image_path], "private");
      const imageBlob = fileBlobObj[image_path];
      if (imageBlob) {
        setImageSrc(window.URL.createObjectURL(imageBlob));
      }
    }
    setLoadingImage(false);
  };

  useEffect(() => {
    if (sample.image_path) {
      loadImageFromAWS(sample.image_path);
    }
  }, []); // eslint-disable-line
  return (
    <div
      className={`sample-card sample-card-modal sample-card-details-modal sample-card-details-sampleid-container ${specs_status.includes(submissionStatus) ? "sample-card-details-sampleid-container-disabled" : ""}`}
      style={{ paddingRight: !disableActionsForSubmission ? "16px" : "10px" }}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        if (!specs_status.includes(submissionStatus)) {
          handleViewReportData(sample.sample_id);
        }
      }}
    >
      {loadingImage && <div className="SampleSubmission__SampleCardLoading" />}
      {!disableActionsForSubmission && (
        <div className="SampleSubmission__SampleCard__ActionsMenu">
          <ActionsMenu
            handleEdit={handleEditSample}
            handleDelete={handleDeleteSample}
            disableDelete={parseInt(submissionStatus) === 0}
            disabled={disableActionsForSample}
            disabledTooltipText="Cannot modify sample at this stage"
            sampleCategory={sampleCategory}
          />
        </div>
      )}
      {showTestView ? (
        <Scrollbar
          trackYProps={scrollbarInUse ? showScrollbarStyle : hideScrollbarStyle}
          onMouseEnter={() => setScrollbarInUse(true)}
          onMouseLeave={() => setScrollbarInUse(false)}
        >
          <div className="sample-card-details sample-test-ul-container">
            <ul className="sample-test-ul">
              {sample.tests_list.map((test, idx) => (
                <li key={idx}>{test.Test}</li>
              ))}
            </ul>
          </div>
        </Scrollbar>
      ) : (
        <>
          {(sample.num_composite_samples > 0) && (
            <div
              className="sample-card-composite-header"
              style={{
                width: !disableActionsForSubmission ? "calc(100% + 28px)" : "calc(100% + 22px)",
              }}
            >
              <div>Composite Sample</div>
              <div>{`${sample.num_composite_samples} Sub Samples`}</div>
            </div>
          )}
          <Scrollbar
            trackYProps={scrollbarInUse ? showScrollbarStyle : hideScrollbarStyle}
            onMouseEnter={() => setScrollbarInUse(true)}
            onMouseLeave={() => setScrollbarInUse(false)}
          >
            <div
              className="sample-card-details"
            >
              <table>
                <tbody>
                  <tr>
                    <th>Sample ID</th>
                    <td>
                      {sample.sample_id}
                    </td>
                  </tr>
                  {sampleFields.map((json_field, i) => {
                    if (
                      sample[json_field] !== ""
                      && sample[json_field] !== undefined
                      && json_field !== "sample_id"
                    ) {
                      return (
                        <tr key={`${json_field}${i}`}>
                          <th>{sampleFieldsInfo[json_field].title_field}</th>
                          <td>{sample[json_field]}</td>
                        </tr>
                      );
                    }
                    return null;
                  })}
                  {imageSrc !== "" && (
                    <tr key="image">
                      <th>Image</th>
                      <td>
                        <div className="SampleSubmission__SampleCardImageDiv">
                          <img
                            src={imageSrc}
                            alt="sample"
                            className="SampleSubmission__SampleCardImage"
                          />
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Scrollbar>
        </>
      )}

      <div className="sample-card-status-test-view-cointainer">
        <div className="SampleCard__ActionsStatusContainer">
          <div className="sample-card-status">
            {SPEC_FLAGS_MAP[sample.specs_flag] && (
              <>
                <img src={SPEC_FLAGS_MAP[sample.specs_flag].icon} alt="" />
                <span style={{ color: SPEC_FLAGS_MAP[sample.specs_flag].color }}>
                  {SPEC_FLAGS_MAP[sample.specs_flag].label}
                </span>
              </>
            )}
          </div>
        </div>

        <div className="sample-card-test-view-choice">
          {/* <span>Test View</span>
          {showTestView ? (
            <button
              className="to-close-test-view"
              type="button"
              onClick={handleClickTestViewSwitch}
            >
              <span className="sample-card-white-dot" />
            </button>
          ) : (
            <button
              className="to-open-test-view"
              type="button"
              onClick={handleClickTestViewSwitch}
            >
              <span className="sample-card-white-dot" />
            </button>
          )} */}
          <div
            className="added-sample-tests-number"
            onClick={(e) => {
              e.stopPropagation();
              handleClickTestViewSwitch();
            }}
          >
            <div>
              {showTestView
                ? "Sample Details"
                : `${sample?.tests_list?.length} Tests added`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
