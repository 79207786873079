import React, {
  useCallback, useEffect, useRef, useState,
} from "react";

import { LoadingOutlined } from "@ant-design/icons";
import { pdf } from "@react-pdf/renderer";
import { QRCode } from "antd";
import { isEmpty } from "lodash";
import moment from "moment";
// import printJS from "print-js";
import { toast } from "react-toastify";

import NoResults from "../../../Common/NoResults";
import PdfPreview from "../../../Common/PdfPreview";
import LabelsPDF from "./LabelsPDF";
import ReportModal from "./ReportModal";
import ZebraPrintModal from "./ZebraPrintModal";

import { hasFeature } from "../../../../utils/common";
import { getFormattedDaysAgoString } from "../../../Common/DatePicker/utils";

import { getEnvSchedule } from "../../../../actions/envCalendar";

import emptyStateIcon from "../../../../assets/images/EmptyState_V2.png";

import "./PrintLabelModal.css";

export default function PrintLabelModal({
  eventData, onClose, defaultDateRange, allowClear = true,
}) {
  const [blobURL, setBlobURL] = useState(null);
  const [generatingPDF, setGeneratingPDF] = useState(false);
  const [scheduleData, setScheduleData] = useState([]);
  const [markedDates, setMarkedDates] = useState([]);
  const [isPrintLabelOpen, setIsPrintLabelOpen] = useState(false);

  const controller = useRef();

  /**
   * Calls envschedule/ api
   * @param {Date} start start date
   * @param {Date} end end date
   */
  const apiGetEnvSchedule = async (start, end) => {
    const abortController = new AbortController();
    if (controller.current) {
      controller.current.abort();
    }
    controller.current = abortController;
    const result = await getEnvSchedule(start, end, "", controller.current.signal);

    if (controller.current.signal.aborted) {
      setScheduleData([]);
      setMarkedDates([]);
    }

    if (result && result.success) {
      const data = result.result;
      const sampleWiseData = [];
      (data.scheduled_submission ?? []).forEach((submission) => {
        const sample_data = submission.samples_list.map((sample) => ({
          event_id: submission.event_id,
          submission_name: sample.sample_id,
          event_date: moment(submission.event_date).format("MMM D, YYYY"),
        }));
        sampleWiseData.push(...sample_data);
      });
      setScheduleData(sampleWiseData);
      setMarkedDates(data.scheduled_submission_dates);
    }

    controller.current = null;
  };

  useEffect(() => {
    if (eventData) {
      const sample_data = eventData.samples_list.map((sample) => ({
        event_id: eventData.event_id,
        submission_name: sample.sample_id,
        event_date: moment(eventData.event_date).format("MMM D, YYYY"),
      }));
      setScheduleData(sample_data);
    } else {
      setGeneratingPDF(true);
      const { dateFromString, dateToString } = defaultDateRange;
      apiGetEnvSchedule(dateFromString, dateToString).finally(() => setGeneratingPDF(false));
    }
  }, [eventData]); // eslint-disable-line

  /**
   * Generates filename
   * @returns Filename
   */
  const getFileName = () => {
    const timeStamp = moment().unix();
    return `Print_Label_${timeStamp}.pdf`;
  };

  /**
   * Generate pdf blob
   */
  const generatePDFBlob = useCallback(async () => {
    try {
      setGeneratingPDF(true);
      if (scheduleData.length === 0) {
        return null;
      }
      const pdfBlob = await pdf(LabelsPDF({
        getFileName,
        scheduleData,
      })).toBlob();
      setGeneratingPDF(false);
      return pdfBlob;
    } catch (e) {
      setGeneratingPDF(false);
      toast.error("Failed to generate pdf");
      return null;
    }
  }, [scheduleData]);

  /**
   * Get generated pdf and displays
   */
  const getGeneratedPDFBlob = useCallback(async () => {
    const pdfBlob = await generatePDFBlob();
    if (pdfBlob) {
      const url = URL.createObjectURL(pdfBlob);
      setBlobURL(url);
    } else {
      setBlobURL(null);
    }
  }, [generatePDFBlob]);

  const handlePrint = () => {
    // if (blobURL) {
    //   printJS(blobURL);
    // }
    setIsPrintLabelOpen(true);
  };

  /**
   * Handle download excel file
   */
  const handleDownload = async () => {
    const pdfBlob = await generatePDFBlob();
    if (pdfBlob) {
      const url = window.URL.createObjectURL(pdfBlob);
      if (url) {
        const a = document.createElement("a");
        a.href = url;
        a.download = getFileName();
        a.click();
      }
    }
  };

  /**
   * handle date picker change event
   * @param {Date} startDate Start date
   * @param {Date} endDate End date
   */
  const handleDatePickerChange = async (startDate, endDate) => {
    if (eventData) return; // If print modal is opened from the event card i.e. having only one event data

    const start = startDate ? moment(startDate).format("YYYY-MM-DD") : "";
    const end = endDate ? moment(endDate).format("YYYY-MM-DD") : "";
    setGeneratingPDF(true);
    await apiGetEnvSchedule(start, end);
    setGeneratingPDF(false);
  };

  useEffect(() => {
    getGeneratedPDFBlob();
  }, [getGeneratedPDFBlob]);

  const qrContainerStyles = { display: "none" };
  return (
    <>
      <ReportModal
        title="Export Scheduling Plan"
        onClose={onClose}
        onPrint={handlePrint}
        onDownload={handleDownload}
        onDatePickerChange={handleDatePickerChange}
        disablePrintButton={generatingPDF || isEmpty(scheduleData)}
        disableDownloadButton={generatingPDF || isEmpty(scheduleData)}
        markedDates={markedDates}
        defaultDateRange={getFormattedDaysAgoString(defaultDateRange.dateFromObj, defaultDateRange.dateToObj)}
        showDatePicker={!eventData}
        showDownloadButton={false}
        allowClear={allowClear}
        showPrint={hasFeature("printer")}
      >
        {blobURL ? (
          <PdfPreview
            blob={blobURL}
            height="70vh"
            scaleType="PageWidth"
            scrollBarBorderRadius="8px"
            showLoading={generatingPDF}
          />
        ) : (
          <div className="PrintLabelModal__NoResults">
            {generatingPDF || !isEmpty(scheduleData) ? <LoadingOutlined spin className="PrintLabelModal__LoadingIcon" /> : <NoResults image={emptyStateIcon} message="Preview not available." />}
          </div>
        )}
        <div style={qrContainerStyles}>
          {scheduleData.map((data, i) => {
            const key = `${data.event_id}-${data.event_date}-${i}`;
            return (
              <div id={`GeneratedQR__${data.event_id}`} key={key}>
                <QRCode
                  status={data.event_id === "" ? "loading" : "active"}
                  value={data.event_id}
                  type="canvas"
                  size={180}
                  bordered={false}
                />
              </div>
            );
          })}
        </div>
      </ReportModal>
      {isPrintLabelOpen && (
        <ZebraPrintModal
          scheduleData={scheduleData}
          totalItems={scheduleData?.length}
          onClose={() => setIsPrintLabelOpen(false)}
        />
      )}
    </>
  );
}
