import React from "react";

import Scrollbar from "react-scrollbars-custom";

import { AppContext } from "../../../../../AppContext";
import StyledButton from "../../../../Common/UIComponents/StyledButton";
import StyledTooltip from "../../../../Common/UIComponents/StyledTooltip";
import ConfirmModal from "./ConfirmModal";
import EditSpecTable from "./EditSpecTable";
import { checkIfSpecsInvalid } from "./Helper";
import SpecTable from "./SpecTable";
import UpdateTestList from "./UpdateTestList";

import { hasPermission } from "../../../../../utils/common";

import watermelon from "../../../../../assets/images/EmptyState_V2.png";
import backIcon from "../../../../../assets/images/product/backIconDarkBlue.png";

import "../../../Product.css";
import "./SpecManager.css";
import "./EditSpec.css";

class EditSpec extends React.Component {
  constructor(props) {
    super(props);
    const { addingSpec, editingSpec } = this.props;

    this.state = {
      saveConfirmation: false,
      editing: editingSpec || false,
      // specsComplete: false,
      disableButton: false,
      addingSpecState: addingSpec,
      updateConfirmationLoading: false,
      canAddSpec: hasPermission("add_spec"),
    };

    this.handleExitModal = this.handleExitModal.bind(this);
    this.handleEditButtonClicked = this.handleEditButtonClicked.bind(this);
  }

  componentDidMount() {
    const {
      addingSpec,
    } = this.props;

    if (addingSpec) {
      this.setState({
        editing: true,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { dataOfSpecEditing } = this.props;

    if (prevProps.dataOfSpecEditing !== dataOfSpecEditing) {
      const response = checkIfSpecsInvalid(dataOfSpecEditing);
      this.setState({
        disableButton: response,
      });
    }

    return true;
  }

  componentWillUnmount() {
    const { setShowUpdateTestList } = this.props;
    setShowUpdateTestList(false);
  }

  handleClearSpec = (e) => {
    const { dataOfSpecEditing, handleDataChange } = this.props;
    e.preventDefault();
    const specList = dataOfSpecEditing;
    const specListCleared = specList.map((spec) => ({
      test: spec.test,
      test_type: "",
      specs: [],
    }));
    handleDataChange(specListCleared);
    // this.props.handleSaveSpec(true);
  }

  handleSaveSpec = async (e) => {
    const {
      handleSaveSpec, handleSpecChanged, fromModal, handleClosingEditSpec,
    } = this.props;

    this.saveConfirmation(true);

    e.preventDefault();
    if (this.state.saveConfirmation) { // eslint-disable-line
      this.setState({ updateConfirmationLoading: true });
      const response = await handleSaveSpec();

      if (response === true) {
        if (fromModal) {
          handleClosingEditSpec();
        } else {
          this.setState({
            editing: false,
            addingSpecState: false,
          });

          handleSpecChanged(false);
        }
      }
      this.saveConfirmation(false);
      this.setState({ updateConfirmationLoading: false });
    }
  }

  handleConfirmationPopup() {
    const { saveConfirmation, addingSpecState, updateConfirmationLoading } = this.state;

    if (saveConfirmation && addingSpecState) {
      return (
        <ConfirmModal
          headerText="Add Specs to Product?"
          bodyText="Adding specs will update the status of all samples linked to this product. This cannot be undone."
          buttonText={["Cancel", "Add"]}
          buttonFunctions={[() => { this.setState({ saveConfirmation: false }); }, this.handleSaveSpec]}
          disableConfirmButton={updateConfirmationLoading}
          loadingConfirm={updateConfirmationLoading}
        />
      );
    }

    if (saveConfirmation && !addingSpecState) {
      return (
        <ConfirmModal
          headerText="Update Specs to Product?"
          bodyText="Adding specs will update the status of all samples linked to this product. This cannot be undone."
          buttonText={["Cancel", "Update"]}
          buttonFunctions={[() => { this.setState({ saveConfirmation: false }); }, this.handleSaveSpec]}
          disableConfirmButton={updateConfirmationLoading}
          loadingConfirm={updateConfirmationLoading}
        />
      );
    }

    return <></>;
  }

  handleExitModal() {
    const {
      confirmExitModalDisplayToggle, handleExitEdit, specChanged, valueHasBeenEdited,
    } = this.props;
    const { editing } = this.state;

    if (editing && specChanged) {
      const response = confirmExitModalDisplayToggle();
      this.setState({
        editing: response,
      });
    } else {
      handleExitEdit(false, valueHasBeenEdited);
    }
  }

  handleEditButtonClicked() {
    const { fromModal, dataOfSpecEditing } = this.props;
    this.setState({
      editing: true,
    });

    if (!fromModal) {
      if (dataOfSpecEditing.length !== 0) {
        const response = checkIfSpecsInvalid(dataOfSpecEditing);
        this.setState({
          disableButton: response,
        });
      } else {
        this.setState({
          disableButton: false,
        });
      }
    }
  }

  /**
   * Handler for back button click
   */
  handleBackButtonClick = () => {
    const { showUpdateTestList, setShowUpdateTestList } = this.props;
    if (showUpdateTestList) {
      setShowUpdateTestList(false);
    } else {
      this.handleExitModal();
    }
  }

  callProperUpdateDataFunction = (specList) => {
    const { handleDataChange, handleSpecChanged } = this.props;

    handleDataChange(specList);
    handleSpecChanged(true);

    const response = checkIfSpecsInvalid(specList);
    this.setState({
      disableButton: response,
    });
  }

  saveConfirmation(value) {
    this.setState({
      saveConfirmation: value,
    });

    return value;
  }

  renderButtons() {
    const {
      editing,
      // specsComplete,
      disableButton,
      addingSpecState,
    } = this.state;
    const { dataOfSpecEditing, setShowUpdateTestList } = this.props;

    let title = "";
    if (disableButton) {
      title = "Missing or invalid inputs";
    }

    if (addingSpecState && dataOfSpecEditing.length) {
      return (
        <>
          <div />
          <div className="EditSpec__Button_Container">
            <StyledButton
              type="primary"
              ghost
              // className="EditSpec__Button__Outline"
              onClick={() => setShowUpdateTestList(true)}
            >
              Update Test List
            </StyledButton>
            <StyledTooltip
              title={title}
              placement="top"
            >
              <StyledButton
                type="primary"
                // className={!disableButton ? "SpecManager__ConfirmButton" : "SpecManager__ConfirmButton--disabled"}
                onClick={!disableButton ? this.handleSaveSpec : null}
              >
                Add Specs
              </StyledButton>
            </StyledTooltip>
          </div>
        </>
      );
    }

    if (!editing && dataOfSpecEditing.length) {
      return (
        <>
          <div />
          <StyledButton
            type="primary"
            ghost
            // className="SpecManager__SecondaryButton"
            onClick={this.handleEditButtonClicked}
          >
            Edit
          </StyledButton>
        </>
      );
    }
    if (dataOfSpecEditing.length) {
      return (
        <>
          <div />
          <div className="EditSpec__Button_Container">
            <StyledButton
              type="primary"
              ghost
              // className="EditSpec__Button__Outline"
              onClick={() => setShowUpdateTestList(true)}
            >
              Update Test List
            </StyledButton>
            <StyledTooltip
              title={title}
              placement="top"
            >
              <StyledButton
                type="primary"
                // className={!disableButton ? "SpecManager__ConfirmButton" : "SpecManager__ConfirmButton--disabled"}
                onClick={!disableButton ? this.handleSaveSpec : null}
              >
                Update Specs
              </StyledButton>
            </StyledTooltip>
          </div>
        </>
      );
    }
    return null;
  }

  renderTable() {
    const { editing, addingSpecState, canAddSpec } = this.state;
    const {
      addingSpec, dataOfSpecEditing, loading,
    } = this.props;

    if (!loading) {
      if (!dataOfSpecEditing.length) {
        return (
          <div className="productReportEditSpecNoResult">
            <img
              className="productReportEditSpecNoResultWatermelonImg"
              alt="no result"
              src={watermelon}
            />
            <div className="productReportEditSpecNoResultErrorMessage">
              No Tests Found
            </div>
          </div>
        );
      }
      if (canAddSpec && (editing || addingSpecState)) {
        return (
          <EditSpecTable
            addingSpec={addingSpec}
            dataOfSpecEditing={dataOfSpecEditing}
            handleDataChange={this.callProperUpdateDataFunction}
          />
        );
      }

      return (
        <SpecTable
          addingSpec={addingSpec}
          dataOfSpecEditing={dataOfSpecEditing}
          handleDataChange={this.callProperUpdateDataFunction}
        />
      );
    }
    return null;
  }

  renderProductTitle() {
    const { productIdentifierArr } = this.props;
    let title = "";
    Object.entries(productIdentifierArr).forEach(
      ([key, value], index) => {
        if (value !== "") {
          title += `${index > 0 ? " \u2022 " : ""}${key}: ${value}`;
        }
      },
    );
    return title;
  }

  render() {
    const {
      fromModal, productIdentifierArr, showUpdateTestList, setShowUpdateTestList,
      dataOfSpecEditing, handleDataChange, handleSaveTestList,
    } = this.props;
    const { canAddSpec } = this.state;
    return (
      <div className="productReportEditSpecContainer">
        <div className={`productReportEditSpecTitleDiv ${showUpdateTestList ? "productReportEditSpecTitleDiv__UpdateTestList" : ""}`}>
          {!fromModal && (
            <img
              className="productReportEditSpecBackIcon"
              src={backIcon}
              alt="back icon"
              onClick={this.handleBackButtonClick}
            />
          )}
          <Scrollbar className="EditSpec__LinkedFieldScrollBar">
            <span className="LinkedFieldsSpan productReportEditSpecTitle" title={this.renderProductTitle()}>
              {Object.entries(productIdentifierArr)
                .filter(([, value]) => value !== "")
                .map(([key, value], index, array) => (
                  <React.Fragment key={key}>
                    <span className="LinkedFieldTitle">{`${key}: `}</span>
                    <span className="LinkedFieldValue">{value}</span>
                    {index < array.length - 1 && <span className="LinkedFieldsDelimiterDot" />}
                  </React.Fragment>
                ))}
            </span>
          </Scrollbar>
        </div>
        {!showUpdateTestList ? (
          <>
            <div className="productReportEditSpecTableContainer">
              {this.renderTable()}
              {this.handleConfirmationPopup()}
            </div>
            <div
              id="productReportEditSpecButtons"
              className="productReportEditSpecButtonsSection"
            >
              {canAddSpec ? this.renderButtons() : null}
            </div>
          </>
        ) : (
          <UpdateTestList
            setShowUpdateTestList={setShowUpdateTestList}
            dataOfSpecEditing={dataOfSpecEditing}
            handleDataChange={handleDataChange}
            handleSaveTestList={handleSaveTestList}
          />
        )}
      </div>
    );
  }
}

EditSpec.contextType = AppContext; // allows class component to consume context, access via this.context
export default EditSpec;
