/* Built on react-flatpickr v3.10.6 (uses flatpickr v4.6.2) */
import React, { useEffect, useRef, useState } from "react";

import moment from "moment";
import Flatpickr from "react-flatpickr";

import StyledButton from "../UIComponents/StyledButton";
import MonthYearSelect from "./MonthYearSelect";

import { convertUtcToLocal } from "../utils/dateUtils";
import { getSelectedDates } from "./utils";

import { ReactComponent as CloseIcon } from "../../../assets/images/common/CloseIconFilled.svg";

import "flatpickr/dist/themes/material_green.css";
import "../../Product/Product.css";
import "./Calendar.css";
// Calculate the start and end years to be shown in the dropdown menu
// Also used to limit the min/max values a user can navigate to

const yearStart = 11;
const yearEnd = 0;
const currDate = moment(); // new Date();

const currYear = moment().year(); // new Date().getFullYear();

// const start = new Date().getFullYear() - yearStart;
const start = moment().subtract(yearStart, "years").year();
const end = currYear + yearEnd;

export default function Calendar({
  setShowCalendarInternal,
  handleSearchDate,
  handleCancel,
  setPrevDateSelected,
  getReportDates,
  dateSelected,
  setDateSelected,
  searchOnClear,
  calendarClassName,
  disableSingleDaySelection,
  useLocalTime,
  allowFutureDateSelect = false,
  allowClear = true,
}) {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [flatpickrSelectedDates, setFlatpickrSelectedDates] = useState();
  const [currentSelectedMonth, setCurrentSelectedMonth] = useState(moment(currDate).month());
  const [currentSelectedYear, setCurrentSelectedYear] = useState(moment(currDate).year());
  const [reportDates, setReportDates] = useState(new Set());
  const [datesReceived, setDatesReceived] = useState(false);

  const options = {
    plugins: [],
    inline: true,
    allowInput: true,
    dateFormat: "m.d.Y",
    mode: "range",
    minDate: moment().year(start).startOf("year").format("MM/DD/YYYY"),
    maxDate: allowFutureDateSelect ? undefined : moment().year(end).endOf("year").format("MM/DD/YYYY"),
  };

  const flatpickrRef = useRef();

  const apiGetReportDates = async () => {
    const dates = await getReportDates();
    const datesSet = new Set();
    if (dates.length > 0) {
      dates.forEach((_date) => {
        let date = _date;
        if (useLocalTime) {
          date = convertUtcToLocal(_date);
        }
        const dateTemp = moment(date).format("LL");
        datesSet.add(dateTemp);
      });
    }
    setReportDates(datesSet);
    setDatesReceived(true);
  };

  useEffect(() => {
    apiGetReportDates();
    const dates = getSelectedDates(dateSelected, false); // rangeSelectionComplete is false so we know that an array of length one means a single day was picked and we don't have to do a start/end date comparison
    if (dates !== undefined) {
      if (dates.length === 2) {
        // Two dates
        const month = moment(dates[1]).month();
        const year = moment(dates[1]).year();
        setCurrentSelectedMonth(month);
        setCurrentSelectedYear(year);

        setStartDate(moment(dates[0]));
        setEndDate(moment(dates[1]));
      } else {
        const month = moment(dates[0]).month();
        const year = moment(dates[0]).year();
        setCurrentSelectedMonth(month);
        setCurrentSelectedYear(year);

        setStartDate(moment(dates[0]));
      }
    }
    setFlatpickrSelectedDates(getSelectedDates(dateSelected, true)); // rangeSelectionComplete is true so selection is complete on mount
  }, []); // eslint-disable-line

  const handleCancelInternal = (e) => {
    e.stopPropagation();

    setStartDate("");
    setEndDate("");

    handleCancel();
    setShowCalendarInternal(false);
  };

  const onEndDateChange = async (date) => {
    if (date.length < 1) {
      return;
    }

    if (date[1] === undefined || moment(date[0]).valueOf() === moment(date[1]).valueOf()) {
      setStartDate(moment(date[0]));
      setEndDate("");
      const newDateSelected = moment(date[0].getTime()).format("MMM D, YYYY");
      setDateSelected(newDateSelected);
      const rangeSelectionComplete = disableSingleDaySelection ? false : date.length === 2;
      setFlatpickrSelectedDates(getSelectedDates(newDateSelected, rangeSelectionComplete));
    } else {
      setStartDate(moment(date[0]));
      setEndDate(moment(date[1]));
      const dateSelectedFrom = moment(date[0].getTime()).format("MMM D, YYYY");
      const dateSelectedTo = moment(date[1].getTime()).format("MMM D, YYYY");
      const newDateSelected = `${dateSelectedFrom} - ${dateSelectedTo}`;
      setDateSelected(newDateSelected);
      setFlatpickrSelectedDates(getSelectedDates(newDateSelected, true));
    }
  };

  const handleClearDateClick = () => {
    setStartDate("");
    setEndDate("");
    setDateSelected("");
    setFlatpickrSelectedDates(getSelectedDates(""));
    if (searchOnClear) {
      setPrevDateSelected("");
      handleSearchDate("", "");
    }
    flatpickrRef.current.flatpickr.clear();
    setCurrentSelectedYear(currYear);
  };

  const handleApplySearchFilter = () => {
    if (startDate) {
      const formatedDateStart = moment(startDate)
        .format("YYYY/MM/DD")
        .split("/")
        .join("-");
      const formatedDateEnd = endDate === ""
        ? endDate
        : moment(endDate).format("YYYY/MM/DD").split("/").join("-");

      handleSearchDate(formatedDateStart, formatedDateEnd);
      setStartDate("");
      setEndDate("");
      setPrevDateSelected(dateSelected);
      setShowCalendarInternal(false);
    }
  };

  /**
   * Update selected month in MonthYearSelect onMonthChange
   * @param {Array<Date>} selectedDates array of Date objects sorted from least to most recent
   * @param {String} dateStr date (06.04.2023 format) or date range (06.04.2023 to 07.03.2023 format)
   * @param {Flatpickr} instance flatpickr instance
   */
  const onMonthChange = (selectedDates, dateStr, instance) => {
    const month = instance.currentMonth;
    // const months = [
    //   "January", "February", "March", "April", "May", "June",
    //   "July", "August", "September", "October", "November", "December",
    // ];
    // console.log("onMonthChange", months[month]);
    setCurrentSelectedMonth(parseInt(month));
  };

  /**
   * Update selected year in MonthYearSelect onYearChange
   * @param {Array<Date>} selectedDates array of Date objects sorted from least to most recent
   * @param {String} dateStr date (06.04.2023 format) or date range (06.04.2023 to 07.03.2023 format)
   * @param {Flatpickr} instance flatpickr instance
   */
  const onYearChange = (selectedDates, dateStr, instance) => {
    const year = instance.currentYear;
    // console.log("onYearChange", year);
    setCurrentSelectedYear(parseInt(year));
  };

  /**
   * Update selectedDate, startDate, endDate onChange. If the current month or year shown in flatpickr
   * differs from what is shown in the MonthYearSelect, update MonthYearSelect.
   * @param {Array<Date>} selectedDates array of Date objects sorted from least to most recent
   */
  const onChange = (selectedDates) => {
    // console.log("onChange", selectedDates);
    onEndDateChange(selectedDates);
    /* There is a bug where onMonthChange, onYearChange aren't triggered when user clicks
    on a date from previous or next month and month/year changes.
    So if currentSelectedMonth and currentSelectedYear don't match the flatpickr end date month/year,
    update their values (on change, flatpickr always goes to the month of the most recent date)  */
    const fpCurrEndDate = selectedDates[selectedDates.length - 1]; // get most recent date in selection
    const fpCurrMonth = moment(fpCurrEndDate).month();
    const fpCurrYear = moment(fpCurrEndDate).year();
    if (fpCurrMonth !== currentSelectedMonth) {
      setCurrentSelectedMonth(fpCurrMonth);
    }
    if (fpCurrYear !== currentSelectedYear) {
      setCurrentSelectedYear(fpCurrYear);
    }
  };

  const disableApplyButton = startDate === "" || (disableSingleDaySelection && endDate === "");

  return (
    <div className={`Calendar ${calendarClassName}`}>
      <div className="Calendar__Head">
        <div>Select Date</div>
        <CloseIcon
          type="button"
          title="close icon"
          onClick={handleCancelInternal}
          className="Calendar__CloseButton"
        />
      </div>
      <div className="Calendar__Body">
        <MonthYearSelect
          startYear={start}
          endYear={end}
          currentSelectedMonth={currentSelectedMonth}
          setCurrentSelectedMonth={setCurrentSelectedMonth}
          currentSelectedYear={currentSelectedYear}
          setCurrentSelectedYear={setCurrentSelectedYear}
          flatpickrRef={flatpickrRef}
        />
        <Flatpickr
          value={flatpickrSelectedDates}
          ref={flatpickrRef}
          options={{ ...options }}
          onChange={onChange}
          onDayCreate={(dObj, dStr, fp, dayElem) => {
            if (datesReceived) {
              if (
                reportDates.has(dayElem.getAttribute("aria-label"))
              ) {
                dayElem.innerHTML += "<span class='Calendar__MarkDay'></span>";
              }
            }
          }}
          onMonthChange={onMonthChange}
          onYearChange={onYearChange}
        />
        <div className="Calendar__Actions">
          <a // eslint-disable-line
            className={startDate === "" || !allowClear ? "Calendar__ClearLink--inactive" : "Calendar__ClearLink"}
            onClick={startDate === "" || !allowClear ? () => { } : (e) => handleClearDateClick(e)}
          >
            Clear
          </a>
          <StyledButton
            onClick={disableApplyButton ? null : (e) => handleApplySearchFilter(e)}
            disabled={disableApplyButton}
            type="primary"
          >
            Apply
          </StyledButton>
        </div>
      </div>
    </div>
  );
}
