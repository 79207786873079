import React, { useEffect, useRef, useState } from "react";

import { toast } from "react-toastify";

import UploadImageModal from "../../../../../Common/ImageEditModal/UploadImageModal";

import {
  getFileFromS3WithPresigned, deleteFileFromS3WithPresigned, uploadFileToS3WithPresigned, getFileNameAndExtensionFromPath, getFileNameWithTimestampRemoved,
  getFilePath,
} from "../../../../../../utils/helpers";

import { updateEnvSampleImage } from "../../../../../../actions/envAnalytics";

import { ReactComponent as EditIcon } from "../../../../../../assets/images/sample-submission/edit.svg";
import { ReactComponent as UploadImageIcon } from "../../../../../../assets/images/sample-submission/uploadImageIcon.svg";

import "./ReportViewImage.css";

export default function ReportViewImage({
  locationID,
  imagePath,
  curReportInfo,
  // setImagePath,
}) {
  const [showUploadImageModal, setShowUploadImageModal] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [displayImageSrc, setDisplayImageSrc] = useState("");
  const imageInfoRef = useRef({
    originalUrl: "",
    editedUrl: "",
    file: "",
  });
  const imageFileType = useState("");

  /**
   * Set image related info
   * @param  {Object} info { originalUrl, editedUrl, file, crop, rotate }
   */
  const setImageInfoRef = (info) => {
    // console.log("setImageInfoRef", info);
    imageInfoRef.current = { ...info };
  };

  const loadImageFromAWS = async (image_path) => {
    setLoadingImage(true);
    let imageUrl;
    if (image_path) {
      const fileBlobObj = await getFileFromS3WithPresigned([image_path], "private");
      const imageBlob = fileBlobObj ? fileBlobObj[image_path] : null;
      if (imageBlob) {
        imageUrl = window.URL.createObjectURL(imageBlob);
        imageFileType.current = imageBlob.type;
      }
      setDisplayImageSrc(imageUrl);
      setImageInfoRef({
        originalUrl: imageUrl ?? "",
        editedUrl: imageUrl ?? "",
        file: imageBlob ? new File([imageBlob], getFileNameWithTimestampRemoved(image_path), { type: imageBlob.type }) : null,
      });
    } else {
      setDisplayImageSrc("");
      setImageInfoRef({
        originalUrl: "",
        editedUrl: "",
        file: null,
      });
    }
    setLoadingImage(false);
  };

  /**
   * Delete image on both backend and S3 bucket
   * 1. Send empty string to backend to update the sample's image path
   * 2. If backend updated successfully, then delete the image on S3
   * @returns
   */
  const handleDeleteImage = async () => {
    const payload = {
      ...curReportInfo.params,
      image_path: "",
    };
    const response = await updateEnvSampleImage(payload);
    if (!response.success) {
      toast.error("Image failed to update");
      return false;
    }
    if (imagePath) {
      deleteFileFromS3WithPresigned([imagePath], "private");
    }
    // setImagePath("");
    toast.success("Image updated successfully.");
    return true;
  };

  /**
   * Upload the image to the target s3 bucket
   * @param {*} file the current image uploaded from local
   * @returns the aws api response and the file path
   */
  const addSampleImageToAWS = async (file) => {
    if (file) {
      const { name, ext } = getFileNameAndExtensionFromPath(file.name);
      const fileInfo = {
        file, folderPath: "SampleSubmission/", fileName: `${name}_${Date.now()}${ext}`, type: file.type,
      };
      const key = getFilePath(fileInfo);
      const fileBlob = await uploadFileToS3WithPresigned([fileInfo], "private");
      if (fileBlob && fileBlob[key]) {
        return { success: true, path: fileBlob[key].path };
      }
    } return { success: false };
  };

  /**
   * Update new image on both backend and S3
   * 1. If it is a "delete" action, then delete the image
   * 2. Update:
   *    2.1: upload to S3 and get the s3 image path
   *    2.2: send the s3 image path with current report infor to backend
   *    2.3: delete the old image on S3
   * @param {File} imageFile file of updated image
   * @param {Boolean} deleteImage boolean value which determine current update is delete or edit
   * @returns
   */
  const updateNewSampleImage = async (imageFile, deleteImage = false) => {
    if (deleteImage) {
      return handleDeleteImage();
    }

    const { success, path } = await addSampleImageToAWS(imageFile);
    if (!success) {
      toast.error("Image failed to upload to AWS");
      return false;
    }
    const payload = {
      ...curReportInfo.params,
      image_path: path,
    };
    const response = await updateEnvSampleImage(payload);
    if (!response.success) {
      toast.error("Image failed to update");
      return false;
    }
    if (imagePath) {
      deleteFileFromS3WithPresigned([imagePath], "private");
    }
    // setImagePath(path);
    toast.success("Image updated successfully.");
    return true;
  };

  useEffect(() => {
    setImageInfoRef({
      originalUrl: "",
      editedUrl: "",
      file: null,
    });
    setDisplayImageSrc("");
    if (imagePath && curReportInfo) {
      loadImageFromAWS(imagePath);
    }
  }, [locationID, imagePath, curReportInfo]); // eslint-disable-line

  const hideUploadImage = displayImageSrc || !curReportInfo || (curReportInfo && !curReportInfo.showUploadImage);
  return (
    <>
      <div
        className={`SampleSubmission__UploadImageInputDiv ${hideUploadImage ? "imageWithSrc" : ""}`}
        onClick={hideUploadImage ? null : () => setShowUploadImageModal(true)}
      >
        {(curReportInfo?.showUploadImage && !loadingImage) && (
          <>
            {displayImageSrc ? (
              <img src={displayImageSrc} alt="sample" />
            ) : (
              <UploadImageIcon />
            )}
            {displayImageSrc && (
              <div
                className="SampleSubmission__ImageEdit"
                onClick={() => setShowUploadImageModal(true)}
              >
                <EditIcon />
              </div>
            )}
          </>
        )}
      </div>
      <UploadImageModal
        setShowUploadImageModal={setShowUploadImageModal}
        showUploadImageModal={showUploadImageModal}
        setDisplayImageSrc={setDisplayImageSrc}
        displayImageSrc={displayImageSrc}
        imageInfoRef={imageInfoRef}
        imageFileType={imageFileType}
        setImageInfoRef={(obj) => { imageInfoRef.current = { ...obj }; }}
        locationID={locationID}
        // eslint-disable-next-line react/jsx-boolean-value
        editMode={true}
        updateNewSampleImage={updateNewSampleImage}
      />
    </>

  );
}
