import React, { useState } from "react";

import { Dropdown } from "antd";
import { toast } from "react-toastify";

import TEMPLATE_TAGS_COLOR from "../../helper";
import TemplateDeleteModal from "./TemplateDeleteModal";
import TemplatePreview from "./TemplatePreview";

import { getFileFromS3WithPresigned } from "../../../../../utils/helpers";

import { ReactComponent as DeleteIcon } from "../../../../../assets/images/product/template/delete.svg";
import { ReactComponent as EditIcon } from "../../../../../assets/images/product/template/edit.svg";
import { ReactComponent as OptionIcon } from "../../../../../assets/images/product/template/options.svg";
import PreviewIcon from "../../../../../assets/images/product/template/PlayWhite.png";

import "./TemplateCard.css";

export default function TemplateCard({
  index,
  item,
  isCreatingCoa,
  isSelected,
  // reportIsSelected,
  saveTemplateIdForAddingCoa,
  handleEditTemplate,
  handleDeleteTemplate,
}) {
  const [templatePreview, setTemplatePreview] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  // const templateDisabled = isCreatingCoa && !reportIsSelected;
  // const backgroundColor = templateDisabled ? "#AFBDCA" : TEMPLATE_TAGS_COLOR[item.tags[0]].backgroundColor;
  const backgroundColor = TEMPLATE_TAGS_COLOR[item.tags[0]].backgroundColor;
  const borderColor = isSelected ? TEMPLATE_TAGS_COLOR[item.tags[0]].borderColor : backgroundColor;
  const boxShadow = isSelected ? TEMPLATE_TAGS_COLOR[item.tags[0]].boxShadow : "";

  const handleSelectTemplate = () => {
    if (isCreatingCoa) {
      saveTemplateIdForAddingCoa(index);
    }
  };

  const toggleShowDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const handlePreviewTemplate = async (filePath) => {
    if (!filePath) {
      toast.error("Preview link not available");
      return;
    }
    const fileBlobObj = await getFileFromS3WithPresigned([filePath], "private");
    const blob = fileBlobObj[filePath];
    const url = window.URL.createObjectURL(blob);
    if (url) {
      const previewObj = { url, title: filePath.slice(0, -4) };
      setTemplatePreview(previewObj);
    } else {
      toast.error("Failed to preview");
    }
  };

  const closePreviewTemplate = () => {
    if (templatePreview.url) {
      URL.revokeObjectURL(templatePreview.url);
    }
    setTemplatePreview(null);
  };

  const handleClickPreview = (e) => {
    const isDropdownTrigger = e.target.id === "TemplateCardDropdownTrigger" || e.target.parentNode.id === "TemplateCardDropdownTrigger";
    if (!isDropdownTrigger) { // open preview only if click was not on the dropdown trigger
      handlePreviewTemplate(item.preview_pdf_url);
    }
  };

  const handleOptionClick = ({ key, domEvent }) => {
    domEvent.stopPropagation();
    switch (key) {
      case "edit":
        handleEditTemplate(item.template_id);
        break;
      case "delete":
        toggleShowDeleteModal();
        break;
      default:
        break;
    }
  };

  const items = [
    {
      key: "edit",
      label: (
        <div className="TemplateCard__MenuItem">
          <EditIcon className="templateCardEditIcon" />
          <span>Edit</span>
        </div>
      ),
    },
    {
      key: "delete",
      label: (
        <div className="TemplateCard__MenuItem">
          <DeleteIcon className="templateCardDeleteIcon" />
          <span>Delete</span>
        </div>
      ),
    },
  ];

  return (
    <>
      <div
        className="templateCardContainer"
        style={{
          backgroundColor,
          // cursor: isCreatingCoa && !templateDisabled ? "pointer" : "initial",
          border: isCreatingCoa ? `6px solid ${borderColor}` : "border: 1px solid #EAF0F5",
          boxShadow: isCreatingCoa ? boxShadow : "",
        }}
        onClick={isCreatingCoa ? handleSelectTemplate : (e) => handleClickPreview(e)}
      >
        <span className="TemplateCard__Text" title={item.name}>{item.name}</span>
        {isCreatingCoa && (
          <img
            className="templateCardPlayIcon"
            src={PreviewIcon}
            alt="play icon"
            onClick={(e) => handleClickPreview(e)}
          />
        )}
        {!isCreatingCoa && (
          <Dropdown
            menu={{ items, onClick: handleOptionClick }}
            trigger={["click"]}
            overlayClassName="TemplateCard__Menu"
          >
            <OptionIcon className="templateCardOptionsIcon" id="TemplateCardDropdownTrigger" />
          </Dropdown>
        )}
      </div>
      {templatePreview
        && (
          <TemplatePreview
            pdfObj={templatePreview}
            closePreviewTemplate={closePreviewTemplate}
          />
        )}
      <TemplateDeleteModal open={showDeleteModal} toggleShowDeleteModal={toggleShowDeleteModal} handleDeleteTemplate={() => handleDeleteTemplate(item.template_id)} />
    </>
  );
}
