import React, { useEffect, useMemo, useState } from "react";

import { Modal } from "antd";
import { toast } from "react-toastify";

import StyledTooltip from "../../../Common/UIComponents/StyledTooltip";
import ZebraPrintEdit from "./ZebraPrintEdit";
import ZebraPrinterSettings from "./ZebraPrinterSettings";

import { AWS_BASE_PATH } from "../../../../utils/Constant";
import { getFileFromS3WithPresigned } from "../../../../utils/helpers";

import { getPrinters } from "../../../../actions/print";

import cancelBtn from "../../../../assets/images/product/crossWhite.png";

import "./ZebraPrintModal.css";

export default function ZebraPrintModal({ totalItems, onClose, scheduleData }) {
  const [config, setConfig] = useState({
    printer: "",
    copies: 1,
  });
  const [printerList, setPrinterList] = useState([]);
  const [isEditingPrinters, setIsEditingPrinters] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  /**
 * Set config state
 * @param {"printer" | "copies"} type type of field
 * @param {*} value value of field
 */
  const onSetConfig = (type, value) => {
    setConfig({ ...config, [type]: value });
  };

  const apiGetPrinters = async () => {
    const response = await getPrinters();
    if (response.success) {
      setPrinterList(response.result);
    } else {
      setPrinterList([]);
      toast.error("Unable to load printers");
    }
  };

  useEffect(() => {
    setIsLoading(true);
    apiGetPrinters().then(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    if (printerList.length === 1) {
      const printer = printerList[0];
      onSetConfig("serial_number", printer.serial_number);
    }
  }, [printerList]); // eslint-disable-line

  const onToggleEditPrinters = () => setIsEditingPrinters(!isEditingPrinters);

  const openPrinterDocs = async () => {
    const filePath = `${AWS_BASE_PATH}media/app/ZD410_Getting_Started-2.pdf`;
    const fileBlobObj = await getFileFromS3WithPresigned([filePath], "private");
    if (!(fileBlobObj && fileBlobObj[filePath])) {
      toast.error("Something went wrong");
      return;
    }
    const fileBlob = fileBlobObj[filePath];
    const blobURL = URL.createObjectURL(fileBlob);
    window.open(blobURL, "_blank");
  };

  const printerDropdownOptions = useMemo(() => printerList.map((printer) => ({ label: printer.name, value: printer.serial_number })), [printerList]);

  const renderHeader = (isEditing) => {
    if (isEditing) {
      return (
        <span>
          Printers (
          <StyledTooltip title="Getting Started with Zebra Printer.">
            <span className="ZebraPrintModal__HelpText" onClick={openPrinterDocs}>Getting Started</span>
          </StyledTooltip>
          )
        </span>
      );
    }

    return <span>Print Labels</span>;
  };

  return (
    <Modal
      open
      closable={false}
      centered
      footer={null}
      onCancel={onClose}
      className="ZebraPrintModal"
      width="486px"
      destroyOnClose
    >
      <div className="ZebraPrintModal__Container">
        {renderHeader(isEditingPrinters)}
        <img
          src={cancelBtn}
          alt="close"
          className="ZebraPrintModal__Container__CloseIcon"
          onClick={onClose}
        />
      </div>
      {!isEditingPrinters ? (
        <ZebraPrinterSettings
          config={config}
          onSelect={(value) => onSetConfig("serial_number", value)}
          onCopiesChange={(value) => onSetConfig("copies", value)}
          onToggleEditPrinters={onToggleEditPrinters}
          printerDropdownOptions={printerDropdownOptions}
          onClose={onClose}
          totalItems={totalItems}
          isLoading={isLoading}
          scheduleData={scheduleData}
        />
      ) : (
        <ZebraPrintEdit
          onToggleEditPrinters={onToggleEditPrinters}
          printerList={printerList}
          refetch={apiGetPrinters}
        />
      )}
    </Modal>
  );
}
