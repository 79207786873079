import React from "react";

import { Flex, Row } from "antd";
import { useHistory } from "react-router-dom";

import StyledButton from "../../../Common/UIComponents/StyledButton";

import { hasPermission } from "../../../../utils/common";

import "./LandingPage.css";

export default function LandingPage({
  heading, description, image, setDisplayForm,
}) {
  const canOnboardNewFeature = hasPermission(["onboard_product", "onboard_environment"]);
  const history = useHistory();

  return (
    <Row justify="center" className="LandingPage__Div" span={24}>
      <Flex className="LandingPage">
        <div className="LandingPage__Container">
          <p className="LandingPage__Text">Begin your</p>
          <p className="LandingPage__Heading">
            {heading}
          </p>
          <p className="LandingPage__Description">
            {description}
          </p>
          {canOnboardNewFeature ? (
            <StyledButton type="primary" className="LandingPage__Btn" onClick={() => setDisplayForm(true)}>
              Get started
            </StyledButton>
          ) : (
            <>
              <p className="LandingPage__RequestText">Request your administer</p>
              <StyledButton type="primary" className="LandingPage__Btn" onClick={() => history.push("/sample-submission")}>
                Back
              </StyledButton>
            </>
          )}
        </div>
        <div className="LandingPage__Image">
          <img src={image} alt="" />
        </div>
      </Flex>
    </Row>
  );
}
