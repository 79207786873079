import React, { Component } from "react";

import { Layout, Select, Tooltip } from "antd";
import { Slide } from "react-awesome-reveal";
import Scrollbar from "react-scrollbars-custom";
import { toast } from "react-toastify";
import "./TeamMember.css";

import { AppContext } from "../../../AppContext";
import MyAccountSideMenu from "../MyAccountSideMenu";
import AddMemberModal from "./AddMemberModal";
import DeleteMemberModal from "./DeleteMemberModal";
import ResendInviteButton from "./ResendInviteButton";

import { hasPermission } from "../../../utils/common";

import {
  getMembersInfo,
  deleteTeamMember,
  editMemberRole,
} from "../../../actions/myAccount";
import { inviteMember } from "../../../actions/signup";

import infoIcon from "../../../assets/images/product/infoCircleBlue.png";
import { ReactComponent as TrashCan } from "../../../assets/images/trashIconLightblue.svg";

class TeamMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      editingRoleKey: "", // user_payload of user that is changing roles
      resendingInviteKey: "", // user_payload of user who is being resent an invite
      members: [],
      showDeleteMemberModal: false,
      deletedMember: null,
      showAddMemberModal: false,
    };
  }

  componentDidMount() {
    this.apiGetMembersInfo().then((members) => {
      this.setState({ loading: false, members });
    });
  }

  apiGetMembersInfo = async () => {
    const response = await getMembersInfo();
    if (response && response.success) {
      return response.members;
    } return null;
  }

  handleMenuClick = (event) => {
    const key = event.key;
    const { history } = this.props;
    if (key === "1") {
      history.push("/myaccount/profile");
    } else if (key === "2") {
      history.push("/myaccount/members");
    } else if (key === "3") {
      history.push("/myaccount/help");
      // this.props.history.push(`/myaccount/setting`);
    }
    // else if (key === "4") {
    //   this.props.history.push(`/myaccount/faq`);
    // } else if (key === "5") {
    //   this.props.history.push(`/myaccount/help`);
    // }
  }

  handleShowAddMemberModal = () => {
    this.setState({ showAddMemberModal: true });
  }

  handleCloseAddMemberModal = () => {
    this.setState({ showAddMemberModal: false });
  }

  handleShowDeleteMemberModal = (row) => {
    const { members } = this.state;
    this.setState({ showDeleteMemberModal: true, deletedMember: members[row] });
  }

  handleCloseDeleteMemberModal = () => {
    this.setState({ showDeleteMemberModal: false, deletedMember: null });
  }

  handleConfirmDeleteMember = () => {
    const { deletedMember } = this.state;
    const { user_email, user_payload } = deletedMember;
    const payload = {
      email: user_email,
      user_payload,
    };
    deleteTeamMember(payload).then((response) => {
      if (response && response.success) {
        this.apiGetMembersInfo().then((members) => {
          this.setState({ members });
        });
        this.handleCloseDeleteMemberModal();
        toast.success("Member successfully deleted.");
      } else if (!response || !response.message) {
        toast.error("Unable to delete member.");
      } else {
        toast.error(response.message);
      }
    });
  }

// api to add member
handleConfirmAddMember = (params, setLoadingState) => inviteMember(params).then((response) => {
  setLoadingState(false);
  if (response.success) {
    this.apiGetMembersInfo().then((members) => {
      this.setState({ members });
    });
    this.handleCloseAddMemberModal();
    toast.success("Member successfully added.");
  } else {
    toast.error(response.message);
  }
})

/**
 * Call inviteMember to send user another invite email
 * @param {Object} userInfo { user_email, user_contact, user_name, user_payload, user_title, status }
 */
handleResendInvite = async (userInfo) => {
  const {
    user_email, user_name, user_contact, user_payload,
  } = userInfo;
  this.setState({ resendingInviteKey: user_payload });
  const payload = {
    name: user_name, email: user_email, phone: user_contact,
  };
  const { success } = await inviteMember(payload);
  if (success) {
    toast.success("Invitation successfully resent");
  } else {
    toast.error("Unable to resend invitation");
  }
  this.setState({ resendingInviteKey: "" });
}

/** Make an api call to change a user's role
 * @param {*} value
 * @param {*} item
 */
handleChangeRole = async (value, item) => {
  const { user_payload, user_email } = item;
  this.setState({
    editingRoleKey: user_payload,
  });

  const payload = {
    user_payload,
    email: user_email,
    new_title: value,
  };

  const { success, message } = await editMemberRole(payload);
  if (success) {
    this.apiGetMembersInfo().then((refreshed_members_list) => {
      this.setState({ members: refreshed_members_list, editingRoleKey: "" });
    });
  } else {
    toast.error(message);
    this.setState({
      editingRoleKey: "",
    });
  }
}

/**
 * Display different text based on the cellText and title
 * @param {*} title
 * @param {*} memberIsAdmin
 * @param {*} cellText
 * @param {*} i
 * @param {*} item
 * @returns
 */
showCellContent = (title, cellText, i, item) => {
  const { editingRoleKey, resendingInviteKey } = this.state;
  const memberIsAdmin = item.user_title === "Admin";
  const isPending = item.status === "0";
  const disableInteraction = editingRoleKey || resendingInviteKey;

  if (title !== "Delete" && title !== "Role" && title !== "Status") {
    return (<span>{cellText}</span>);
  }

  /** Show the trashcan icon for non-admin users */
  if (title === "Delete" && !memberIsAdmin) {
    return (
      <TrashCan
        className={`TeamMemberLayout_Button_TrashIcon${disableInteraction ? "--disabled" : ""}`}
        onClick={disableInteraction ? null : () => this.handleShowDeleteMemberModal(i)}
      />
    );
  }

  /** Show the resend invite button if the logged in user is admin, the row is for a non admin user, and status is pending  */
  if (title === "Status" && isPending && hasPermission("add_member") && !memberIsAdmin) {
    return (
      <>
        <span>{cellText}</span>
        <ResendInviteButton
          userInfo={item}
          loading={resendingInviteKey && item.user_payload === resendingInviteKey}
          disabled={disableInteraction}
          handleResendInvite={this.handleResendInvite}
        />
      </>
    );
  }

  if (title === "Role" && !memberIsAdmin) {
    /** Allow editing user's role for the Admin role */
    if (hasPermission("change_member_role")) {
      return (
        <Select
          className="TeamMember__Role"
          popupClassName="TeamMember__Role__DropdownMenu"
          disabled={disableInteraction}
          loading={editingRoleKey && item.user_payload === editingRoleKey}
          bordered={false}
          value={cellText}
          onChange={(val) => this.handleChangeRole(val, item, i)}
          options={[
            { value: "Member", label: "Member" },
            { value: "Editor", label: "Editor" },
          ]}
        />
      );
    }
    /** Show the role text for non-admin role */
    return (<span className="TeamMember__Role__TextCell">{cellText}</span>);
  }

  return (<span>{cellText}</span>);
}

render() {
  const {
    loading,
    members,
    showAddMemberModal,
    showDeleteMemberModal,
  } = this.state;

  const columns = [
    { title: "Name", key: "user_name", permission: "no_permission" },
    { title: "Email Address", key: "user_email", permission: "no_permission" },
    { title: "Phone No.", key: "user_contact", permission: "no_permission" },
    { title: "Status", key: "status", permission: "view_member_status" },
    { title: "Role", key: "user_title", permission: "change_member_role" },
    { title: "Delete", align: "center", permission: "delete_member" },
  ];

  const authorizedColumns = columns.reduce((arr, item) => {
    if (hasPermission(item.permission)) {
      arr.push(item);
    }
    return arr;
  }, []);

  const statusMap = { 0: "Pending", 1: "Active" };

  return (
    <div className="myAccountMainContainer">
      {showDeleteMemberModal && (
      <DeleteMemberModal
        onConfirm={this.handleConfirmDeleteMember}
        onClose={this.handleCloseDeleteMemberModal}
      />
      )}

      {showAddMemberModal && (
      <AddMemberModal
        onClose={this.handleCloseAddMemberModal}
        onConfirm={this.handleConfirmAddMember}
      />
      )}

      <Layout className="TeamMemberLayout">
        <MyAccountSideMenu
          selectedMenuItem="2"
          handleMenuClick={this.handleMenuClick}
        />
        <div className="myAccountRightMainContainerNoShadow">
          {!loading && (
            <div className="myAccountMemberMainContainer">
              <Slide direction="left" triggerOnce>
                <div className="myAccountContainerTitle TeamMemberLayout__Slide">
                  Team Members
                </div>
              </Slide>

              <Slide direction="up" triggerOnce>
                <div className="newMemberBtnTableDiv">
                  {hasPermission("add_member")
                    && (
                      <button
                        type="button"
                        onClick={() => this.handleShowAddMemberModal()}
                        className="newMemberBtn"

                      >
                        + New Member
                      </button>
                    )}
                  <Scrollbar
                    className="TeamMemberLayout__ScrollBar"
                    disableTrackYWidthCompensation
                    disableTracksWidthCompensation
                  >
                    <table className="TeamMemberTable">
                      <thead>
                        <tr>
                          {authorizedColumns.map(({ title, align }) => (
                            <th key={title} className={`TeamMember__TableHeaderCell ${align === "center" ? "TeamMember__TableHeaderCell--center-align" : ""} ${title === "Role" ? "TeamMember__TableHeaderCell__Role" : ""}`}>
                              {title}
                              {title === "Role" && (
                                <Tooltip
                                  title={(
                                    <span>
                                      <strong>Editor: </strong>
                                      CAN view/edit data, submit samples, edit specs, but CANNOT invite/delete members.
                                      <br />
                                      <strong>Member: </strong>
                                      CAN view data and submit samples, but CANNOT edit sample tests, edit specs and invite/delete members.
                                    </span>
                                  )}
                                  overlayClassName="TeamMember__Tooltip"
                                >
                                  <img
                                    className="specificationInfo"
                                    src={infoIcon}
                                    alt="infoIcon"
                                  />
                                </Tooltip>
                              )}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {members && members.length > 0 && members.map((item, i) => (
                          <tr key={i} className="TeamMember__TableRow">
                            {authorizedColumns.map(({ title, key, align }) => {
                              const cellText = title === "Status" ? statusMap[item[key]] : item[key];
                              return (
                                <td key={title} className={`TeamMember__TableCell ${align === "center" ? "TeamMember__TableCell--center-align" : ""}`}>
                                  <div className="TeamMember__TableCellContent">
                                    {this.showCellContent(title, cellText, i, item)}
                                  </div>
                                </td>
                              );
                            })}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Scrollbar>
                </div>
              </Slide>
            </div>
          )}

        </div>
      </Layout>
    </div>
  );
}
}

TeamMember.contextType = AppContext; // allows class component to consume context, access via this.context
export default TeamMember;
