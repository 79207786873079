import React from "react";

import moment from "moment";
import Highlighter from "react-highlight-words";
import { toast } from "react-toastify";

import TEMPLATE_TAGS_COLOR from "../../helper";

import { getFileFromS3WithPresigned, downloadFileFromS3WithPresigned } from "../../../../../utils/helpers";

import Calendar from "../../../../../assets/images/product/template/Calendar.png";
import Download from "../../../../../assets/images/product/template/Download.png";
import Product from "../../../../../assets/images/product/template/Product.png";

export default function COAListItem(props) {
  const {
    elem, toggleShowDeleteModal, setPdfObjForPreview, searchedValue,
  } = props;
  const handleDelete = () => {
    toggleShowDeleteModal(elem);
  };

  const handleDownload = async () => {
    const fileName = elem.pdf_path.split("/").pop();
    const downloadSuccess = await downloadFileFromS3WithPresigned([elem.pdf_path], "private", [fileName]);
    if (!downloadSuccess) {
      toast.error("Failed to download");
    }
  };

  const handlePreview = async () => {
    const fileBlobObj = await getFileFromS3WithPresigned([elem.pdf_path], "private");
    const blob = fileBlobObj[elem.pdf_path];
    if (!blob) {
      toast.error("Failed to preview");
    }
    const url = window.URL.createObjectURL(blob);
    if (url) {
      const previewObj = { url, title: elem.pdf_path.slice(0, -4) };
      setPdfObjForPreview(previewObj);
    } else {
      toast.error("Failed to preview");
    }
  };
  return (
    <div className="coaListItemContainer">
      <div className="coaListItemHeader" onClick={handlePreview}>
        <Highlighter
          highlightClassName="coaListItemHeader__SearchHighlight"
          searchWords={[searchedValue]}
          autoEscape
          textToHighlight={elem.coa_name}
        />
      </div>
      <div className="coaListItemBody">
        <div className="coaListItemBodyLeft">
          <div className="coaListItemNumber">
            <img src={Product} alt="Product" className="coaListItemProductImg" />
            <span className="LinkedFieldsSpan">
              {elem.all_identifiers.map((identifierArr, sampleIdx) => (
                <React.Fragment key={sampleIdx}>
                  <span className="LinkedFieldsSpan">
                    {identifierArr.map((val, valIdx) => (
                      <React.Fragment key={valIdx}>
                        <span>
                          {val}
                          {valIdx === identifierArr.length - 1 && sampleIdx < elem.all_identifiers.length - 1 && (",")}
                        </span>
                        {valIdx < identifierArr.length - 1 && (<span className="LinkedFieldsDelimiterDot" />)}
                      </React.Fragment>
                    ))}
                  </span>
                </React.Fragment>
              ))}
            </span>
          </div>
          <div className="coaListItemDate">
            <img src={Calendar} alt="Calendar" className="coaListItemCalendarImg" />
            <span>
              {moment(elem.added_on).format("MM/DD/YYYY")}
            </span>
          </div>
          <div
            className="coaListItemTemplate"
            style={{
              backgroundColor: elem.tags ? TEMPLATE_TAGS_COLOR[elem.tags[0]].backgroundColor : "#A9A9A9",
            }}
          >
            <span>
              {elem.template_name || "Deleted Template"}
            </span>
          </div>
        </div>
        <div className="coaListItemBodyRight">
          <div>
            <div className="coaListItemDeleteImg" onClick={() => handleDelete()} />
          </div>
          <div>
            <img src={Download} alt="Download" className="coaListItemDownloadImg" onClick={handleDownload} />
          </div>
        </div>
      </div>
    </div>
  );
}
