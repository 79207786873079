import React, { useState } from "react";

import { Modal } from "antd";

import DatePicker from "../../../Common/DatePicker/DatePicker";
import StyledButton from "../../../Common/UIComponents/StyledButton";

import { ReactComponent as CalendarIcon } from "../../../../assets/images/environment/CalendarEnv.svg";
import { ReactComponent as DownloadIcon } from "../../../../assets/images/environment/downloadIcon.svg";
import { ReactComponent as PrinterIcon } from "../../../../assets/images/environment/PrinterIcon.svg";
import cancelBtn from "../../../../assets/images/product/crossWhite.png";

import "./ReportModal.css";

export default function ReportModal({
  onClose,
  title,
  children,
  onPrint,
  onDownload,
  onDatePickerChange,
  disablePrintButton = false,
  disableDownloadButton = false,
  markedDates,
  defaultDateRange = "",
  showDatePicker = true,
  showPrint = true,
  showDownloadButton = true,
  allowClear = true,
}) {
  const [dateSelected, setDateSelected] = useState(defaultDateRange);
  const [prevDateSelected, setPrevDateSelected] = useState(defaultDateRange);

  return (
    <Modal
      open
      closable={false}
      centered
      footer={null}
      onCancel={onClose}
      className="ReportModal"
      width="676px"
    // afterOpenChange={() => this.setState({ modalIsAnimating: false })}
    >
      <div className="ReportModal__Container">
        <span>{title}</span>
        <img
          src={cancelBtn}
          alt="close"
          className="ReportModal__Container__CloseIcon"
          onClick={onClose}
        />
      </div>
      <div className="ReportModal__Content">
        <div className={`ReportModal__ButtonPanel ${showDatePicker ? "" : "ReportModalNoCalendarView"}`}>
          {showDatePicker && (
            <DatePicker
              handleSearchDate={onDatePickerChange} // eslint-disable-line
              getReportDates={() => markedDates ?? []}
              value={dateSelected}
              onChange={setDateSelected}
              prevValue={prevDateSelected}
              setPrevValue={setPrevDateSelected}
              searchOnClear
              popoverPlacement="bottomLeft"
              allowClear={allowClear}
              datePickerDisplayProps={{
                displayTextPosition: "right",
                displayClassName: "ReportModal__DatePicker",
                customCalendarIcon: <CalendarIcon width={16} height={16} />,
              }}
            />
          )}
          <div className="ReportModal__Buttons">
            {showPrint && (
              <StyledButton
                htmlType="button"
                type="primary"
                ghost
                className="ReportModal__PrintButton"
                onClick={onPrint}
                disabled={disablePrintButton}
              >
                <PrinterIcon />
                Print
              </StyledButton>
            )}
            {showDownloadButton && (
              <StyledButton
                htmlType="button"
                type="primary"
                className="ReportModal__DownloadButton"
                onClick={onDownload}
                disabled={disableDownloadButton}
              >
                <DownloadIcon />
                Download
              </StyledButton>
            )}
          </div>
        </div>
        {children}
      </div>
    </Modal>
  );
}
