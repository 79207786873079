import React from "react";
import { Tooltip } from "antd";
import cancelIcon from "../../../assets/images/product/crossDarkBlue.png";
// import defaultCalendarIcon from "../../../assets/images/product/calendarDarkBlue.png";
import defaultCalendarIcon from "../../../assets/images/sample-submission/SampleCalendarIcon.svg";
import "./DatePickerDisplay.css";

export default function DatePickerDisplay({
  dateSelected,
  showCalendarInternal,
  setDateSelected,
  setPrevDateSelected,
  handleSearchDate,
  displayTextPosition = "right", // "right" | "left" - whether the text grows to the right or the left of the calendar
  displayClassName = "",
  showText = true, // whether to show dates or not
  iconTooltipTitle = "", // tooltip content (leave empty for no tooltip)
  customCalendarIcon = null, // custom calendar icon
  displayElemId, // id of display container
  allowClear = true,
}) {
  const handleResetDateFilter = (e) => {
    e.stopPropagation();
    setDateSelected("");
    setPrevDateSelected("");
    handleSearchDate("", "");
  };

  return (
    <div
      className={`DatePickerDisplay${displayTextPosition === "left" ? "--row-reverse" : ""} ${displayClassName}`}
      id={displayElemId}
    >
      <Tooltip
        title={iconTooltipTitle}
        placement="top"
        overlayClassName="DatePickerDisplay__Tooltip"
      >
        {customCalendarIcon ?? (
          <img
            className="DatePickerDisplay__CalenderIcon"
            src={defaultCalendarIcon}
            alt="calendarIcon"
          />
        )}
      </Tooltip>
      {showText && dateSelected && (
        <>
          <span>{dateSelected}</span>
          {!showCalendarInternal && allowClear && (
            <img
              className="DatePickerDisplay__CancelIcon"
              src={cancelIcon}
              alt="cancelIcon"
              onClick={handleResetDateFilter}
              height={16}
              width={16}
            />
          )}
        </>
      )}
    </div>
  );
}
