import React, {
  useEffect, useState, useRef, useContext, useMemo,
  forwardRef, useImperativeHandle,
  useCallback,
} from "react";

import { motion } from "framer-motion/dist/framer-motion";
import {
  partition, has, isEmpty, debounce, every,
} from "lodash";
import { Scrollbar } from "react-scrollbars-custom";

import {
  showScrollbarStyle,
  hideScrollbarStyle,
} from "../../../../Common/AutoHideScrollbarStyles";
import UploadImageModal from "../../../../Common/ImageEditModal/UploadImageModal";
import SampleSubmissionContext from "../../../SampleSubmissionContext";
import {
  CHAR_LIMITS, COMPOSITE_FIELD_DELIMITER, INVALID_CHARS_ESCAPED,
} from "../../Constant";
import CompositeModal from "./CompositeModal";
import SampleCompositeToggle from "./SampleCompositeToggle";
import SampleDetailsAutocompleteInput from "./SampleDetailsAutocompleteInput";
import SampleDetailsInput from "./SampleDetailsInput";
import UploadImageInput from "./UploadImageInput";

// import StyledTooltip from "../../../../Common/UIComponents/StyledTooltip";
import { hasFeature } from "../../../../../utils/common";
import { getFileFromS3WithPresigned, getFileNameWithTimestampRemoved } from "../../../../../utils/helpers";
import { getSortedFields } from "../../../utils";
import { initializeObjectWithDefaultValue, removeReplicateTag } from "../../helpers";

import { getDropdownSuggestionsAndTests } from "../../../../../actions/sampleSubmission";

import { ReactComponent as AddIcon } from "../../../../../assets/images/sample-submission/addSamplePlusIcon.svg";

import "./SampleDetails.css";

const SampleDetails = forwardRef(({
  inputValuesRefs,
  setInputValuesRefs,
  inputValuesSavedForNext,
  toggleSaveForNext,
  sampleFields,
  sampleFieldsInfo,
  clearSampleDetailsForm,
  setClearSampleDetailsForm,
  draftEditing,
  loadDraftState,
  setLoadDraftState,
  loadSampleEditing,
  setLoadSampleEditing,
  sampleEditing,
  handleClickBackButton,
  loadingFields,
  delimiterRegex,
  setInvalidInputFound,
  savedState,
  loadSavedState,
  setLoadSavedState,
  linkedFields,
  setTestsSelected,
  saveTestsForNext,
  generateSampleID,
  setInputValueAutofilled,
  imageInfoRef, // { originalUrl, editedUrl, file, crop, rotate, scale }
  setImageInfoRef,
  setLoadingImage,
  submissionEditingSample,
  submissionAddingSamples,
  sampleIdFields,
  disableAddSample,
  testInfoMap,
  sampleFromSubmission,
  submissionDetails,
  testsSelected,
  setShowExitModal,
  setIsLoadingTest,
  compositeSample,
  editingSubmittedSampleComposite,
  analysisRequestRef,
  clearCompositeSaveForNext,
}, ref) => {
  const [compositeData, setCompositeData] = useState({ sample_count: null, unique: null, identifier: [] });
  const [inputValues, setInputValues] = useState(() => initializeObjectWithDefaultValue(sampleFields, ""));
  const [inputInvalid, setInputInvalid] = useState(() => initializeObjectWithDefaultValue(sampleFields, false));
  const [selectedFields, setSelectedFields] = useState([]); // Selected fields are the fields selected to input field values
  const [fieldOptions, setFieldOptions] = useState([]); // Field options are shown on the top field pills
  const [dropdownSuggestionsObj, setDropdownSuggestionsObj] = useState({});
  const [poHasChanged, setPoHasChanged] = useState(submissionDetails.poHasChanged);
  const [scrollbarInUse, setScrollbarInUse] = useState();
  const horizontalScrollbarRef = useRef();
  const sampleFieldsSet = useRef(new Set(sampleFields));
  const inputValuesInitialization = useRef(true);
  const dropdownsAbortController = useRef();
  const [showUploadImageModal, setShowUploadImageModal] = useState(false);
  const [displayImageSrc, setDisplayImageSrc] = useState("");
  const imageFileType = useRef("");
  const [showCompositeModal, setShowCompositeModal] = useState(false);
  const [compositeToggle, setCompositeToggle] = useState(false);
  // const [compositeValues, setCompositeValues] = useState(() => initializeObjectWithDefaultValue(compositeData?.identifier, []));
  const {
    isLoggedOut, sampleCategory, thirdParty, mrl,
  } = useContext(SampleSubmissionContext);
  const enableSuggestions = !isLoggedOut || thirdParty;

  const loadImageFromAWS = async (image_path) => {
    setLoadingImage(true);
    let imageUrl;
    if (image_path) {
      const fileBlobObj = await getFileFromS3WithPresigned([image_path], "private");
      const imageBlob = fileBlobObj[image_path];
      if (imageBlob) {
        imageUrl = window.URL.createObjectURL(imageBlob);
        imageFileType.current = imageBlob.type;
      }
      setDisplayImageSrc(imageUrl);
      setImageInfoRef({
        originalUrl: imageUrl ?? "",
        editedUrl: imageUrl ?? "",
        file: imageBlob
          ? new File([imageBlob], getFileNameWithTimestampRemoved(image_path), { type: imageBlob.type })
          : null,
        imageWasEdited: false,
      });
      if (submissionEditingSample) {
        sampleFromSubmission.current.image_src = imageUrl;
      }
    } else {
      setDisplayImageSrc("");
      setImageInfoRef({
        originalUrl: "",
        editedUrl: "",
        file: null,
        imageWasEdited: false,
      });
      if (submissionEditingSample) {
        sampleFromSubmission.current.image_src = "";
      }
    }
    setLoadingImage(false);
  };

  /**
   * Get dropdown suggestions based on current inputValues, get corresponding test list
   * @param {Object} currInputValues
   * @returns {Object} {item: [], best_by: [], test_list: [], ...}
   */
  const apiGetDropdownSuggestions = async (currInputValues) => {
    /** Set the po value based on
     * 1. New submission
     * 2. Is adding new sample to a submission
     * 3. Is editing existing sample to a submission
     */
    setIsLoadingTest(true);
    const sample = { po: submissionDetails.poNumber };
    if (submissionAddingSamples) {
      sample.po = submissionAddingSamples.po;
    } else if (submissionEditingSample) {
      sample.po = submissionEditingSample.po;
    }

    selectedFields.forEach((field) => {
      if (currInputValues[field]) {
        sample[field] = currInputValues[field];
      }
    });
    const newAbortController = new AbortController();
    if (dropdownsAbortController.current) {
      dropdownsAbortController.current.abort();
    }
    dropdownsAbortController.current = newAbortController;
    const { success, result } = await getDropdownSuggestionsAndTests(sample, newAbortController.signal, sampleCategory);
    if (newAbortController.signal.aborted) {
      return null;
    }
    dropdownsAbortController.current = null;
    if (success) {
      setIsLoadingTest(false);
      return result;
    }
    return {};
  };

  /**
   * Make API call to get new dropdown suggestions and tests to autofill
   * After clearing the form, set clearSampleDetailsForm back to false.
   * After loading saved state, set loadSavedState back to false.
   * After loading draft, set loadDraft back to false.
   * Make the call to generate the sample id as input values change.
   */
  const handleInputValuesUpdate = (stateValue) => {
    /* Set dropdown suggestions, autofill tests */
    if (enableSuggestions
      && ((!loadDraftState && !loadSavedState && !submissionEditingSample) || !inputValuesInitialization.current)) { // only call dropdowns api after actual draft/saved/submissionEditing state values set (the first time this useEffect is fired is when inputs are initialized to empty)
      debouncedHandleSetDropdownsAndTests(stateValue, // eslint-disable-line no-use-before-define
        clearSampleDetailsForm || loadDraftState,
        loadSavedState || loadSampleEditing);
    }

    /* Set load states back to false in this useEffect if dropdowns are disabled (if enabled, these states will be set back to false in handleSetDropdownsAndTests) */
    if (!enableSuggestions) {
      setLoadStatesToFalse(); // eslint-disable-line no-use-before-define
    }

    /* Generate Sample ID */
    // generateSampleID(
    //   stateValue,
    //   submissionEditingSample
    //     ? submissionEditingSample.sample.sample_id
    //     : sampleEditing?.sample_id,
    // );

    if (submissionEditingSample) {
      setShowExitModal(true);
    }

    /* After inputValues initialized to empty, set inputValuesInitialization to false */
    if (inputValuesInitialization.current) {
      inputValuesInitialization.current = false;
    }
  };

  /** componentDidMount - set selectedFields, fieldOptions */
  useEffect(() => {
    if (!isEmpty(sampleFieldsInfo)) {
      const [selected, notSelected] = partition(
        sampleFields,
        (json_field) => sampleFieldsInfo[json_field].display === "1" || sampleFieldsInfo[json_field].field_autofill === "1",
      );
      setSelectedFields(getSortedFields(selected, linkedFields, sampleCategory)); // fields displayed in product report
      setFieldOptions(notSelected); // fields not displayed in product report
    }
  }, [sampleFields]); // eslint-disable-line

  /** componentDidMount - set selectedFields, fieldOptions */
  useEffect(() => {
    if (compositeSample !== null) {
      setCompositeData(compositeSample);
      setCompositeToggle(false); // toggle
    }
  }, [compositeSample]); // eslint-disable-line

  useEffect(() => {
    const hasTrue = Object.values(inputInvalid).some((value) => value === true);
    if (hasTrue) {
      setInvalidInputFound(true);
    } else {
      setInvalidInputFound(false);
    }
  }, [inputInvalid]); // eslint-disable-line

  /** if savedState is not null, and loadSavedState, reset to savedState vals */
  useEffect(() => {
    if (loadSavedState && savedState !== null) {
      const { fields } = savedState;
      if (
        sampleCategory === "environment"
        && (!isLoggedOut || !thirdParty)
        && has(savedState, "imageInfoRef")
      ) {
        setImageInfoRef(savedState.imageInfoRef);
        setDisplayImageSrc(savedState.imageInfoRef.editedUrl);
      }
      const [selected, notSelected] = partition(
        sampleFields,
        (json_field) => fields[json_field] !== undefined,
      );
      setSelectedFields(getSortedFields(selected, linkedFields, sampleCategory));
      setFieldOptions(notSelected);
      const updatedInputValues = {};
      sampleFields.forEach((json_field) => {
        updatedInputValues[json_field] = fields[json_field]?.value || "";
      });
      setInputValues(updatedInputValues);
      handleInputValuesUpdate(updatedInputValues);
    }
  }, [savedState, loadSavedState]); // eslint-disable-line

  /** Show sample details of the sample that is being edited */
  useEffect(() => {
    if (!isEmpty(sampleEditing) && !isEmpty(testInfoMap) && loadSampleEditing && !loadSavedState) {
      /** Set tests */
      const tests_selected = {};
      sampleEditing.test_list.forEach((test) => {
        tests_selected[test] = { ...(testInfoMap.get(test) ?? {}) };
      });
      setTestsSelected(tests_selected);
      /** Set selected fields */
      const [selected, notSelected] = partition(
        sampleFields,
        (json_field) => sampleEditing[json_field] !== undefined
          && sampleEditing[json_field] !== "",
      );
      setSelectedFields(getSortedFields(selected, linkedFields, sampleCategory));
      setFieldOptions(notSelected);
      /** Set field values, save for next */
      const updatedInputValues = {};
      sampleFields.forEach((json_field) => {
        updatedInputValues[json_field] = sampleEditing[json_field] ?? "";
        toggleSaveForNext(json_field, false); // set checkbox to false
      });

      setInputValues(updatedInputValues);
      handleInputValuesUpdate(updatedInputValues);

      /** Set image */
      if (sampleCategory === "environment" && (!isLoggedOut || thirdParty)) {
        toggleSaveForNext("img", false);
        if (has(sampleEditing, "image_src")) {
          setDisplayImageSrc(sampleEditing.image_src ?? "");
          setImageInfoRef({
            originalUrl: sampleEditing.image_src ?? "",
            editedUrl: sampleEditing.image_src ?? "",
            file: sampleEditing.image_file ?? null,
            imageWasEdited: false,
          });
          imageFileType.current = sampleEditing.image_file?.type ?? "";
        } else if (has(sampleEditing, "image_path")) {
          loadImageFromAWS(sampleEditing.image_path);
        } else {
          setImageInfoRef({
            originalUrl: "",
            editedUrl: "",
            file: null,
            imageWasEdited: false,
          });
          imageFileType.current = "";
          setDisplayImageSrc("");
        }
      }
    }
    /** Composite sample editing draft */
    if (editingSubmittedSampleComposite && sampleEditing.num_composite_samples && sampleEditing.num_composite_samples > 0) {
      setCompositeData({
        sample_count: sampleEditing.num_composite_samples,
        identifier: sampleEditing.composite_fieldnames ?? [],
        unique: sampleEditing.composite_fieldnames.length > 0 ?? null,
      });
      setCompositeToggle(true);
    }
    if (editingSubmittedSampleComposite && submissionEditingSample && submissionEditingSample.sample.num_composite_samples && submissionEditingSample.sample.num_composite_samples > 0) {
      setCompositeData({
        sample_count: submissionEditingSample.sample.num_composite_samples,
        identifier: submissionEditingSample.sample.composite_fieldnames ?? [],
        unique: submissionEditingSample.sample.composite_fieldnames.length > 0 ?? null,
      });
      setCompositeToggle(true);
    }
  }, [sampleEditing, loadSampleEditing, testInfoMap]); // eslint-disable-line

  /** Show saved fields from draft object */
  useEffect(() => {
    if (loadDraftState && draftEditing) {
      const { fields: savedFields, test_list } = draftEditing;
      /** Set tests */
      if (test_list.length) {
        const tests_selected = {};
        test_list.forEach((test) => {
          tests_selected[test] = { ...(testInfoMap.get(test) ?? {}) };
        });
        setTestsSelected(tests_selected);
      }
      /** Set selected fields */
      const [selected, notSelected] = partition(
        sampleFields,
        (json_field) => sampleFieldsInfo[json_field].display === "1"
          || sampleFieldsInfo[json_field].field_autofill === "1"
          || savedFields[json_field] !== undefined,
      );
      setSelectedFields(getSortedFields(selected, linkedFields, sampleCategory));
      setFieldOptions(notSelected);
      /** Set field values, save for next */
      const updatedInputValues = {};
      sampleFields.forEach((json_field) => {
        if (has(savedFields, json_field)) {
          updatedInputValues[json_field] = savedFields[json_field];
          toggleSaveForNext(json_field, true); // on mount, set to true
        } else {
          updatedInputValues[json_field] = "";
          toggleSaveForNext(json_field, false); // on mount, set to false
        }
      });
      setInputValues(updatedInputValues);
      handleInputValuesUpdate(updatedInputValues);

      /** Set image */
      if (sampleCategory === "environment" && (!isLoggedOut || thirdParty)) {
        if (has(savedFields, "image_path")) {
          loadImageFromAWS(savedFields.image_path);
          toggleSaveForNext("img", true);
        } else {
          setImageInfoRef({
            originalUrl: "",
            editedUrl: "",
            file: null,
          });
          setDisplayImageSrc("");
        }
      }
    }
  }, [draftEditing, loadDraftState]); // eslint-disable-line

  /** When the add/update sample button is clicked, clear fields that are not use-for-next-sample */
  useEffect(() => {
    if (clearSampleDetailsForm) {
      /** Clear tests if not saved for next */
      if (!saveTestsForNext) {
        setTestsSelected({});
      }

      /** Clear all fields that are not saved for next */
      selectedFields.forEach((field) => {
        if (!inputValuesSavedForNext[field]) {
          inputValues[field] = "";
        }
      });

      /** Clear image if environment and not saved for next */
      if (sampleCategory === "environment" && !inputValuesSavedForNext.img) {
        setImageInfoRef({
          originalUrl: "",
          editedUrl: "",
          file: null,
        });
        setDisplayImageSrc("");
        imageFileType.current = "";
      }
      setInputValues({ ...inputValues });
      handleInputValuesUpdate({ ...inputValues });
      setCompositeData({ sample_count: null, unique: null, identifier: [] });
      setCompositeToggle(false);
    }
  }, [clearSampleDetailsForm]); // eslint-disable-line

  /**
   * Calls handleInputValuesUpdate() to update dropdown options for fields
   */
  useEffect(() => {
    handleInputValuesUpdate(inputValues);
  }, [sampleFields]); // eslint-disable-line

  /**
   * Make the call to generate the sample id as input values change.
   */
  useEffect(() => {
    generateSampleID(
      inputValues,
      submissionEditingSample
        ? submissionEditingSample.sample.sample_id
        : sampleEditing?.sample_id,
    );
  }, [inputValues, sampleFields]); // eslint-disable-line

  /** Set invalidInputFound in parent */
  useEffect(() => {
    setInvalidInputFound(
      Array.from(Object.values(inputInvalid)).some((val) => val === true),
    );
  }, []); // eslint-disable-line
  /** Back ref from the sample details
   * Input value should be returned to parent
   * Composite values should be returned to parent
   */
  useImperativeHandle(ref, () => ({
    backFunction() {
      if (!submissionAddingSamples && !submissionEditingSample) {
        handleClickBackButton();
      }
    },
    getInputValues: () => inputValues,
    getCompositeData: () => (compositeToggle ? compositeData : { sample_count: null, unique: null, identifier: [] }),
    clearCompositeData: () => {
      setCompositeData({ sample_count: null, unique: null, identifier: [] });
      setCompositeToggle(false);
    },
  }));

  /**
   * Checks if tests includes pesticides
   * @returns {boolean}
   */
  const hasMRLTest = useMemo(() => {
    const containsMRLTest = Object.values(testsSelected).findIndex((test) => test.test_category === "MRL") !== -1;
    return containsMRLTest && sampleCategory !== "environment" && (hasFeature("special_pesticides") || mrl);
  }, [testsSelected, sampleCategory]); // eslint-disable-line
  /**
   * Check if array of string has empty values and specific length
   * @param {string[]} valueArr Array of values
   * @param {number} totalFields total expected length
   * @returns {boolean} is valid
   */
  const hasEmptyCompositeValues = (valueArr, totalFields) => {
    const allNonEmptyString = every(valueArr, (value) => value.trim() !== "");
    const isValid = allNonEmptyString && valueArr.length === totalFields; // If true then valid, else invalid
    return !isValid;
  };

  /**
   * Process input values to limit the length of composite fields to sample_count
   * @param {string} values
   * @param {number} sample_count
   * @returns Check if the length of parts is greater than sample_count
   */

  const processInputValues = (values, sample_count) => {
    // Create a new object to hold the processed values
    const processedValues = {};

    // Use Object.entries to iterate over key-value pairs
    Object.entries(values).forEach(([key, value]) => {
      // Check if the value is a string and contains " :: "
      if (typeof value === "string" && value.includes(COMPOSITE_FIELD_DELIMITER)) {
        // Split the value by " :: "
        const parts = value.split(COMPOSITE_FIELD_DELIMITER);

        // Check if the length of parts is greater than sample_count
        if (parts.length > sample_count) {
          // Drop extra values
          parts.length = sample_count;
        }

        // Join the parts back into a string
        processedValues[key] = parts.join(COMPOSITE_FIELD_DELIMITER);
      } else {
        // If the value doesn't contain COMPOSITE_FIELD_DELIMITER, keep it as is
        processedValues[key] = value;
      }
    });

    // Return the modified object with the processed values
    return processedValues;
  };

  /**
   * Check mandatory composite field and disable Add Sample button.
   * Runs initially and when compositeData changes
   */
  useEffect(() => {
    if (compositeData.identifier.length) {
      const inputHasEmptyValues = every(Object.values(inputValues), (value) => value.trim() === "");
      if (!inputHasEmptyValues) {
        const fieldValues = processInputValues(inputValues, compositeData.sample_count);
        setInputValues(fieldValues);
      }
      const totalFields = compositeData.sample_count;
      const invalidFieldsCopy = { ...inputInvalid };
      compositeData.identifier.forEach((field) => {
        const currentValues = inputValues[field]?.split(COMPOSITE_FIELD_DELIMITER) || [];
        // every composite value should be non-empty
        if (field === "sample_type" && hasMRLTest) {
          invalidFieldsCopy[field] = false;
        } else {
          invalidFieldsCopy[field] = hasEmptyCompositeValues(currentValues, totalFields);
        }
      });
      setInputInvalid(invalidFieldsCopy);
      // Check if all identifiers are present in selectedFields
      const allFieldsPresent = compositeData.identifier.every((field) => selectedFields.includes(field));
      if (!allFieldsPresent) {
        compositeData.identifier.forEach((field) => {
          if (!selectedFields.includes(field)) {
            selectedFields.push(field); // Add missing field
          }
        });
        setSelectedFields(getSortedFields([...selectedFields], linkedFields, sampleCategory));
        const updatedFieldOptions = fieldOptions.filter((option) => !selectedFields.includes(option));
        setFieldOptions([...updatedFieldOptions]);
      }
      const isAnyFieldInvalid = Array.from(Object.values(invalidFieldsCopy)).some((val) => val === true);
      setInvalidInputFound(isAnyFieldInvalid);
    }
  }, [compositeData, testsSelected]); // eslint-disable-line

  // /**
  //  * Check mandatory composite field and disable Add Sample button on change of composite fields
  //  * Debounced for performance
  //  */
  const debouncedCheckMandatoryCompositeFields = useCallback(debounce((field, compositeFieldValueArr) => {
    const totalFields = compositeData.sample_count;
    const invalidFieldsCopy = { ...inputInvalid };
    // every composite value should be non-empty
    if (field === "sample_type" && hasMRLTest) {
      invalidFieldsCopy[field] = false;
    } else {
      invalidFieldsCopy[field] = hasEmptyCompositeValues(compositeFieldValueArr, totalFields);
    }
    const isAnyInvalidField = Array.from(Object.values(invalidFieldsCopy)).some((val) => val === true);
    setInputInvalid(invalidFieldsCopy);
    setInvalidInputFound(isAnyInvalidField);
  }, 500), [compositeData]); // eslint-disable-line

  /**
   * Set the field value in state
   * @param  {String} field json_field
   * @param  {String} val value of field
   * @param  {Boolean} autofilled if field is autofilled or not
   */
  const setFieldValue = (field, val, autofilled = false) => {
    if (sampleFieldsSet.current.has(field)) {
      // if false, do nothing
      inputValues[field] = val;
      setInputValues({ ...inputValues });
      handleInputValuesUpdate({ ...inputValues });
      setInputValueAutofilled(field, val, autofilled);
      if (inputValuesSavedForNext[field] === undefined) {
        // make sure key exists in saved for next object on mount
        toggleSaveForNext(field, false); // checkbox should be unchecked initially
      }
    }
  };

  const sanitizeDropdown = (preArray, returnArrayofObjs = false) => {
    const sanitizedArray = [];
    preArray.forEach((val) => {
      if (val !== null && val !== undefined && val !== "") {
        const tagRemoved = removeReplicateTag(val);
        if (tagRemoved.trim()) {
          if (returnArrayofObjs) {
            sanitizedArray.push({ value: tagRemoved });
          } else {
            sanitizedArray.push(tagRemoved);
          }
        }
      }
    });
    return sanitizedArray;
  };

  /**
   * Set loadSampleEditing, loadDraftState, loadSavedState, clearSampleDetailsForm to false
   */
  const setLoadStatesToFalse = () => {
    if (!inputValuesInitialization.current) { // set to false after actual sample editing/draft/saved state values set
      if (clearSampleDetailsForm) {
        setClearSampleDetailsForm(false);
      }
      if (loadSampleEditing) { // set to false after actual sample editing values set
        setLoadSampleEditing(false);
      }
      if (loadDraftState) { // set to false after actual draft values set
        setLoadDraftState(false);
      }
      if (loadSavedState) { // set to false after actual saved state values set
        setLoadSavedState(false);
      }
    }
  };

  /**
   * When inputValues changes, make api call to get filtered dropdowns and tests to autofill
   * @param {Boolean} considerSavedForNext do not overwrite saved for next when autofilling after clearing
   * @param {Boolean} disableAutofill do not overwrite if autofill disabled (loading saved state or sample to edit)
   */
  const handleSetDropdownsAndTests = async (currInputValues, considerSavedForNext = false, disableAutofill = false) => {
    // Access the current testsSelected value through the ref
    const currentTestsSelected = analysisRequestRef.current.getTestsSelected();
    // Check if there's a test with category "MRL"
    const hasMRL = Object.values(currentTestsSelected).some(
      (t) => t.test_category === "MRL",
    );

    if (hasMRL) {
      currInputValues.sample_type = "";
    }

    const newDropdownObj = await apiGetDropdownSuggestions(currInputValues);
    if (newDropdownObj !== null) { // if the call wasn't cancelled
      setDropdownSuggestionsObj(newDropdownObj);
      /* Autofill tests */
      const keepTests = (considerSavedForNext && saveTestsForNext) || disableAutofill;
      /** Overwrite the test if:
       * 1. The current company has po dropdown options and
       * 2. User changes the po value in the landing page
       */
      if (!keepTests || poHasChanged) {
        /** We need to set the poHasChanged back to false once it is true after the first occurrence */
        if (poHasChanged) {
          setPoHasChanged(false);
        }
        const testList = newDropdownObj.test_list ?? [];
        const newTestsObj = {};
        testList.forEach((test) => {
          newTestsObj[test] = { ...(testInfoMap.get(test) ?? {}) };
        });
        setTestsSelected(newTestsObj);
      }
      setLoadStatesToFalse();
    }
  };

  /**
   * Debounce handleSetDropdownsAndTests, will wait until 500ms have elapsed to call again.
   * useMemo keeps the debounced function instance the same between rerenders (more performant than useCallback),
   * the function will only reinitialize when a dependency array value updates (needed if function uses state/prop that changes i.e. sampleList)
   * @return {Function} debounced handleSetDropdownsAndTests
   */
  const debouncedHandleSetDropdownsAndTests = useMemo(() => debounce((currInputValues, considerSavedForNext, disableAutofill) => {
    handleSetDropdownsAndTests(currInputValues, considerSavedForNext, disableAutofill);
  }, 500), [selectedFields, saveTestsForNext, testInfoMap, clearSampleDetailsForm, loadDraftState, loadSavedState, loadSampleEditing]); // eslint-disable-line

  /**
   * Set field values splited into multiple fields for the particular fields
   * @param {string} field
   * @param {string} value
   * @param {index} index
   */
  const setFieldValueComposite = (field, value, index) => {
    // Retrieve current values and ensure it has the correct length
    let currentValues = inputValues[field]?.split(COMPOSITE_FIELD_DELIMITER) || [];

    // Ensure currentValues has the same length as compositeData.sample_count
    const sampleCount = compositeData.sample_count;

    // Adjust the length of currentValues to match sampleCount
    if (currentValues.length < sampleCount) {
      currentValues = [...currentValues, ...Array(sampleCount - currentValues.length).fill("")];
    } else if (currentValues.length > sampleCount) {
      // Remove the last value if sampleCount is reduced
      currentValues = currentValues.slice(0, sampleCount);
    }

    // Update the specific index with the new value
    currentValues[index] = value.trim(); // Trim the value to remove leading/trailing spaces

    // Remove empty values from the end of the array
    while (currentValues.length > 0 && currentValues[currentValues.length - 1] === "") {
      currentValues.pop(); // Remove trailing empty values
    }

    // Check if currentValues contains only empty strings
    const allEmpty = currentValues.every((val) => val === "");

    if (allEmpty) {
      setInputValues((prev) => ({
        ...prev,
        [field]: "", // Clear the entire field
      }));
    } else {
      setInputValues((prev) => ({
        ...prev,
        [field]: currentValues.join(COMPOSITE_FIELD_DELIMITER), // Join without extra delimiters
      }));
    }

    // Checking if mandatory fields are not empty
    const compositeFieldValueArr = currentValues;
    const compositeFieldsValues = inputValues;
    compositeFieldsValues[field] = currentValues.join(COMPOSITE_FIELD_DELIMITER);
    debouncedCheckMandatoryCompositeFields(field, compositeFieldValueArr);
    debouncedHandleSetDropdownsAndTests(compositeFieldsValues);
  };

  useEffect(() => {
    if (showCompositeModal) { // Create a set of current identifiers to check against
      const fieldValues = processInputValues(inputValues, compositeData.sample_count);
      setInputValues(fieldValues);
    }
  }, [compositeData]); // eslint-disable-line 

  /**
   * Set if field value is invalid in state
   * @param  {String} field json_field
   * @param  {Boolean} invalid true if invalid
   */
  const setFieldInputInvalid = (field, invalid) => {
    inputInvalid[field] = invalid;
    setInputInvalid({ ...inputInvalid });
  };

  /**
   * Add field to selected array, remove it from options
   * @param  {Number} idx index of the field in the fieldOptions array
   */
  const handleSelectField = (idx) => {
    const field = fieldOptions[idx];
    selectedFields.push(field);
    fieldOptions.splice(idx, 1); // remove item at that idx
    setSelectedFields(getSortedFields([...selectedFields], linkedFields, sampleCategory));
    setFieldOptions([...fieldOptions]);
    if (!has(inputValues, field) || inputValues[field] !== "") {
      setFieldValue(field, "");
    }
    if (
      !has(inputValuesSavedForNext, field)
      || inputValuesSavedForNext[field]
    ) {
      toggleSaveForNext(field, false); // set checkbox to false
    }
  };

  const handleCompositeSave = (sample_count, identifiers) => {
    const processedValues = processInputValues(inputValues, sample_count);
    Object.keys(processedValues).forEach((key) => {
      if (!identifiers.includes(key)) {
        if (inputValues[key].includes(COMPOSITE_FIELD_DELIMITER)) {
          processedValues[key] = inputValues[key]?.split(COMPOSITE_FIELD_DELIMITER)[0];
        } else {
          processedValues[key] = inputValues[key];
        }
      }
    });
    clearCompositeSaveForNext(identifiers);
    setInputValues(processedValues);
    debouncedHandleSetDropdownsAndTests(processedValues);
  };

  // When user toggle of the composite clear the composite data autofill
  useEffect(() => {
    if (!compositeToggle) {
      // debouncedHandleSetDropdownsAndTests(inputValues);
      setInvalidInputFound(
        Array.from(Object.values(inputInvalid)).some((val) => val === true),
      );
    }
  }, [compositeToggle]); // eslint-disable-line
  /**
   * When user clicks the delete button on an input field, remove from selected fields and add to field option
   * @param  {Number} idx index of the field in the selectedFields array
   * @param  {String} field json_field
   */
  const handleDeselectField = (idx, field) => {
    fieldOptions.push(selectedFields[idx]);
    selectedFields.splice(idx, 1); // remove item at that idx
    setSelectedFields(getSortedFields([...selectedFields], linkedFields, sampleCategory));
    setFieldOptions([...fieldOptions]);
    if (inputValues[field] !== "") {
      setFieldValue(field, "");
    }
    setInputInvalid({ ...inputInvalid, [field]: false });
    if (inputValuesSavedForNext[field]) {
      // make sure to uncheck checkbox
      toggleSaveForNext(field, false);
    }
  };

  /**
   * Divert horizontal scroll to vertical scroll
   * @param  {WheelEvent} e scroll event
   */
  const horizontalToVerticalScroll = (e) => {
    horizontalScrollbarRef.current.scrollLeft += e.deltaX + e.deltaY;
  };

  const handleInputChange = (field, value, index) => {
    // Check for invalid characters after modifying the value
    const invalidChars = INVALID_CHARS_ESCAPED; // Assuming you have this defined
    const regex = new RegExp(invalidChars.join("|"), "g");
    const invalidMatches = value.match(regex);
    const endsWithInvalid = INVALID_CHARS_ESCAPED.some((invalidChar) => value.endsWith(invalidChar));
    // If there are invalid characters, set the error state
    if (invalidMatches) {
      setFieldInputInvalid(field, true); // Set the error state to true
      setInvalidInputFound(true); // Set the error state to true
    } else {
      setFieldInputInvalid(field, false); // Clear the error state
      setInvalidInputFound(false); // clear the error state
    }
    if (value.includes(COMPOSITE_FIELD_DELIMITER)) {
      value = value.replace(new RegExp(`\\${COMPOSITE_FIELD_DELIMITER}`, "g"), ""); // Remove all instances of the delimiter
    }
    // Prevent the delimiter from being carried over
    if (endsWithInvalid) {
      // If the value ends with " _: or :_ ", we can prevent it from being set
      value = value.slice(0, -2); // Remove the delimiter
    }

    setFieldValueComposite(field, value, index);
  };

  const hideSaveForNext = Boolean(submissionEditingSample);

  const sampleFieldsContainerStyle = { height: "40px", width: "100%" };

  const renderFields = () => {
    const compositeFields = selectedFields.filter((field) => compositeData.identifier.includes(field));
    const displayedFields = new Set(); // To track which fields have displayed their labels

    return (
      <>
        {compositeFields.map((json_field, idx) => (
          Array.from({ length: compositeData.sample_count }, (_, i) => {
            if (hasMRLTest && json_field === "sample_type") return null;
            const showLabel = !displayedFields.has(json_field); // Determine if label should be shown

            if (showLabel) {
              displayedFields.add(json_field); // Mark this field as displayed
            }
            return (
              <SampleDetailsInput
                key={`${json_field}-${i}-${idx}`} // Unique key for each instance
                index={i}
                idx={idx}
                json_field={json_field}
                sampleIdFields={sampleIdFields}
                // handleDeselectField={handleDeselectField}
                inputValuesRefs={inputValuesRefs}
                setFieldValue={(field, val) => handleInputChange(field, val, i)}
                inputValuesSavedForNext={inputValuesSavedForNext}
                inputValues={inputValues}
                setInputValuesRefs={setInputValuesRefs}
                toggleSaveForNext={toggleSaveForNext}
                sampleFieldsInfo={sampleFieldsInfo}
                delimiterRegex={delimiterRegex}
                setFieldInputInvalid={setFieldInputInvalid}
                charLimit={CHAR_LIMITS[json_field] ?? undefined} // Pass the updated char limit for sample_type
                remainingLimit={json_field === "sample_type" ? inputValues[json_field]?.length : undefined} // Pass the remaining char limit for sample_type
                dropdownSuggestionsObj={dropdownSuggestionsObj}
                sanitizeDropdown={sanitizeDropdown}
                hideSaveForNext={hideSaveForNext}
                compositeData={compositeData}
                inputInvalid={inputInvalid}
                showLabel={showLabel}
              />
            );
          })
        ))}
        {selectedFields.map((json_field, idx) => {
          if (hasMRLTest && json_field === "sample_type") return null;
          const isComposite = compositeData.identifier.includes(json_field);
          // For non-composite fields, render normally
          if (isComposite) return null;
          return (
            <SampleDetailsAutocompleteInput
              key={`${json_field}-${idx}`}
              idx={idx}
              json_field={json_field}
              sampleIdFields={sampleIdFields}
              handleDeselectField={handleDeselectField}
              inputValuesRefs={inputValuesRefs}
              setFieldValue={setFieldValue}
              inputValuesSavedForNext={inputValuesSavedForNext}
              inputValues={inputValues}
              setInputValuesRefs={setInputValuesRefs}
              toggleSaveForNext={toggleSaveForNext}
              sampleFieldsInfo={sampleFieldsInfo}
              delimiterRegex={delimiterRegex}
              setFieldInputInvalid={setFieldInputInvalid}
              charLimit={CHAR_LIMITS[json_field] ?? undefined}
              dropdownSuggestionsObj={dropdownSuggestionsObj}
              sanitizeDropdown={sanitizeDropdown}
              hideSaveForNext={hideSaveForNext}
              isCompositeSample={compositeToggle}
            />
          );
        })}
      </>
    );
  };

  return (
    <>
      <UploadImageModal
        setShowUploadImageModal={setShowUploadImageModal}
        showUploadImageModal={showUploadImageModal}
        setDisplayImageSrc={setDisplayImageSrc}
        displayImageSrc={displayImageSrc}
        imageInfoRef={imageInfoRef}
        setImageInfoRef={setImageInfoRef}
        imageFileType={imageFileType}
      />
      {sampleCategory === "product" && (
        <CompositeModal
          showCompositeModal={showCompositeModal}
          setShowCompositeModal={setShowCompositeModal}
          sampleFieldsInfo={sampleFieldsInfo}
          sampleFields={sampleFields}
          compositeData={compositeData}
          setCompositeData={setCompositeData}
          setCompositeToggle={setCompositeToggle}
          compositeToggle={compositeToggle}
          handleCompositeSave={handleCompositeSave}
        />
      )}
      <div className="new-submission-pages-col sample-details-column">
        <div className="new-submission-pages-card sample-details">
          {disableAddSample && (
            <div className="SampleSubmissionForm__DisableAddSample" />
          )}
          <div className="new-submission-pages-title-composite-container sample-details">
            <div className="new-submission-pages-title-container">
              <div className="sample-submission-title-block" />
              <span>Sample Details</span>
            </div>
            {sampleCategory === "product" && (
              <SampleCompositeToggle
                compositeToggle={compositeToggle}
                setCompositeToggle={setCompositeToggle}
                setShowCompositeModal={setShowCompositeModal}
                compositeData={compositeData}
                setCompositeData={setCompositeData}
                setInputValues={setInputValues}
                inputValues={inputValues}
              />
            )}
          </div>

          {!loadingFields && (
            <>
              {fieldOptions.length > 0 && (
                <Scrollbar
                  style={sampleFieldsContainerStyle}
                  trackXProps={
                    scrollbarInUse ? showScrollbarStyle : hideScrollbarStyle
                  }
                  onMouseEnter={() => setScrollbarInUse(true)}
                  onMouseLeave={() => setScrollbarInUse(false)}
                  ref={horizontalScrollbarRef}
                  onWheel={horizontalToVerticalScroll}
                >
                  <motion.div
                    layoutScroll="position"
                    className="sample-field-pills-container"
                  >
                    {fieldOptions.map((json_field, idx) => (
                      <motion.div
                        layout="position"
                        key={`${json_field}--${idx}`}
                        className="sample-field-pill"
                        onClick={() => handleSelectField(idx)}
                      >
                        <span>{sampleFieldsInfo[json_field].title_field}</span>
                        <AddIcon height={15} />
                      </motion.div>
                    ))}
                  </motion.div>
                </Scrollbar>
              )}

              <div className="header-input-checkbox-list-container">
                {!selectedFields.length ? (
                  <span
                    className="no-fields-added-text"
                    style={{
                      height:
                        sampleCategory === "environment" ? "calc(100% - 192px)" : "100%",
                    }}
                  >
                    No Sample Details Fields added
                  </span>
                ) : (
                  <>
                    {!hideSaveForNext && (
                      !(compositeToggle) && (
                        <motion.div
                          layout="position"
                          className="input-checkbox-row"
                        >
                          <div className="sample-details-input" />
                          <div className="sample-submission-checkbox-container">
                            <div className="use-in-all-samples-subtitle">
                              Use in Next Sample
                              <div className="SampleDetails__GrayDivider" />
                            </div>
                            <span className="sample-submission-checkmark--hidden" />
                          </div>
                        </motion.div>
                      )
                    )}
                    <Scrollbar
                      style={{
                        height:
                          sampleCategory === "environment"
                            ? "calc(100% - 220px)"
                            : "calc(100% - 25px)",
                      }}
                    >
                      <motion.div
                        layoutScroll="position"
                        transition={{
                          layout: { duration: 0.3 },
                        }}
                        className="input-checkbox-list-container "
                      >
                        {renderFields()}
                      </motion.div>
                    </Scrollbar>
                  </>
                )}
                {sampleCategory === "environment" && (!isLoggedOut || thirdParty) && (
                  <>
                    <div className="SampleDetails__GrayDivider--ImageSection" />
                    <UploadImageInput
                      inputValuesSavedForNext={inputValuesSavedForNext}
                      setInputValuesRefs={setInputValuesRefs}
                      toggleSaveForNext={toggleSaveForNext}
                      setShowUploadImageModal={setShowUploadImageModal}
                      imageSrc={displayImageSrc}
                      hideSaveForNext={hideSaveForNext}
                    />
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
});

export default SampleDetails;
