import React, { Component } from "react";

import { Select } from "antd";
import { Slide } from "react-awesome-reveal";
import { toast } from "react-toastify";

import { AppContext } from "../../AppContext";
import StyledButton from "../Common/UIComponents/StyledButton";
import LoginInput from "./Common/LoginInput";

import { setUserCredentials } from "../../utils/common";

import { esvSupportLogin } from "../../actions/login";

import loginBgIcon from "../../assets/images/loginBgImg.svg";
import loginMainIcon from "../../assets/images/loginMainImg.svg";
import esv_logo from "../../assets/images/logo/esv_logo_2x.png";

import "./supportLogin.css";
import "./login.css";
import "./Common/LoginInput.css";

class SupportLogin extends Component {
  state = {
    companyName: "",
    companyDomain: "",
    username: "",
    password: "",
    usernameError: false,
    passwordError: false,
    selectError: false,
    slideOut: false,
    dropDownOptions: [],
    loginLoading: false,
  }

  passwordInput = React.createRef();

  componentDidMount = async () => {
    // Load dropdown list options
    const response = await esvSupportLogin();
    if (response.success) {
      const dropDownOptions = [];
      response.data.result.forEach(({ company_domain, company_name: companies }) => {
        dropDownOptions.push({ label: company_domain, options: companies.map((company_name) => ({ label: company_name, value: `${company_name}${company_domain}` })) });
      });
      this.setState({
        dropDownOptions,
      });
    }
  }

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  onSubmit = async () => {
    const { history } = this.props;
    const {
      username, password, companyName, companyDomain,
    } = this.state;
    let atLeastOneError = false;

    if (!username) {
      this.setState({ usernameError: true });
      atLeastOneError = true;
    }

    if (!password) {
      this.setState({ passwordError: true });
      atLeastOneError = true;
    }

    if (!companyName || !companyDomain) {
      this.setState({ selectError: true });
      atLeastOneError = true;
    }

    if (atLeastOneError) {
      return;
    }

    this.setState({ loginLoading: true }, async () => {
      const userDetails = await this.getUserPayloadFromDomain(username, password, companyDomain, companyName);
      if (userDetails.success) {
        const data = {
          company_domain: userDetails.companyDomain,
          user_payload: userDetails.payload,
          uid: "support",
          user_title: "Admin",
          rememberMe: true,
          company_name: companyName,
        };
        setUserCredentials("local", data, this.context);

        this.setState({ slideOut: true, loginLoading: false }, () => {
          window.setTimeout(() => {
            this.setState({ username: "", password: "" });
            history.push(("/sample-submission"));
          }, 500);
        });
      } else {
        this.setState({ loginLoading: false });
        toast.error(userDetails.message);
      }
    });
  }

  // API call to get the proper user payload
  getUserPayloadFromDomain = async (username, password, companyDomain, selectedCompany) => {
    const result = await esvSupportLogin(username, password, companyDomain, selectedCompany);
    let obj = {
      payload: "",
      companyDomain: "",
      message: "",
      success: result.success,
    };

    if (result.success) {
      obj = {
        payload: result.data.result.user_payload,
        companyDomain,
        message: result.data.message,
        success: result.success,
      };

      return obj;
    }
    obj.success = result.success;
    obj.message = result.data;

    return obj;
  }

  onInvite = () => {
    const { history } = this.props;
    history.push("/signup/invite");
  }

  onSelect = (value, { label: company_name }) => {
    const { selectError } = this.state;
    if (selectError) {
      this.setState({ selectError: false });
    }
    const company_domain = value.split(company_name)[1];
    this.setState({ companyDomain: company_domain, companyName: company_name });
  }

  render() {
    const {
      username,
      password,
      usernameError,
      passwordError,
      selectError,
      slideOut,
      dropDownOptions,
      loginLoading,
    } = this.state;

    return (
      <Slide direction="left" triggerOnce reverse={slideOut}>
        <img src={esv_logo} alt="esv" className="logoLoginPage" />
        <div className="login-page">
          <div className="loginMainContainer">
            <div className="login-box">
              <div className="login-header">
                Log in
              </div>

              <div className="login-header-subtitle">
                For internal use only. Log in with your information provided by an admin.
              </div>

              <label htmlFor="login-username-input" className="Login__Input__Label">User Name</label>
              <LoginInput
                id="login-username-inputid"
                name="username"
                value={username}
                onChange={(e) => {
                  if (usernameError && e.target.value) {
                    this.setState({ usernameError: false });
                  }
                  this.onChange(e);
                }}
                status={usernameError ? "error" : undefined}
              />
              <div className="support-login-company-domain">
                {/* form with autocomplete off needed to disable browser's autofill dropdown (it was blocking the select dropdown) */}
                <form autoComplete="off" style={{ width: "unset", padding: "unset" }}> {/* eslint-disable-line */}
                  <label htmlFor="support-login-select" className="Login__Input__Label">Company</label>
                  <Select
                    id="support-login-select"
                    placeholder="Please select a company"
                    showSearch
                    onSelect={this.onSelect}
                    options={dropDownOptions}
                    status={selectError ? "error" : undefined}
                    rootClassName="SupportLogin__Select"
                    popupClassName="SupportLogin__Select__Popup"
                  />
                </form>
              </div>
              <LoginInput
                label="Password"
                isPassword
                id="support-login-password"
                name="password"
                value={password}
                onChange={(e) => this.onChange(e)}
                error={passwordError}
                clearError={() => this.setState({ passwordError: false })}
                inputRef={this.passwordInput}
                onEnter={this.onSubmit}
              />

              <div className="support-login-login">
                <StyledButton
                  type="primary"
                  className="Login__Button"
                  loading={loginLoading}
                  disabled={loginLoading}
                  onClick={() => this.onSubmit()}
                >
                  Log In
                </StyledButton>
              </div>
              {/* <div className="login-dont-have-account-container">
                  <u className="signUp-link" onClick={this.onInvite}>Invite admin</u>
                </div> */}
            </div>

            <div className="loginImage">
              <img
                className="loginImageCharacters"
                src={loginMainIcon}
                alt="esv"
              />
              <img
                className="loginImageBubbles"
                src={loginBgIcon}
                alt="esv"
              />
            </div>
          </div>
        </div>
      </Slide>
    );
  }
}

SupportLogin.contextType = AppContext;
export default SupportLogin;
