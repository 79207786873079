/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useRef, useState } from "react";

import { LoadingOutlined } from "@ant-design/icons";
import Scrollbar from "react-scrollbars-custom";
import { toast } from "react-toastify";

import StyledButton from "../../../Common/UIComponents/StyledButton";
import StyledTooltip from "../../../Common/UIComponents/StyledTooltip";
import EnvConfirmModal from "../../Analytics/Components/EnvConfirmModal";

import { AWS_BASE_PATH } from "../../../../utils/Constant";
import { downloadFileFromS3WithPresigned } from "../../../../utils/helpers";

import { deletePrinters, patchPrinters, postPrinters } from "../../../../actions/print";

import { ReactComponent as CloseIcon } from "../../../../assets/images/close.svg";
import { ReactComponent as CheckMarkIcon } from "../../../../assets/images/environment/checkMark.svg";
import DownloadIcon from "../../../../assets/images/product/downloadBlue.png";
import backArrowIcon from "../../../../assets/images/product/template/BackArrowLightBlue.png";
import { ReactComponent as DeleteIcon } from "../../../../assets/images/product/template/delete.svg";
import { ReactComponent as AddIcon } from "../../../../assets/images/sample-submission/add.svg";
import { ReactComponent as EditIcon } from "../../../../assets/images/sample-submission/edit.svg";

const INITIAL_ADD_PRINTER_STATE = {
  name: "",
  label_type: "Sample",
  serial_number: "",
};
const INITIAL_FIELD_ERRORS_STATE = { name: false, serial_number: false };

export default function ZebraPrintEdit({ onToggleEditPrinters, printerList, refetch }) {
  const [addData, setAddData] = useState(INITIAL_ADD_PRINTER_STATE);
  const [fieldErrors, setFieldErrors] = useState(INITIAL_FIELD_ERRORS_STATE);
  const [editType, setEditType] = useState(null); // null => Not Editing, "add" => Adding new, <printer_id> => Editing existing
  const [deleteId, setDeleteId] = useState(null);
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const scrollbarRef = useRef();

  /**
 * Set config state
 * @param {"id" | "name" | "label_type" | "serial_number"} type type of field
 * @param {string} value value of field
 */
  const onSetAddData = (type, value) => {
    setAddData({ ...addData, [type]: value });
  };

  /**
   * Show add new printer input
   */
  const onAddNewPrinter = () => {
    setAddData(INITIAL_ADD_PRINTER_STATE);
    setEditType("add");
  };

  /**
   * Download configuration file
   */
  const downloadConfigurationFile = async () => {
    const fileName = "printer_setup.txt";
    const filePath = `${AWS_BASE_PATH}media/app/printer_setup.txt`;
    const success = await downloadFileFromS3WithPresigned([filePath], "private", [fileName]);
    if (!success) {
      toast.error("Something went wrong");
    }
  };

  /**
   * Start editing printer
   * @param {*} printer printer data
   */
  const editPrinter = (printer) => {
    const { id, ...printerData } = printer;
    setEditType(id);
    setAddData(printerData);
  };

  /**
   * Reset state
   */
  const onResetState = () => {
    setEditType(null);
    setAddData(INITIAL_ADD_PRINTER_STATE);
    setFieldErrors(INITIAL_FIELD_ERRORS_STATE);
  };

  /**
   * Validate data before submit
   */
  const validateData = () => {
    const rules = {
      name: !addData.name.trim(),
      serial_number: !addData.serial_number.trim(),
    };
    const hasError = rules.name || rules.serial_number;
    setFieldErrors(rules);
    return hasError;
  };

  /**
   * Submit add/edit printer
   */
  const onUpdate = async () => {
    if (!editType) return; // If not add/edit
    if (validateData()) return; // Validation

    setIsLoading(true);
    // editType = "add" => Add new printer, editType = <printer_id> => Edit printer
    const response = editType === "add" ? await postPrinters(addData) : await patchPrinters({ id: editType, ...addData });
    const message = editType === "add" ? "Printer added successfully" : "Printer edited successfully";
    toast(response.success ? message : response.message, { type: response.success ? "success" : "error" });
    onResetState(); // Close edit mode
    await refetch();
    setIsLoading(false);
  };

  /**
   * Show delete confirmation dialog
   * @param {string} id printer id
   */
  const showDeleteConfirmation = (id) => {
    setDeleteId(id);
    setDeleteConfirmationVisible(true);
  };

  /**
   * On confirm delete operation
   */
  const onDelete = async () => {
    setIsLoading(true);
    const response = await deletePrinters(deleteId);
    if (response.success) {
      toast.success("Printer deleted successfully");
    } else {
      toast.error(response.message);
    }
    await refetch();
    setDeleteId(null);
    setDeleteConfirmationVisible(false);
    setIsLoading(false);
  };

  /**
   * Cancel delete confirmation
   */
  const cancelDeleteConfirmation = () => {
    setDeleteId(null);
    setDeleteConfirmationVisible(false);
  };

  /**
   * Submit on enter
   * @param {Event} e event
   */
  const onPressEnter = (e) => {
    if (e.key === "Enter" && editType !== null) {
      onUpdate();
    }
  };

  /**
   * Returns error styles for an input
   * @param {"name" | "serial_number"} key input key
   * @returns style object
   */
  const getErrorStyles = (key) => ({ borderColor: fieldErrors[key] ? "red" : undefined });

  const renderEditRow = (printer) => (
    <tr key={printer ? printer.id : "add"}>
      <td>
        <input
          type="text"
          placeholder="Printer name"
          className="ZebraPrintEdit__AddInput"
          value={addData.name}
          onChange={(e) => onSetAddData("name", e.target.value)}
          autoFocus
          onKeyDown={onPressEnter}
          style={getErrorStyles("name")}
          disabled={isLoading}
        />
      </td>
      <td>
        <input
          type="text"
          placeholder="Serial number"
          className="ZebraPrintEdit__AddInput"
          value={addData.serial_number}
          onChange={(e) => onSetAddData("serial_number", e.target.value)}
          onKeyDown={onPressEnter}
          style={getErrorStyles("serial_number")}
          disabled={isLoading}
        />
      </td>
      <td className="Center__align Flex__Center">
        <StyledButton
          htmlType="button"
          type="text"
          onClick={onUpdate}
          className="ZebraPrintEdit__ActionButton"
          disabled={isLoading}
        >
          {isLoading ? <LoadingOutlined spin /> : <CheckMarkIcon width={14} height={14} />}
        </StyledButton>
        <StyledButton
          htmlType="button"
          type="text"
          onClick={onResetState}
          className="ZebraPrintEdit__ActionButton"
          disabled={isLoading}
        >
          <CloseIcon width={20} height={20} />
        </StyledButton>
      </td>
    </tr>
  );

  const renderRow = (printer) => (
    <tr key={printer.id}>
      <td>{printer.name}</td>
      <td>{printer.serial_number}</td>
      <td className="Center__align Flex__Center">
        <StyledButton
          htmlType="button"
          type="text"
          onClick={() => editPrinter(printer)}
          className="ZebraPrintEdit__ActionButton"
        >
          <EditIcon />
        </StyledButton>
        <StyledButton
          htmlType="button"
          type="text"
          onClick={() => showDeleteConfirmation(printer.id)}
          className="ZebraPrintEdit__ActionButton"
          disabled={deleteId === printer.id && isLoading}
        >
          {deleteId === printer.id && isLoading ? <LoadingOutlined spin /> : <DeleteIcon />}
        </StyledButton>
      </td>
    </tr>
  );

  return (
    <>
      <div className="ZebraPrintModal__Content">
        <div className="ZebraPrintModal__ButtonPanel">
          <StyledButton
            htmlType="button"
            type="text"
            className="ZebraPrintModal__CancelButton"
            onClick={onToggleEditPrinters}
          >
            <img src={backArrowIcon} alt="Back arrow" className="ZebraPrintModal__BackIcon" />
            <span className="ZebraPrintModal__BackText">Back</span>
          </StyledButton>
          <div className="ZebraPrintModal__Buttons">
            <StyledTooltip title="Download Configuration File">
              <StyledButton
                htmlType="button"
                type="text"
                className="ZebraPrintModal__Button"
                onClick={downloadConfigurationFile}
              >
                <img src={DownloadIcon} alt="Download configuration file icon" className="ZebraPrintModal__DownloadIcon" />
                <div className="ZebraPrintModal__DownloadText">Config</div>
              </StyledButton>
            </StyledTooltip>
            <StyledButton
              htmlType="button"
              type="primary"
              className="ZebraPrintModal__Button"
              onClick={onAddNewPrinter}
              disabled={editType === "add"}
            >
              <AddIcon className="ZebraPrintModal__AddPrinterIcon" />
              Add Printer
            </StyledButton>
          </div>
        </div>
        <div className="ZebraPrintModal__ContentContainer">
          <Scrollbar
            ref={scrollbarRef}
            disableTrackYWidthCompensation
            disableTracksWidthCompensation
            className="ZebraPrintModal__EditTableScrollbar"
          >
            <table className="ZebraPrintModal__EditTable">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Serial Number</th>
                  <th className="Center__align">Actions</th>
                </tr>
              </thead>
              <tbody>
                {editType === "add" && renderEditRow()}
                {printerList.map((printer) => (printer.id === editType ? renderEditRow(printer) : renderRow(printer)))}
              </tbody>
            </table>
          </Scrollbar>
        </div>
      </div>
      {deleteConfirmationVisible && (
        <EnvConfirmModal
          width={346}
          headerText="Delete printer"
          bodyText="Are you sure you want to delete printer?"
          buttonText={["Cancel", "Delete"]}
          buttonFunctions={[cancelDeleteConfirmation, onDelete]}
          loading={isLoading}
        />
      )}
    </>
  );
}
