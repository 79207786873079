/* eslint-disable import/prefer-default-export */
export const hardcodedPermissions = {
  profile: [
    {
      title: "Add Member",
      description: "Grants ability to add members.",
      codename: "add_member",
      authorized_roles: ["admin"],
    },
    {
      title: "Delete Member",
      description: "Grants ability to delete members.",
      codename: "delete_member",
      authorized_roles: ["admin"],
    },
    {
      title: "Change Role",
      description: "Grants ability to modify member roles.",
      codename: "change_member_role",
      authorized_roles: ["admin"],
    },
    {
      title: "View Member Status",
      description: "Grants access to view member status.",
      codename: "view_member_status",
      authorized_roles: ["admin"],
    },
  ],
  sample_submission: [
    {
      title: "Share Submission",
      description: "Grants ability to share sample submissions with third parties.",
      codename: "share",
      authorized_roles: ["admin", "editor"],
    },
    {
      title: "Add Test",
      description: "Grants ability to add tests.",
      codename: "add_test",
      authorized_roles: ["admin", "support", "editor"],
    },
  ],
  onboarding: [
    {
      title: "Add Company",
      description: "Grants ability to add companies.",
      codename: "add_company",
      authorized_roles: ["admin", "support"],
    },
    {
      title: "Onboard Product",
      description: "Grants access to onboard product information for companies.",
      codename: "onboard_product",
      authorized_roles: ["admin"],
    },
    {
      title: "Onboard Environment",
      description: "Grants access to onboard environment information for companies.",
      codename: "onboard_environment",
      authorized_roles: ["admin"],
    },
  ],
  spec_manager: [
    {
      title: "Add Spec",
      description: "Grants ability to add specifications.",
      codename: "add_spec",
      authorized_roles: ["admin", "editor"],
    },
  ],
  product: [
    {
      title: "Edit Report Table Titles",
      description: "Grants ability to edit report table headers.",
      codename: "report_table_header_edit",
      authorized_roles: ["admin", "editor"],
    },
    {
      title: "Request Retest",
      description: "Grants ability to request a product retest.",
      codename: "request_product_retest",
      authorized_roles: ["support"],
    },
  ],
};
